@use 'src/scss/font';
@use 'src/scss/variables';
@use 'src/scss/mixins';

.pur-life-theme {
  $pur-life-sativa: #ab8876;
  $pur-life-indica: #9caf88;
  $pur-life-hybrid: #7a99ac;
  $pur-life-blend: #8e8ba6;
  $pur-life-cbd: #dedede;

  // Header

  .main-header {
    @include font.roboto-black();
    font-size: 3.5rem;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .header-container {
    justify-content: center;
  }

  .company-logo {
    max-width: 20rem;
    max-height: 4.25rem;
  }

  // Sections

  .title-section {
    @include font.roboto-black();
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    padding: 0.5rem;
  }

  .title-section-container {
    margin-bottom: 0.5rem !important;
  }

  .section-container {
    margin-bottom: 1rem;
  }

  .section-header-container {
    height: 2.25rem;
    text-transform: uppercase;
  }

  .section-column-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section-title {
    @include font.roboto-bold();
    font-size: 1.75rem;
    padding-left: 1rem;
  }

  .section-header-general {
    @include font.roboto-bold();
    font-size: 1.75rem;
    text-transform: uppercase;
  }

  // Product

  .product-container {
    height: 2.1rem;
  }

  .product-odd {
  }

  .product-column {
    flex-direction: row;
    height: 80%;
  }

  .product-column-general {
    @include font.roboto-medium-extra();
    font-size: 1rem;
    text-align: center;
  }

  .product-cannabinoid,
  .product-terpene,
  .product-quantity,
  .product-variant-price-integer,
  .product-size,
  .product-variant-secondary-price {
    padding-left: 0.25rem;
  }

  .product-strain-type {
    background-color: transparent;
    @include font.roboto-black();
    display: flex;
    justify-content: center;
    align-items: center;

    &.s,
    &.i,
    &.b,
    &.h,
    &.c {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
    }

    &.sativa,
    &.indica,
    &.blend,
    &.hybrid,
    &.cbd {
      width: 4.2rem;
      height: 1.5rem;
      font-size: 1.2rem;
    }

    &.s,
    &.sativa {
      color: $pur-life-sativa;
    }

    &.i,
    &.indica {
      color: $pur-life-indica;
    }

    &.b,
    &.blend {
      color: $pur-life-blend;
    }

    &.h,
    &.hybrid {
      color: $pur-life-hybrid;
    }

    &.c,
    &.cbd {
      color: $pur-life-cbd;
    }
  }

  .product-title-container {
    padding-left: 0.5rem;
    padding-right: 0;
  }

  .product-title {
    @include font.f24px;
    @include font.roboto-bold();
  }

  .product-brand {
    @include font.f24px;
    @include font.roboto-regular-italic();
    margin-left: 0.5rem;
  }

  .product-label {
    @include font.f16px;
    @include font.roboto-bold();
    padding: 0.25rem 0.5rem;
    margin-left: 0.3rem;
    margin-right: 0.1rem;
    align-items: flex-start;
  }

  .product-badge {
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }

  .badge {
    height: 1.625rem;
  }

  .product-title-flex-container {
    align-items: center;
  }

  // Font Styling
  .force-bold-style * {
    @include font.roboto-black-extra();
  }

  // Override font sizes for multi-column portrait

  .portrait-multi-column {
    padding-right: 1rem;

    .title-section {
      @include font.f24px;
    }

    .section-title {
      @include font.f22px;
    }

    .section-header-general {
      @include font.f16px;
    }

    .product-column-general {
      @include font.f14px;
    }

    .product-title {
      @include font.f14px;
    }

    .product-brand {
      @include font.f14px;
    }

    .product-label {
      @include font.f12px;
      padding: 0.1rem 0.4rem;
      margin-left: 0.4rem;
    }
  }
}
