@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.top-products {
  // Header & Footer

  .screen-is-portrait {
    .header-container {
      padding: 2rem 3.5rem 1rem 3.5rem;
    }
  }

  .header-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin-top: 3.5rem;
    padding: 2rem 7.5rem 1rem 7.5rem;
  }

  .main-header {
    @include f.poppins-bold;
    @include f.f64px;
    line-height: 6rem;
  }

  .main-subtitle {
    @include f.poppins-regular;
    @include f.f32px;
    @include m.no-wrap;
    line-height: 1.2;
  }

  .header-xor-footer-height {
    height: calc(100% - 3rem);
  }

  .no-footer {
    padding-bottom: 3rem;
  }

  // Section container

  .screen-is-portrait {
    .sections-container {
      margin-left: 3.5rem;
      margin-right: 3.5rem;
    }
  }

  .sections-container {
    margin-top: 1rem;
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  // Sections

  .title-section {
  }

  .section-container {
  }

  .section-header-container {
    @include f.poppins-bold;
  }

  .section-header-badge {
    @include f.poppins-bold;
  }

  .section-title {
    @include f.poppins-bold;
  }

  .section-header-pre-roll {
  }

  .section-header-general {
  }

  // Product

  .product-column-general {
    @include f.poppins-regular;
    @include f.f32px;

    .pricing-tier-grid-mode {
      @include f.f24px;
    }
  }

  .screen-is-portrait {
    .product-container {
      align-items: center;
      height: 9.5rem;
    }
  }
  .product-container {
    align-items: center;
    height: 7rem;
  }

  .product-title {
    @include f.poppins-bold();
    @include f.f32px;
  }

  .product-label {
    @include f.poppins-light();
    margin: 0 0 0 0.8rem;
    font-size: 1rem;
  }

  .product-subtitle {
    @include f.poppins-regular;
    @include f.f24px();
  }

  .product-variant-price {
  }

  .product-variant-price-integer {
    @include f.poppins-bold;
  }

  .product-variant-price-decoration {
    @include f.poppins-bold;
  }

  .product-cannabinoid,
  .product-terpene {
  }

  .product-quantity {
  }

  .product-size {
  }

  .product-column {
    @include f.poppins-regular;
    font-size: 1.5rem;
  }

  .base-product-strain-type {
    @include f.poppins-light;
  }

  // Font Styling

  .force-bold-style * {
    @include f.poppins-bold;
  }
}
