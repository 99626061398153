/* ************** Print ***************** */

.print-menu {
  page: default-print-margins;
}

@page default-print-margins {
  margin: 0.5in;
}

@media print {
  // Allow background images to be printed
  * {
    print-color-adjust: exact !important;
  }

  @page :blank {
    visibility: hidden;
  }
}

/**
 * Borked, print menus have to be developed using the base REM size of 11px.
 * TODO: at some point we should refactor this to use the default REM size of 16px.
 */
html.print-menu {
  font-size: 11px !important;
  break-after: avoid-page !important;

  .header-container {
    padding-top: 0 !important;
  }

  .menu-background-asset {
    display: block;
  }

  .menu-container {
    position: relative !important;
    height: auto !important;
    // Don't change to scroll. Scoll adds extra padding to bottom and causes extra page at end of document...
    overflow: overlay !important;
  }

  .flipper-divider {
    display: none !important;
  }

  .h100p {
    height: auto !important;
  }

  .product-menu-container {
    overflow: visible !important;
  }

  .vertical-scroll {
    display: block !important;
    overflow-x: hidden !important;
    overflow-y: visible !important;
  }

  .section-container.section-page-break {
    page-break-before: always;
  }
}

.wrapping-swimlane-section-container {
  display: flex;
  width: 100%;
}

/*
  https://stackoverflow.com/a/36247448/11308345

  A flex item cannot be smaller than the size of its content along the main axis.

  The defaults are min-width: auto and min-height: auto
  for flex items in row-direction and column-direction, respectively.

  You can override these defaults by setting flex items to:
  - min-width: 0
  - min-height: 0
  - overflow: hidden (or any other value, except visible)

  ------------------------------------------------------------------------

  Without this, flex box does not share the space between all swimlanes
  equally. This was a massive thorn in my side. One needs to go deep into
  the belly of the docs and stackoverflow to find this out.

  See docs for more information:
  https://www.w3.org/TR/css-flexbox-1/#min-size-auto
 */
.wrapping-swimlane {
  flex: 1;
  min-width: 0;
}
