@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.marketing-featured-category-static-grid-theme {
  .product-menu-container {
    &.screen-is-portrait {
      .header-container {
        padding: 3.25rem 1.875rem 3.125rem 1.875rem;
      }
    }
  }

  .header-container {
    padding: 1.5rem 1.875rem 1.5rem 1.875rem;

    .main-header {
      @include f.archivo-black-regular;
      @include f.f100px;
      letter-spacing: -0.6rem;
      line-height: 7.125rem;
    }

    .main-subtitle {
      @include f.source-sans-pro-regular;
      @include f.f32px;
      @include m.no-wrap;
      line-height: 2.5rem;
    }
  }

  .product-menu-container {
    position: absolute;
    inset: 0;
    height: unset;
    display: flex;
  }

  .sections-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  .section-container {
    height: 100%;
    width: 100%;
  }

  .footer-container {
    padding: 2.8125rem 1.875rem;
    justify-content: flex-end;

    .company-logo {
      max-height: 6.625rem;
    }
  }

  .product-label {
    margin: 0 0.5rem 0 0;
  }
}
