$primary-font-color: #222222;
$primary-background-color: #ffffff;
/*
 * Order matters here.
 * The rem unit scales relative to the main html font-size.
 * 16px is the standard base size.
 */
html {
  color: $primary-font-color;
  background-color: $primary-background-color;
  font-size: 16px;
}

/* *************** Scaling Portrait ****************** */
// 8K portrait scaling
@media only screen and (max-width: 4320px) and (orientation: portrait) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 64px;
  }
}

// 4k portrait scaling
@media only screen and (max-width: 2160px) and (orientation: portrait) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 33px;
  }
}

// 1440p portrait scaling
@media only screen and (max-width: 1440px) and (orientation: portrait) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 22px;
  }
}

// 1080p portrait scaling
@media only screen and (max-width: 1080px) and (orientation: portrait) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 16px;
  }
}

// 1024px portrait Ipad pro
@media only screen and (max-width: 1024px) and (orientation: portrait) and (pointer: coarse) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 14px;
  }
}

// 768px portrait Ipad
@media only screen and (max-width: 768px) and (orientation: portrait) and (pointer: coarse) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 11px;
  }
}

// 720p portrait scaling
@media only screen and (max-width: 720px) and (orientation: portrait) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 10.5px;
  }
}

// Amazon Scaling
@media only screen and (width: 540px) and (height: 960px) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 8px;
  }
}

// Mobile scaling
@media only screen and (max-width: 500px) and (orientation: portrait) and (pointer: coarse) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 5px;
  }
}

/* ************************************************** */

/* ************** Scaling Landscape ***************** */
// 8K landscape scaling
@media only screen and (max-width: 7680px) and (orientation: landscape) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 64px;
  }
}

// 4k landscape scaling
@media only screen and (max-width: 3840px) and (orientation: landscape) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 33px;
  }
}

// 1440p landscape scaling
@media only screen and (max-width: 2560px) and (orientation: landscape) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 22px;
  }
}

// 1080p landscape scaling
@media only screen and (max-width: 1920px) and (orientation: landscape) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 16px;
  }
}

// 720p landscape scaling
@media only screen and (max-width: 1280px) and (orientation: landscape) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 10.5px;
  }
}

// landscape Ipad pro
@media only screen and (max-width: 1366px) and (orientation: landscape) and (pointer: coarse) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 16px;
  }
}

// landscape Ipad
@media only screen and (max-width: 1024px) and (orientation: landscape) and (pointer: coarse) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 14px;
  }
}

// Mobile scaling
@media only screen and (max-width: 1000px) and (orientation: landscape) and (pointer: coarse) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 10px;
  }
}

// Amazon Scaling
@media only screen and (width: 960px) and (height: 540px) {
  html.product-menu,
  html.marketing-menu,
  html.spotlight-menu {
    font-size: 8px;
  }
}

/* ************************************************** */
