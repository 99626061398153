@use 'src/scss/font' as f;

.foggy-theme {
  // Header

  .main-header {
    @include f.big-noodle;
    font-size: 7.5rem;
    letter-spacing: 0;
    text-transform: uppercase;
    text-align: center;
  }

  // Section container

  .sections-container {
    margin-left: 2.125rem;
    margin-right: 2.125rem;
  }

  // Sections

  .title-section {
    @include f.big-noodle;
    font-size: 3.75rem;
    letter-spacing: 0.01625rem;
  }

  .section-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-header-container {
    height: 5.125rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .section-title {
    @include f.big-noodle;
    font-size: 3.75rem;
    letter-spacing: 0.01625rem;
  }

  .section-header-pre-roll {
    @include f.big-noodle;
    vertical-align: middle;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .section-header-general {
    @include f.big-noodle;
    font-size: 3.75rem;
    letter-spacing: 0.0125rem;
  }

  // Product

  .product-column-general {
    @include f.heebo-regular;
    font-size: 1.5rem;
  }

  .product-container {
    align-items: center;
    height: 5.5rem;
  }

  .product-title {
    @include f.heebo-regular;
    font-size: 2rem;
    letter-spacing: 0.01375rem;
  }

  .product-label {
    @include f.heebo-light;
    margin: 0 0 0 0.8rem;
    font-size: 1rem;
  }

  .product-subtitle {
    @include f.heebo-regular;
    font-size: 1.25rem;
    letter-spacing: 0.009375rem;
  }

  .product-variant-price {
    font-size: 1.875rem;
    letter-spacing: 0.0125rem;
  }

  .product-cannabinoid,
  .product-terpene {
    font-size: 1.65rem;
    letter-spacing: 0.01rem;
  }

  .product-quantity {
    font-size: 1.5rem;
  }

  .product-low-amount {
    @include f.heebo-regular;
    font-size: 1.4rem;
  }

  .product-size {
    font-size: 1.5rem;
  }

  .product-column {
    @include f.heebo-regular;
    font-size: 1.5rem;
  }

  .base-product-strain-type {
    @include f.heebo-light;
  }

  // Font Styling

  .force-bold-style * {
    @include f.heebo-bold;
  }
}
