@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.error-container {
  @include m.fixed-top-left;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  background-color: white;
  z-index: 25;
}

.error-logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.error-logo {
  height: 15rem;
}

.error-title {
  @include f.poppins-bold;
  font-size: 7rem;
}

.error-margin {
  margin-left: 20%;
  margin-right: 20%;
}

.error-desc {
  @include f.poppins-medium;
  font-size: 2.5rem;
}
