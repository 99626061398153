@use 'src/scss/font' as f;

.la-canapa-theme {
  $la-canapa-sativa-color: #eba900;
  $la-canapa-indica-color: #006068;
  $la-canapa-hybrid-color: #739e69;
  $la-canapa-blend-color: #5690f0;
  $la-canapa-cbd-color: #b8b8b8;
  $la-canapa-subtitle: #4b4b4b;

  // Header

  .main-header {
    @include f.f72px();
    letter-spacing: 2px;
    text-align: left;
    padding-left: 1rem;
    @include f.open-sans-bold();
  }

  .header-container {
    align-items: center;
    justify-content: center;
  }

  .company-logo {
    max-height: 7.5rem;
  }

  // Section container

  .sections-container {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  // Sections

  .title-section {
    @include f.open-sans-bold();
    @include f.f48px();
    line-height: 3.5rem;
    padding: 0 0.2rem 0.3rem 0.2rem;
  }

  .section-container {
    padding-inline: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  .section-header-container {
    height: 3rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
  }

  .section-title {
    @include f.open-sans-bold();
    color: #731818;
    @include f.f32px();
  }

  .section-header-general {
    @include f.open-sans-regular();
    @include f.f19px();
    color: #731818;
  }

  // Product

  .product-column-general {
    @include f.open-sans-regular();
    @include f.f18px();
  }

  .product-container {
    align-items: center;
    height: 3.5rem;
  }

  .product-title {
    @include f.open-sans-bold();
    @include f.f20px();
  }

  .product-label {
    @include f.open-sans-regular();
    margin: 0 0 0 0.1rem;
    @include f.f14px();
    border-radius: 0.75rem;
    width: 5rem;
    height: 1.4rem;
    justify-content: center;
    text-transform: uppercase;
  }

  .product-subtitle {
    @include f.open-sans-semi-bold();
    @include f.f14px();
    color: $la-canapa-subtitle;
  }

  .product-column {
    @include f.open-sans-regular();
    @include f.f16px();
  }

  .badge {
    height: 1.8rem;
  }

  .product-badge {
    align-items: end;
    justify-items: end;
    float: right;
    align-self: unset;
    width: unset;
    margin-right: 0.5rem;
  }

  .product-column-Badge {
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
  }

  .classification-mapping {
    flex-direction: column !important;
    text-align: -webkit-center !important;
  }

  .classification-name {
    @include f.f13px();
    margin-top: 0.1rem;
  }

  .footer-classification-board {
    color: #1a1a1a;
    border: solid;
    padding: 1rem 1rem !important;
    margin-bottom: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .footer-container {
    height: 12% !important;
  }

  .product-strain-type,
  .classification-mapping {
    @include f.open-sans-bold();
    @include f.f16px();
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-right: 0.5rem;

    .s,
    .sativa,
    .h,
    .hybrid,
    .i,
    .indica,
    .b,
    .blend,
    .c,
    .cbd {
      padding-top: 0.1rem !important;
      padding-bottom: 0.2rem !important;
      height: max-content !important;
    }

    &.s,
    &.h,
    &.i,
    &.b,
    &.c {
      color: white;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
    }

    &.sativa,
    &.hybrid,
    &.indica,
    &.blend,
    &.cbd {
      color: white;
      width: 4rem;
      height: 1.8rem;
      border-radius: 0.5rem;
    }

    &.s,
    &.sativa {
      background-color: $la-canapa-sativa-color !important;
    }

    &.h,
    &.hybrid {
      background-color: $la-canapa-hybrid-color !important;
    }

    &.i,
    &.indica {
      background-color: $la-canapa-indica-color !important;
    }

    &.b,
    &.blend {
      background-color: $la-canapa-blend-color !important;
    }

    &.c,
    &.cbd {
      background-color: $la-canapa-cbd-color !important;
    }
  }

  // Font Styling

  .force-bold-style * {
    @include f.open-sans-bold-extra();
  }
}
