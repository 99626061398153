.product-label-container {
  align-self: center;
}

.product-label {
  height: fit-content;
  margin-left: 0.5rem;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.3125rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
