/* You can add global styles to this file, and also import other style files */
@import './toastr/toastr';
@import './scaling';
@import './error/error';
@import '../scss/animations';

// base print
@import '../scss/menus/base-print-menu';

// Labels
@import '../scss/labels/base-label';

// Product
@import '../scss/menus/base-product-menu';
@import '../scss/menus/product/framed';
@import '../scss/menus/product/mary-jane';
@import '../scss/menus/product/small-jane';
@import '../scss/menus/product/doug';
@import '../scss/menus/product/night';
@import '../scss/menus/product/foggy';
@import '../scss/menus/product/tight';
@import '../scss/menus/product/jimmy';
@import '../scss/menus/product/contemporary';
@import '../scss/menus/product/weed';
@import '../scss/menus/product/true-north';
@import '../scss/menus/product/true-north-dark';
@import '../scss/menus/product/weed-store';
@import '../scss/menus/product/amsterdam';
@import '../scss/menus/product/king-of-queens';
@import '../scss/menus/product/la-canapa';
@import '../scss/menus/product/ingram-and-sons';
@import '../scss/menus/product/black-jack';
@import '../scss/menus/product/clean';
@import '../scss/menus/product/runtz';
@import '../scss/menus/product/lobster-butter';
@import '../scss/menus/product/soul-bud';
@import '../scss/menus/product/soul-bud-landscape';
@import '../scss/menus/product/lux-leaf';
@import '../scss/menus/product/pur-life';
@import '../scss/menus/product/pur-life-landscape';
@import '../scss/menus/product/super-lemon-haze';
@import '../scss/menus/product/northern-lights';

// Print
@import '../scss/menus/print/plain-jane';
@import '../scss/menus/print/grid-lines';
@import '../scss/menus/print/sprout';
@import '../scss/menus/print/calyx-trichomes';
@import '../scss/menus/print/banded';
@import '../scss/menus/print/bud-supply';
@import '../scss/menus/print/plantlife';
@import '../scss/menus/print/blended';
@import '../scss/menus/print/plantlife-non-smokable';
@import '../scss/menus/print/double-dutch';
@import '../scss/menus/print/come-to-bask-trifold';
@import '../scss/menus/print/double-gelato';
@import '../scss/menus/print/ingram-and-sons-print';
@import '../scss/menus/print/la-canapa-print-theme';
@import '../scss/menus/print/doug-print';
@import '../scss/menus/print/soul-bud-print-portrait';
@import '../scss/menus/print/og-kush-print';
@import '../scss/menus/print/space-monkey-print';
@import '../scss/menus/print/true-north-print-theme';
@import '../scss/menus/print/fika-print';
@import '../scss/menus/print/fire-and-flower-print';
@import '../scss/menus/print/bella-luna-print-theme';

// Spotlight
@import '../scss/menus/base-spotlight-menu';
@import '../scss/menus/spotlight/top-products';

// Combo
@import '../scss/menus/combo/original-combo';

// Marketing
@import '../scss/menus/marketing/marketing-featured-category';
@import '../scss/menus/marketing/marketing-featured-category-static-grid';

// Report
@import '../scss/menus/base-report-menu';
@import '../scss/menus/report/order-review';

// Main

* {
  // removes orange border around selections on buzz-tvs
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.product-menu {
}

.marketing-menu {
}

.print-menu {
}

.spotlight-menu {
}

.company-logo {
  object-fit: contain;
  max-height: 6.25rem;
  object-position: bottom center;
  max-width: 50%;
}

.rotate90 {
  transform-origin: bottom left;
  position: fixed;
  top: -100vw;
  left: 0;
  height: 100vw;
  width: 100vh;
  overflow: hidden;
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate180 {
  overflow: hidden;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate270 {
  transform-origin: bottom right;
  position: fixed;
  top: -100vw;
  right: 0;
  height: 100vw;
  width: 100vh;
  overflow: hidden;
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.leaf-img {
  height: 3rem;
  width: 3rem;
  margin-bottom: 1rem;
}

.black-background {
  background-color: #222222;
}

.white-background {
  background-color: white;
}

.h100vh {
  height: 100vh;
}

.h100p {
  height: 100%;
}

.block {
  display: block;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

// Scrolling

.body::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

// Flexing

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.flex-container-row {
  @extend .flex-container;
  flex-direction: row;
}

.flex-container-column {
  @extend .flex-container;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
  flex-basis: 0;
}

.fxFlex {
  flex: 1 1 1e-9px;
}

.flex {
  flex: 1;
}

.flex-between {
  justify-content: space-between;
}

.flex-inline {
  display: inline-flex;
}

.flex-wrap-content {
  flex-wrap: wrap;
}

.flex-content-end {
  justify-content: flex-end;
}

.flex-content-center {
  justify-content: center;
}

.flex-align-end {
  align-items: flex-end;
}

// Table

.table-cell {
  display: table-cell;
}

.table-row {
  display: table-row;
}
