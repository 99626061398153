@use 'src/scss/font' as f;

$themeColor: #1a1a1a;
$borderThickness: 4px;
$headerBottomBorderThickness: 2px;
$productBottomBorderThickness: 1px;
$circumferencePadding: 3.125rem;
$content-side-padding: 1.6rem;

.plain-jane-theme {
  .company-logo {
    max-height: 7.5rem;
    max-width: 15rem;
  }

  // Display Component

  .display-container {
  }

  .menu-container {
  }

  // Menu Header

  .header-container {
    height: 11rem;
    justify-content: flex-end;
    text-transform: uppercase;
    border: $borderThickness solid $themeColor;
    border-bottom-width: $headerBottomBorderThickness;
    margin-top: $circumferencePadding;
    margin-left: $circumferencePadding;
    margin-right: $circumferencePadding;
    padding: 0;
  }

  .ask-us-table {
    max-width: 25%;
    padding-right: 1rem;
  }

  .main-header {
    @include f.poppins-semi-bold;
    padding-left: $content-side-padding;
    font-size: 3.3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
  }

  // Sections

  .sections-container {
    border: $borderThickness solid $themeColor;
    border-top-width: 0;
    margin-left: $circumferencePadding;
    margin-right: $circumferencePadding;
  }

  // Product Section

  .section-container {
    padding-bottom: 1rem;
  }

  // Product Section Header

  .section-header-container {
    height: 4.5rem;
  }

  .section-header-bottom-border {
    border-bottom-width: $headerBottomBorderThickness;
  }

  .section-title {
    @include f.poppins-extra-bold;
    font-size: 1.8rem;
    letter-spacing: 0;
    align-items: center;
  }

  .section-column {
    align-self: flex-end;
  }

  .section-column-title {
    align-self: flex-end;
  }

  .product-column-general {
    font-size: 1rem;
    line-height: 1.125rem;
    width: 100%;
    @include f.realist-regular;
  }

  .section-header-variant-price {
  }

  .section-header-pre-roll {
  }

  .section-header-producer {
  }

  .section-header-general {
    @include f.realist-regular;
    font-size: 1.25rem;
    line-height: 1.325rem;
    letter-spacing: 0.2px;
    padding-bottom: 0.64rem;
  }

  .section-header-badge {
  }

  .section-header-quantity {
  }

  .section-header-size {
  }

  .section-header-thc {
  }

  .section-header-cbd {
  }

  .section-header-variant-price {
    @include f.realist-bold;
    font-size: 1.25rem;
    line-height: 1.325rem;
    letter-spacing: 0.2px;
  }

  // Image Section

  .image-section {
  }

  // Title Section

  .title-section {
    $title-section-font-size: 2.18rem;
    @include f.poppins-semi-bold;
    padding: 1.78rem $content-side-padding;
    font-size: $title-section-font-size;
    line-height: $title-section-font-size;
    text-transform: uppercase;
    border-bottom: $headerBottomBorderThickness solid $themeColor;
  }

  // Product Section Item

  .product-container {
    height: 3.125rem;
    border-bottom: $productBottomBorderThickness solid $themeColor;
  }

  .product-title-container {
  }

  .product-column {
  }

  .product-title {
    @include f.realist-regular;
    font-size: 1.5rem;
    line-height: 1.625rem;
    letter-spacing: 0.16px;
  }

  .product-subtitle {
    font-size: 1rem;
    line-height: 1.125rem;
    @include f.realist-regular;
  }

  .product-label {
    @include f.realist-bold;
    font-size: 1rem;
    line-height: 1.125rem;
    padding: 0.125rem 0.7rem;
    margin-left: 1rem;
  }

  .base-product-strain-type {
  }

  .product-variant-price {
    @include f.realist-bold;
    font-size: 1.25rem;
    line-height: 1.325rem;
    letter-spacing: 0.2px;
  }

  .product-quantity,
  .product-size,
  .product-quantity-and-size {
    @include f.realist-wide-regular;
    font-size: 1rem;
    line-height: 1.125rem;
  }

  .product-cannabinoid,
  .product-terpene {
    font-size: 1rem;
    line-height: 1.125rem;
    width: 100%;
    @include f.realist-regular;
  }

  .product-brand {
    @include f.realist-wide-bold-italic;
    font-size: 1rem;
    line-height: 1.125rem;
    text-align: start;
  }

  .product-badge {
  }

  .badge {
  }

  .product-low-amount {
  }

  .pre-roll-button {
  }

  // Menu Footer

  .footer-container {
    border: $borderThickness solid $themeColor;
    border-top-width: 0;
    margin-left: $circumferencePadding;
    margin-right: $circumferencePadding;
    margin-bottom: $circumferencePadding;
  }

  .v-align-middle {
  }

  // Product Menu

  .product-menu-container {
  }

  // Font Styling

  .force-bold-style * {
    @include f.realist-extra-bold;
  }
}

html.print-menu {
  .plain-jane-theme {
    .header-container {
      padding: 0 $borderThickness;
    }

    // Ignore border as one will be added to the page on print
    .sections-container {
      border: none;
    }

    .section-container {
      padding: 0 $content-side-padding;
    }

    .product-container {
      &.has-background-color {
        .product-title {
          margin-left: 0.4rem;
        }
      }
    }

    .header-container,
    .sections-container,
    .footer-container {
      margin-left: 0;
      margin-right: 0;
    }

    .header-container {
      margin-top: 0;
      border-top-width: 0;
      border-right-width: 0;
      border-left-width: 0;
    }

    .section-header-general {
    }

    .title-section {
      font-size: 2.18rem;
      padding-left: $content-side-padding;
      text-transform: uppercase;
    }

    .title-section-container {
      padding: 0 !important;
    }

    &.fixed-print-wrapper {
      display: block;
      border: $borderThickness solid $themeColor;
      margin: 0 0;
    }

    .product-variant-price {
      font-size: 1rem;
      line-height: 1.325rem;
    }

    .footer-container {
      border: none;
      margin-bottom: 0;
      padding-top: 1rem;
    }
  }
}
