@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.print-menu.blended-theme {
  page: blended-pages;
}

@page blended-pages {
  margin: 0.25in;
}

$blended-border-color: #222222;

.blended-theme {
  .company-logo-container {
    max-width: 50%;
  }

  .company-logo-container .company-logo {
    max-width: 75%;
    object-position: bottom left;
  }

  // Display Component

  .display-container {
    padding: 25px;
  }

  .fixed-print-wrapper {
    display: block;
    margin: 0 0;
    padding: 0;
  }

  .menu-container {
  }

  // Menu Header

  .header-container {
    @include f.playfair-display-bold;
    padding: 0;
    justify-content: flex-start;
    border-left: 1px solid $blended-border-color;
    border-right: 1px solid $blended-border-color;
  }

  .main-header {
    font-size: 4rem;
    line-height: 9rem; // Line height should be font-size of .main-header + 2x 2.5rem (padding)
    padding-left: 1rem;
    padding-top: 0.5rem;
  }

  // Sections

  .sections-container {
  }

  // Product Section

  .section-container {
    border-left: 1px solid $blended-border-color;
    border-right: 1px solid $blended-border-color;
  }

  // Product Section Header

  .section-header-container {
    height: 54px;
  }

  .section-header-bottom-border {
    border-bottom: 1px solid $blended-border-color;
  }

  .section-title {
    @include f.raleway-bold;
    font-size: 1.5rem;
    margin-left: 1rem;
    text-transform: uppercase;
  }

  .empty-container {
    border-left: 1px solid $blended-border-color;
    border-right: 1px solid $blended-border-color;
    border-bottom: 1px solid $blended-border-color;
  }

  .section-column {
    height: 100%;
    width: 100%;
    display: flex;
    border-top: 2px solid $blended-border-color;
    border-left: 1px solid $blended-border-color;
    text-align: center;
  }

  .section-column-title {
    border: none $blended-border-color;
    border-top: 2px solid $blended-border-color;
  }

  .section-header-general {
    @include f.acumin-pro-extra-condensed-regular;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    text-align: center;
    align-self: center;
  }

  .products-container {
    gap: 1px;
    padding-bottom: 0.5px;
  }

  .product-column-general {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include m.fill-height;
    @include m.fill-width;
  }

  .section-header-pre-roll {
  }

  .section-header-producer {
  }

  .section-header-badge {
  }

  .section-header-quantity {
  }

  .section-header-size {
  }

  .section-header-thc {
  }

  .section-header-cbd {
  }

  .section-header-variant-price {
  }

  .section-header-size {
  }

  // Image Section

  .image-section {
  }

  // Title Section

  .title-section {
    @include f.playfair-display-bold;
    font-size: 4rem;
    line-height: 9rem; // Line height should be font-size + 2x 2.5rem
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid $blended-border-color;
    border-bottom: 1px solid $blended-border-color;
  }

  // Product Section Item

  .product-container {
    font-size: 1.25rem;
    height: 47px;
  }

  .product-title-container {
    @include f.raleway-bold;
    display: flex;
    @include m.fill-height;
    @include m.fill-width;
  }

  .product-column {
    @include f.acumin-pro-extra-condensed-regular;
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    border-left: 1px solid $blended-border-color;
  }

  .product-title {
    align-self: center;
    text-transform: uppercase;
  }

  .product-subtitle {
  }

  .product-label {
    @include f.raleway-medium;
    font-size: 0.75rem;
  }

  .base-product-strain-type {
    @include f.playfair-display-bold;
    font-size: 1rem;
    text-align: center;
    margin-left: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    max-height: unset;
    max-width: 1.5rem;
    padding: 0;
    letter-spacing: -0.5px;
    border-radius: 0;
    line-height: 1.85rem;
    position: relative;
  }

  .product-variant-price {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .product-quantity {
  }

  .product-size {
  }

  .product-cannabinoid,
  .product-terpene {
    font-size: 1.2rem;
  }

  .product-producer {
  }

  .product-brand {
    @include f.raleway-italic;
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 0.3rem;
    overflow-x: visible;
  }

  .product-badge {
    flex-direction: row;
    margin-left: 0.5rem;
  }

  .badge {
    height: 1.5rem;
  }

  .product-low-amount {
  }

  .product-variant-sale-price {
    border-color: $blended-border-color;
  }

  .product-row-overlay {
  }

  .pre-roll-button {
  }

  // Menu Footer

  .footer-container {
    padding: 1rem 0 0 0;
  }

  .v-align-middle {
  }

  // Product Menu

  .product-menu-container {
  }

  // Subheader

  .subheader-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .default-background-color {
  }

  // Font Styling

  .force-bold-style * {
    @include f.raleway-bold;
  }

  .product-menu-container.print-product-menu-container {
    margin: 0;
    padding: 0;
  }
}

html.print-menu {
  .blended-theme {
    .product-column-general,
    .product-title-container {
      height: 100% !important;
    }

    .product-title-container .flex-container {
      height: 100% !important;
    }

    .title-section {
      font-size: 4rem;
      line-height: 9rem; // Line height should be font-size + 2x 2.5rem
    }

    .section-header-container {
      height: 32px;
    }

    .product-container {
      height: 26px;
      overflow: hidden;
      border-bottom: 1px solid $blended-border-color;
    }

    .header-container {
      padding-left: 0;
      padding-right: 0;
    }

    .sections-container {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
