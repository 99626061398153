@use 'src/scss/font' as f;

.framed-theme {
  // Header

  .header-container {
    padding: 1rem 3.125rem 1rem 3.125rem;
  }

  .main-header {
    @include f.poppins-bold;
    text-transform: uppercase;
    font-size: 5.125rem;
    letter-spacing: 0.125rem;
  }

  // Section container

  .framed-sections-container {
    border-radius: 2rem;
    padding: 1rem 0 2rem 0;
  }

  // Sections

  .section-title {
    @include f.poppins-bold;
    text-align: left;
    font-size: 2.75rem;
    line-height: 3rem;
    margin: auto;
  }

  .title-section-container {
    margin-bottom: 0;
  }

  .title-section {
    @include f.poppins-bold;
    font-size: 3.75rem;
  }

  .section-column {
    vertical-align: middle;
    text-align: center;
  }

  .framed-section-container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .framed-section-container-portrait {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .section-container {
  }

  .section-header-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }

  .section-header-variant-price {
    @include f.realist-bold;
    vertical-align: middle;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
    width: 100%;
    float: right;
  }

  .section-header-general {
    @include f.realist-regular;
  }

  .section-header-variant-price {
    @include f.realist-bold;
    vertical-align: middle;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
    width: 100%;
    float: right;
  }

  // Products

  .product-container {
    vertical-align: middle;
    height: 3.9rem;
  }

  .product-title {
    @include f.realist-regular;
    font-size: 1.5rem;
    letter-spacing: 0.03125rem;
  }

  .product-column-general {
    @include f.realist-regular;
    font-size: 1.1rem;
    letter-spacing: 0.01rem;
  }

  .product-label-container {
    vertical-align: middle;
  }

  .product-label {
    @include f.realist-bold;
    font-size: 0.8rem;
    letter-spacing: 0.0625rem;
    vertical-align: middle;
  }

  .product-subtitle {
    @include f.poppins-bold;
    font-size: 0.8rem;
    line-height: 1rem;
    margin-top: 0.5rem;
  }

  .product-cannabinoid,
  .product-terpene {
    @include f.realist-regular;
    font-size: 1.1rem;
    letter-spacing: 0.01rem;
  }

  .product-variant-price {
    @include f.realist-bold;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .product-brand {
    @include f.realist-wide-bold-italic;
    font-size: 1rem;
  }

  .product-quantity {
    @include f.realist-wide-bold-italic;
    font-size: 1rem;
  }

  .product-size {
    @include f.realist-wide-bold-italic;
    font-size: 1rem;
  }

  .product-badge {
    column-gap: 0.5rem;
  }

  .badge {
  }

  // Font Styling

  .force-bold-style * {
    @include f.realist-extra-bold;
  }
}
