@use 'src/scss/variables' as v;
// Import toast style
@import 'ngx-toastr/toastr';

.toast-container .bs-toast.ngx-toastr.toast-success {
  background-color: v.$toastr-success-color;
}
.toast-container .bs-toast.ngx-toastr.toast-info {
  background-color: v.$toastr-info-color;
}
.toast-container .bs-toast.ngx-toastr.toast-warning {
  background-color: v.$toastr-warning-color;
}
.toast-container .bs-toast.ngx-toastr.toast-error {
  background-color: v.$toastr-error-color;
}
