@use 'src/scss/font' as f;
@use 'src/scss/variables' as v;

.print-menu.bella-luna-print-theme {
  page: bella-luna-print-theme-pages;
}

@page bella-luna-print-theme-pages {
  margin: 1in;
}

.bella-luna-print-theme {
  $scale: 1.25;

  .title-section-content-wrapper {
    display: flex;
    justify-content: center;
  }

  .section-container {
    margin-bottom: calc(5.455rem * #{$scale});
  }

  .header-container-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 3.75rem;
  }

  .header-container {
    padding: 0;
    text-align: center;
    width: calc(85% * #{$scale});
  }

  .main-header,
  .title-section {
    @include f.courier-regular;
    font-size: calc(10.545rem * #{$scale});
    width: calc(85% * #{$scale});
    text-wrap: wrap;
  }

  .section-title {
    @include f.courier-bold;
    font-size: calc(4rem * #{$scale});
  }

  .section-header-general,
  .product-title,
  .product-column-general {
    @include f.courier-regular;
    font-size: calc(3.091rem * #{$scale});
    line-height: 100%;
  }

  .product-subtitle {
    @include f.courier-regular;
    font-size: calc(2.909rem * #{$scale});
  }

  .section-header-container,
  .product-container {
    padding-left: calc(0.727rem * #{$scale});
    padding-right: calc(0.727rem * #{$scale});
  }

  .section-header-container {
    padding-top: calc(1.455rem * #{$scale});
    padding-bottom: calc(1.455rem * #{$scale});
  }

  .product-container {
    height: calc(8.545rem * #{$scale});
  }

  .product-column-general {
    .badge {
      height: calc(4.545rem * #{$scale});
    }
  }

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  .product-label {
    @include f.poppins-bold;
    font-size: calc(1.636rem * #{$scale});
  }

  .force-bold-style * {
    @include f.courier-bold;
  }
}
