@use 'src/scss/font' as f;

.print-menu.la-canapa-print-theme {
  page: la-canapa-print-theme-pages;
}

@page la-canapa-print-theme-pages {
  margin: 0.5in;
}

.la-canapa-print-theme {
  $gapBetweenSwimlanes: 1.5rem;
  $la-canapa-sativa-color: #eba900;
  $la-canapa-indica-color: #006068;
  $la-canapa-hybrid-color: #739e69;
  $la-canapa-blend-color: #5690f0;
  $la-canapa-cbd-color: #b8b8b8;
  $la-canapa-subtitle: #4b4b4b;
  $la-canapa-section-title-color: #731818;

  // Header

  .main-header {
    @include f.f36px();
    text-align: left;
    @include f.open-sans-bold();
  }

  .header-container {
    align-items: center;
    justify-content: center;
    padding: 2rem 0 0 1rem;
  }

  .company-logo {
    max-height: 7.5rem;
  }

  // Section container
  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  // Sections

  .title-section {
    @include f.open-sans-bold();
    @include f.f24px();
    line-height: 1.4;
    padding: 0.5rem 1rem;
  }

  .section-header-container {
    height: 1rem;
    padding: 1rem;
  }

  .section-title {
    @include f.open-sans-bold();
    color: $la-canapa-section-title-color;
    @include f.f16px();
  }

  .section-header-general {
    @include f.open-sans-regular();
    @include f.f14px();
    color: $la-canapa-section-title-color;
  }

  .wrapping-swimlane-section-container {
    gap: $gapBetweenSwimlanes;
    margin-bottom: 0;
  }

  // Product

  .product-column-general {
    @include f.open-sans-regular();
    font-size: 0.9rem;
  }

  .product-container {
    align-items: center;
    height: 3rem;
    font-size: 1rem;
  }

  .product-title {
    @include f.open-sans-bold();
    font-size: 1.15rem;
  }

  .product-label {
    @include f.open-sans-regular();
    margin: 0 0.2rem 0 0.1rem;
    font-size: 0.65rem;
    border-radius: 0.3rem;
    width: 3.2rem;
    height: 1rem;
    justify-content: center;
    text-transform: uppercase;
  }

  .product-subtitle {
    @include f.open-sans-semi-bold();
    font-size: 0.9rem;
    color: $la-canapa-subtitle;
  }

  .product-column {
    @include f.open-sans-regular();
    font-size: 1rem;
  }

  .badge {
    height: 1.3rem;
  }

  .product-badge {
    align-items: end;
    justify-items: end;
    float: right;
    align-self: unset;
    width: unset;
    margin-right: 0.5rem;
    flex-wrap: wrap;
  }

  .product-column-Badge {
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
  }

  .classification-name {
    font-size: 0.9rem;
    margin-top: 0.1rem;
  }

  .product-strain-type {
    @include f.open-sans-bold();
    font-size: 0.8rem;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-right: 0.4rem;
    margin-left: 0.2rem;

    &.s,
    &.h,
    &.i,
    &.b,
    &.c {
      color: white;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
    }

    &.sativa,
    &.hybrid,
    &.indica,
    &.blend,
    &.cbd {
      color: white;
      width: 3.5rem;
      height: 1.5rem;
      border-radius: 0.5rem;
    }

    &.s,
    &.sativa {
      background-color: $la-canapa-sativa-color !important;
    }

    &.h,
    &.hybrid {
      background-color: $la-canapa-hybrid-color !important;
    }

    &.i,
    &.indica {
      background-color: $la-canapa-indica-color !important;
    }

    &.b,
    &.blend {
      background-color: $la-canapa-blend-color !important;
    }

    &.c,
    &.cbd {
      background-color: $la-canapa-cbd-color !important;
    }
  }

  // Font Styling

  .force-bold-style * {
    @include f.open-sans-bold-extra();
  }
}
