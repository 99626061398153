@use 'src/scss/font' as f;

.night-theme {
  // Header

  .main-header {
    @include f.rubik-bold;
    font-size: 5rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }

  .header-container {
    height: 9.75rem;
  }

  .company-logo {
    max-width: 20rem;
    max-height: 4.25rem;
  }

  // Section container

  .sections-container {
    margin-left: 2.125rem;
    margin-right: 2.125rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  // Sections

  .title-section {
    font-size: 4rem;
    letter-spacing: 0.125rem;
    @include f.rubik-medium;
    padding: 0.5rem;
  }

  .title-section-container {
  }

  .section-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-column-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .section-header-container {
    height: 5.125rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }

  .section-title {
    @include f.rubik-medium;
    line-height: 3rem;
    font-size: 2.75rem;
    letter-spacing: 0.01625rem;
    align-self: center;
  }

  .section-header-pre-roll {
    @include f.rubik-medium;
    vertical-align: middle;
    font-size: 1.5rem;
    letter-spacing: 0.0125rem;
  }

  .section-header-general {
    @include f.rubik-medium;
    font-size: 1.5rem;
    letter-spacing: 0.0125rem;
  }

  // Product

  .product-container {
    align-items: center;
    height: 5rem;
    padding: 0 0.5rem;
  }

  .product-title {
    @include f.rubik-regular;
    font-size: 1.5rem;
    letter-spacing: 0.03125rem;
  }

  .product-label-container {
    vertical-align: middle;
  }

  .product-label {
    @include f.rubik-regular;
    font-size: 0.8rem;
    letter-spacing: 0.0625rem;
    vertical-align: middle;
  }

  .product-subtitle {
    @include f.rubik-regular;
    font-size: 1.4rem;
    letter-spacing: 0.009375rem;
    margin-top: 0.25rem;
  }

  .product-variant-price {
    @include f.rubik-medium;
    font-size: 1.5rem;
    letter-spacing: 0.0125rem;
  }

  .product-cannabinoid,
  .product-terpene {
    @include f.rubik-regular;
  }

  .product-low-amount {
    @include f.rubik-regular;
    font-size: 1.4rem;
  }

  .product-column {
    @include f.rubik-medium;
    font-size: 1.5rem;
  }

  .product-badge {
    padding-right: 1rem;
  }

  .product-badge {
    justify-content: flex-end;
  }

  .badge {
  }

  .header-xor-footer-height {
    height: 100%;
  }

  .no-footer {
    padding-bottom: 0;
  }

  // Font Styling

  .force-bold-style * {
    @include f.rubik-bold;
  }
}
