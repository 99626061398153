@use 'src/scss/font' as f;

.contemporary-theme {
  // Header

  .main-header {
    font-size: 5rem;
    letter-spacing: 0;
    text-align: left;
    @include f.apparat-medium;
  }

  // Section container

  .sections-container {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  // Sections

  .title-section {
    @include f.pragmatica-extended-medium;
    font-size: 3.75rem;
  }

  .section-container {
    padding-left: 0;
    padding-right: 0;
    margin: 0 0.5rem 2rem 0.5rem;
  }

  .section-header-container {
    height: 3rem;
    padding-bottom: 0.25rem;
    border-bottom: none;
  }

  .section-title {
    @include f.pragmatica-extended-medium;
    font-size: 3rem;
  }

  .section-header-pre-roll {
    @include f.pragmatica-extended-medium;
    vertical-align: middle;
    font-size: 1.25rem;
  }

  .section-header-general {
    @include f.pragmatica-extended-medium;
    font-size: 1.5rem;
  }

  // Product

  .product-column-general {
    @include f.pragmatica-book;
    font-size: 1.2rem;
  }

  .product-container {
    align-items: center;
    height: 4.25rem;
  }

  .product-title {
    @include f.pragmatica-book;
    font-size: 1.6rem;
  }

  .product-label {
    @include f.pragmatica-book;
    margin: 0 0 0 0.8rem;
    font-size: 0.85rem;
  }

  .product-subtitle {
    @include f.pragmatica-book;
    font-size: 1rem;
  }

  .product-variant-price {
    font-size: 1.5rem;
  }

  .product-quantity {
    font-size: 1.125rem;
  }

  .product-size {
    font-size: 1.2rem;
  }

  .product-column {
    @include f.pragmatica-book;
    font-size: 1.25rem;
  }

  .base-product-strain-type {
    @include f.pragmatica-book;
  }

  .product-low-amount {
    @include f.pragmatica-light;
  }

  // Font Styling

  .force-bold-style * {
    @include f.arial-bold;
  }
}
