@mixin fixed-top-left {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

@mixin takeUpParent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin topHalfOfParent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 50%;
}

@mixin cardBlackShadow {
  box-shadow: 1px 1px 3px #000000;
}

@mixin textBlackShadow {
  text-shadow: 1px 1px 2px #222222;
}

@mixin textWhiteShadow {
  text-shadow: 1px 1px 2px white;
}

/**
 * The default value of flex-shrink of a flex item is 1, which means your div should be able to shrink as much as it
 * wants to. Surprisingly, the result is not what we expected! The flexbox algorithm refuses to shrink a child below
 * its minimum size. When there is text inside an element, the minimum size is determined by the length of the longest
 * string of characters that cannot be broken.
 *
 * By explicitly setting min-width: 0; on a flex item, we can override the default behavior and allow the element
 * to shrink beyond its automatic minimum size. This change enables the flex item to adjust its size to accommodate
 * the ellipsis and prevent UI disruption.
 *
 * Without min-width: 0, many places within the app that use no-wrap within a flex container will not work as expected
 * and the ellipsis will not be displayed when the text overflows.
 */
@mixin no-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
}

@mixin pre-no-wrap {
  white-space: pre;
  text-overflow: ellipsis;
  overflow-x: clip;
}

@mixin line-clamp($nLines, $clipMargin: 0.1rem, $word-break: break-word) {
  overflow: clip;
  overflow-clip-margin: $clipMargin;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: $word-break;
  -webkit-line-clamp: $nLines;
  -webkit-box-orient: vertical;
}

@mixin fill-height {
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: stretch;
}

@mixin fill-width {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
}

/*
 * "overflow: overlay" is not the same as "overflow : auto".
 * In case you style the scrollbar by giving it some width,
 * then both these properties have different effects. In case of overlay,
 * the scrollbar does not push the content in anyway as it floats above the
 * content and is not in the normal document flow. But in case of "overflow: auto",
 * the scrollbar occupies some width (as specified by you),
 * and pushes the content just as any inflow element would. – darKnight Mar 14 '16 at 11:57
 *
 * https://stackoverflow.com/questions/8543664/overflow-overlay-doesnt-work-in-firefox
 */

@mixin overlay-y {
  // gives warning that overflow-y is being overwritten, which is intended, because firefox doesn't support
  // overflow: overlay, so it will use auto instead.
  overflow-y: auto;
  overflow-y: overlay;
}

@mixin overlay-x {
  // gives warning that overflow-x is being overwritten, which is intended, because firefox doesn't support
  // overflow: overlay, so it will use auto instead.
  overflow-x: auto;
  overflow-x: overlay;
}
