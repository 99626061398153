@use 'src/scss/font' as f;
@use 'src/scss/variables' as v;
@use 'src/scss/mixins' as m;

.true-north-dark-theme {
  $scale: 1.25;
  $title-section-height: 3.625rem;

  .background-asset {
    border-radius: 1rem;
  }

  // Header

  .main-header {
    @include f.montserrat-extra-bold;
    font-size: calc(4rem * #{$scale});
    letter-spacing: 0;
    text-align: left;
  }

  .header-container {
    padding: 1rem 1.75rem 1rem 1.75rem;
    justify-content: center;
  }

  .header-meta-desc {
    font-size: calc(1.5rem * #{$scale});
    @include f.montserrat-bold;
    color: black;
  }

  .company-logo {
    padding-top: 0.5rem;
  }

  // Section container

  .sections-container {
    margin: 0 1.25rem 0 1.25rem;
  }

  // Sections

  .image-section {
    margin: 2.5rem 0;
  }

  .title-section {
    @include m.line-clamp(1);
    @include f.montserrat-extra-bold;
    font-size: calc(2rem * #{$scale});
    padding: 0.75rem 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: $title-section-height;

    &.dark-text {
      color: v.$bs-black-color;
    }

    &.light-text {
      color: v.$bs-white-color;
    }
  }

  .section-container {
    margin: 0 1.25rem;
    overflow: hidden;
  }

  .title-section-container {
    color: black;
    border-radius: 1rem 1rem 0 0;
    margin-bottom: 0;
  }

  .section-header-container {
    padding: 0.3125rem 0 0.1875rem 0;
    background-color: #eaeaea;
    color: #231f20;
  }

  .section-title {
    @include f.montserrat-extra-bold;
    font-size: calc(0.875rem * #{$scale});
    letter-spacing: -0.02em;
  }

  .section-title-text {
    padding-left: 0.5rem;
  }

  .section-column {
    box-sizing: border-box;
    padding-left: 0.5rem;
  }

  .section-header-general {
    @include f.montserrat-extra-bold;
    font-size: calc(0.875rem * #{$scale});
    text-align: left;
    vertical-align: middle;
  }

  .section-header-badge {
    display: flex;
    justify-content: center;
  }

  // Product

  .product-section-container {
    &.first-on-page,
    &.after-asset-section {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    &.before-title-section,
    &.last-product-section-on-page,
    &.before-asset-section {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }

  .title-section-container {
    &.first-on-page {
      margin-top: 0 !important;
    }
  }

  .product-column-general {
    @include f.montserrat-medium;
    font-size: calc(0.875rem * #{$scale});
  }

  .product-container {
    align-items: center;
    height: 2.35rem;
  }

  .product-title-flex-container {
    gap: 0.5rem;
  }

  .product-title {
    @include f.montserrat-bold;
    font-size: calc(0.875rem * #{$scale});
    letter-spacing: -0.02em;
    padding-left: 1rem;
  }

  .product-label {
    @include f.montserrat-semi-bold;
    font-size: calc(0.65rem * #{$scale});
    letter-spacing: -0.02em;
    margin: 0;
  }

  .product-subtitle {
    @include f.montserrat-medium;
    line-height: 1rem;
    font-size: calc(0.8125rem * #{$scale});
    letter-spacing: -0.02em;
  }

  .product-low-amount {
    @include f.montserrat-medium;
  }

  .product-column {
    @include f.montserrat-medium;
    font-size: calc(0.875rem * #{$scale});
    text-align: left;
    box-sizing: border-box;
    padding-left: 0.5rem;
  }

  .product-badge {
    text-align: center;
  }

  .badge {
    height: 2rem;
  }

  .product-row-overlay {
  }

  // Font Styling

  .force-bold-style * {
    @include f.montserrat-extra-bold;
  }
}
