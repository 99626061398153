@use 'src/scss/font' as f;

.lobster-butter-theme {
  $lobster-butter-sativa: #c75314;
  $lobster-butter-indica: #7ab5b6;
  $lobster-butter-hybrid: #478e79;
  $lobster-butter-blend: #8e8ba6;
  $lobster-butter-cbd: #efefef;

  // Header

  .main-header {
    @include f.merriweather-ultra-bold();
    font-size: 4rem;
    text-transform: uppercase;
  }

  // Sections

  .title-section {
    @include f.merriweather-ultra-bold();
    font-size: 3rem;
    text-transform: uppercase;
  }

  .section-container {
    margin-bottom: 1rem;
    padding-right: 1.5rem;
  }

  .section-header-container {
    @include f.merriweather-bold();
    height: 3.625rem;
    padding-left: 0.5rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-header-general {
    font-size: 1rem;
    text-align: start;
  }

  .section-header-badge {
    @include f.merriweather-bold();
    font-size: 1rem;
    text-align: center;
  }

  // Product

  .product-container {
    height: 2.875rem;
  }

  .product-column {
    flex-direction: row;
  }

  .product-column.product-column-StrainType {
    flex-direction: column;
    padding-left: 0.2rem;
  }

  .product-column-general {
    @include f.roboto-medium();
    font-size: 1rem;
    text-align: start;
  }

  .product-cannabinoid,
  .product-terpene,
  .product-quantity,
  .product-variant-price-integer,
  .product-size,
  .product-variant-secondary-price {
    padding-left: 0.25rem;
  }

  .product-strain-type {
    color: #ffffff;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.s,
    &.i,
    &.b,
    &.h,
    &.c {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
    }

    &.sativa,
    &.indica,
    &.blend,
    &.hybrid,
    &.cbd {
      width: 3.4rem;
      height: 1.8rem;
      border-radius: 0.5rem;
    }

    &.sativa,
    &.s {
      background-color: $lobster-butter-sativa;
    }

    &.indica,
    &.i {
      background-color: $lobster-butter-indica;
    }

    &.blend,
    &.b {
      background-color: $lobster-butter-blend;
    }

    &.hybrid,
    &.h {
      background-color: $lobster-butter-hybrid;
    }

    &.cbd,
    &.c {
      background-color: $lobster-butter-cbd;
    }
  }

  .product-title-container {
    padding-left: 0.5rem;
    padding-right: 0;
  }

  .product-title {
    @include f.f20px();
    @include f.roboto-medium();
  }

  .product-subtitle {
    @include f.f12px();
    @include f.roboto-regular();
  }

  .product-label {
    @include f.f12px();
    @include f.roboto-medium();
    padding: 0.25rem 0.5rem;
  }

  .product-badge {
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
  }

  .badge {
    height: 1.625rem;
  }

  // Font Styling

  .force-bold-style * {
    @include f.roboto-black();
  }
}
