@use 'src/scss/font' as f;

.jimmy-theme {
  // Header

  .main-header {
    font-size: 6rem;
    letter-spacing: 2px;
    text-align: left;
    padding-left: 1rem;
    @include f.eds-market-narrow-slant;
  }

  .company-logo {
    max-height: 7.5rem;
  }

  // Section container

  .sections-container {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  // Sections

  .title-section {
    @include f.eds-market-narrow-slant;
    font-size: 5rem;
    margin-left: 0.4rem;
    line-height: 1;
  }

  .section-container {
    padding-left: 0;
    padding-right: 0;
    margin: 0 0.5rem 2rem 0.5rem;
  }

  .section-header-container {
    height: 3rem;
    padding-bottom: 0.25rem;
    border-bottom: none;
  }

  .section-title {
    @include f.futura-pt-heavy;
    font-size: 3rem;
  }

  .section-header-pre-roll {
    @include f.futura-pt-heavy;
    vertical-align: middle;
    font-size: 1.25rem;
  }

  .section-header-general {
    @include f.futura-pt-heavy;
    font-size: 1.25rem;
  }

  // Product

  .product-column-general {
    @include f.futura-pt-heavy;
    font-size: 1rem;
  }

  .product-container {
    align-items: center;
    height: 4.25rem;
  }

  .product-title {
    @include f.futura-pt-heavy;
    font-size: 1.4rem;
  }

  .product-label {
    @include f.futura-pt-heavy;
    margin: 0 0 0 0.8rem;
    font-size: 0.85rem;
  }

  .product-subtitle {
    @include f.futura-pt-heavy;
    font-size: 0.85rem;
  }

  .product-variant-price {
    font-size: 1.25rem;
  }

  .product-quantity {
    font-size: 1.125rem;
  }

  .product-size {
    font-size: 1.125rem;
  }

  .product-quantity-and-size {
    font-size: 1.125rem;
  }

  .product-column {
    display: grid;
    @include f.futura-pt-heavy;
    font-size: 1.25rem;
  }

  .base-product-strain-type {
    @include f.futura-pt-heavy;
  }

  // Font Styling

  .force-bold-style * {
    @include f.futura-pt-extra-bold;
  }
}

html.print-menu {
  .jimmy-theme {
    .product-container {
      height: 44px;
    }

    .title-section {
      font-size: 6rem;
    }

    .footer-container {
      padding-bottom: 0;
    }

    .header-container,
    .section-container {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
    }

    .sections-container {
      margin-left: 0;
      margin-right: 0;
    }

    .section-title {
      align-self: flex-end;
    }
  }
}
