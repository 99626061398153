@use 'src/scss/font' as f;

.print-menu.og-kush-print-theme {
  page: og-kush-print-theme-pages;
}

@page og-kush-print-theme-pages {
  margin: 0.5in;
}

.og-kush-print-theme {
  $og-kush-border: 1px solid #1c1c1c;

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  // Header

  .header-container {
    padding-right: 0;
    padding-left: 0.727rem;
    border-bottom: 0.364rem solid #222;
  }

  .main-header {
    @include f.poppins-bold;
    font-size: 3.636rem;
  }

  // Section

  .section-container {
    padding-top: 2.182rem;
  }

  .section-header-container {
    @include f.poppins-bold;
    align-items: stretch;
    height: 3.818rem;
    border-bottom: $og-kush-border;
  }

  .section-header-general {
    font-size: 1.091rem;
  }

  .section-column {
    align-self: unset;
    display: flex;
    align-items: center;
    border-right: $og-kush-border;
  }

  .section-column:last-child {
    border-right: none;
  }

  .section-title {
    padding-left: 0.727rem;
    font-size: 1.455rem;
  }

  .title-section {
    @include f.poppins-bold;
    display: flex;
    align-items: center;
    font-size: 2.727rem;
    padding-left: 0.636rem;
  }

  // Products

  .product-container {
    @include f.poppins-regular;
    align-items: stretch;
    font-size: 1.091rem;
    height: 3.818rem;
    letter-spacing: -0.02em;
  }

  .product-title-container {
    padding: 0.273rem 0.364rem 0.273rem 0.727rem;
    border-right: $og-kush-border;
    border-bottom: $og-kush-border;
  }

  .product-title {
    @include f.poppins-semi-bold;
    font-size: 1.273rem;
  }

  .product-subtitle {
    @include f.poppins-medium;
    font-size: 0.909rem;
  }

  .product-label {
    @include f.rubik-medium;
    font-size: 0.909rem;
    padding: 0.091rem 0.727rem;
    order: 2;
  }

  .product-badge {
    order: 1;
    display: flex;
    margin-left: 0.727rem;
  }

  .badge {
    height: 1.75rem;
  }

  .product-column {
    height: unset;
    border-right: $og-kush-border;
    border-bottom: $og-kush-border;
  }

  .product-column:last-child {
    border-right: none;
  }

  .product-column-VariantPrice {
    @include f.poppins-semi-bold;
    font-size: 1.273rem;
  }

  // Footer

  .footer-container {
    margin-top: 2.182rem;
    border-top: 0.364rem solid #222;
  }

  // Font Styles
  .force-bold-style * {
    @include f.poppins-bold;
  }
}
