@use 'src/scss/font';
@use 'src/scss/variables';
@use 'src/scss/mixins';

$double-dutch-border-thickness: 3.5px;
$double-dutch-border-radius: 0.7275rem;
$double-dutch-black: #000000;
$double-dutch-white: #ffffff;
$double-dutch-tan: #ebdcaf;

$double-dutch-sativa-classification-symbol: #b52a23;
$double-dutch-indica-classification-symbol: #006838;
$double-dutch-hybrid-classification-symbol: #9594d2;
$double-dutch-blend-classification-symbol: #edae44;
$double-dutch-cbd-classification-symbol: #0075c9;

.print-menu.double-dutch-theme {
  page: double-dutch-theme-pages;
}

@page double-dutch-theme-pages {
  margin: 0.25in;
}

@mixin double-dutch-top-border {
  border-top: $double-dutch-border-thickness solid;
}

@mixin double-dutch-right-border {
  border-right: $double-dutch-border-thickness solid;
}

@mixin double-dutch-left-border {
  border-left: $double-dutch-border-thickness solid;
}

@mixin double-dutch-bottom-border {
  border-bottom: $double-dutch-border-thickness solid;
}

@mixin double-dutch-all-borders {
  border: $double-dutch-border-thickness solid;
}

.double-dutch-theme {
  .background-asset {
    border-radius: $double-dutch-border-radius !important;
  }

  // Header
  .header-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 !important;
    gap: 0.5rem;

    .main-header {
      @include font.oswald-medium;
      font-size: 2.5rem;
      text-align: center;
      max-width: 100%;
    }

    .company-logo {
      height: 8.18rem !important;
      max-height: 8.18rem;
    }
  }

  .section-column-title {
    color: $double-dutch-black;
  }

  // Products Container
  .products-container {
    background-color: $double-dutch-white;
    display: flex;
    flex-direction: column;
    border-radius: $double-dutch-border-radius;
    color: $double-dutch-tan;
    overflow: hidden;
    @include double-dutch-left-border;
    @include double-dutch-right-border;
    @include double-dutch-top-border;
  }

  // Section Container
  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  // Sections
  .title-section {
    @include font.oswald-medium;
    font-size: 3.75rem;
  }

  .section-column {
    @include font.oswald-medium;
    font-size: 1.36rem;

    &.section-column-classification {
      flex: 1;
    }
  }

  .section-container {
    margin-left: 2rem;
    margin-right: 2rem;
    flex: 1;
    overflow: unset;
    margin-top: 1.25rem;
    border-radius: $double-dutch-border-radius $double-dutch-border-radius 0 0;
  }

  .section-header-title {
    @include font.oswald-bold;
    @include font.f24px;
    letter-spacing: 0.0625rem;
    color: $double-dutch-black;
    text-align: center;
    padding: 0.25rem;
  }

  .section-header-container {
    flex-direction: row;
    height: 2.36rem;
    background-color: $double-dutch-tan;
    padding: 0.75rem 0;
    color: $double-dutch-black;
  }

  .section-header-asset {
  }

  .split-product-header-inflator.odd {
    .section-header-container {
      border-radius: $double-dutch-border-radius 0 0 0;
    }
  }

  .split-product-header-inflator {
    .section-header-container {
      border-radius: 0 $double-dutch-border-radius 0 0;
      position: relative;
    }

    .gap-filler {
      position: absolute;
      margin-top: 2rem;
      margin-left: 0;
      background-color: $double-dutch-tan;
      height: 2rem;
      left: 0;
      right: 0;
    }
  }

  .section-title {
    display: block;
    text-transform: uppercase;
    max-width: 12rem;
    margin-left: 0.35rem;
    @include mixins.no-wrap;
  }

  .section-column-thc-and-cbd {
  }

  .section-column-price {
  }

  .section-header-general {
    @include font.oswald-medium;
    @include font.f20px;
    color: $double-dutch-black;
  }

  // Product

  .split-product-section-container {
  }

  .product-column-general {
    @include font.oswald-medium;
  }

  .product-container {
    align-items: center;
    height: 4.72rem;
    color: $double-dutch-black;

    &.odd {
      @include double-dutch-right-border;
    }

    &.last {
      border-bottom: unset;
    }
  }

  .product-strain-type {
    @include font.oswald-bold;
    font-size: 1rem;
    &.s,
    &.sativa {
      color: $double-dutch-sativa-classification-symbol;
    }
    &.h,
    &.hybrid {
      color: $double-dutch-hybrid-classification-symbol;
    }
    &.i,
    &.indica {
      color: $double-dutch-indica-classification-symbol;
    }
    &.b,
    &.blend {
      color: $double-dutch-blend-classification-symbol;
    }
    &.c,
    &.cbd {
      color: $double-dutch-cbd-classification-symbol;
    }
  }

  .product-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.18rem;
    height: 100%;
    padding-left: 0.25rem; // 4px
  }

  .product-title-flex-container {
  }

  .product-title {
    @include font.oswald-medium;
    font-size: 1rem;
  }

  .product-badge {
    display: flex;
    gap: 0.5rem;
  }

  .badge {
    height: 1.2rem;
  }

  .product-label {
    @include font.oswald-medium;
    @include font.f12px;
    margin-left: 0;
    border-radius: 0.2rem;
    padding: 0.125rem 0.4rem;
    align-self: end;
  }

  .subtitle-flex-container {
    gap: 0.75rem;
  }

  .product-subtitle {
    @include font.oswald-light;
    font-size: 1rem;
  }

  .product-thc-and-cbd {
  }

  .product-variant-price {
  }

  .product-quantity {
  }

  .product-low-amount {
    @include font.oswald-medium;
  }

  .product-size {
    font-size: 1rem;
  }

  .product-column {
    @include font.oswald-medium;
    font-size: 1rem;
  }

  .base-product-strain-type {
    @include font.oswald-medium;
    font-size: 1rem;
  }

  .product-image-column {
    display: flex;
    justify-content: flex-end;
    overflow-x: visible;
    align-items: center;
    max-height: 100%;
  }

  .product-image {
    height: 90%;
    width: inherit;
  }

  .image-section {
    margin-top: 0;
  }

  .classification-mapping {
    height: 1.82rem;
    align-items: center;
  }

  .empty-container {
    margin-left: 2rem;
    margin-right: 2rem;
    background-color: $double-dutch-white;
    color: $double-dutch-tan;
    position: relative;
    @include double-dutch-right-border;
    @include double-dutch-left-border;

    .sativa-products {
      flex: 1 1 100%;
      max-width: 50%;
      @include double-dutch-right-border;
    }

    .hybrid-products {
      display: none;
    }

    .indica-products {
      flex: 1 1 100%;
      max-width: 50%;
      // this is required to keep the line in the middle of the empty container centered
      border-right: $double-dutch-border-thickness solid rgba(255, 255, 255, 0);
    }

    .gap-filler {
      height: 1rem;
      position: absolute;
      z-index: -1;
      margin-top: -0.25rem;
      left: 0;
      right: 0;
    }
  }

  // Font Styling
  .force-bold-style * {
    @include font.oswald-bold;
  }
}
