@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.print-menu.space-monkey-print-theme {
  page: space-monkey-print-theme-pages;
}

@page space-monkey-print-theme-pages {
  margin: 0.25in;
}

.space-monkey-print-theme {
  // Header

  .header-container {
    flex-direction: row-reverse;
    justify-content: center;
    padding-inline: 0;
  }

  .main-header {
    @include f.rubik-medium;
    text-align: center;
    font-size: 3.818rem;
    letter-spacing: -0.02em;
  }

  // Sections

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  .section-container {
    margin-bottom: 2.182rem;
  }

  .title-section {
    @include f.roboto-medium-extra;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 0.727rem;
    padding: 0.636rem 0.727rem;
    font-size: 2.545rem;
    letter-spacing: 0.05em;
  }

  .section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.636rem;
    padding-bottom: 0.636rem;
    margin-bottom: 0.091rem;
  }

  .section-title-text {
    @include f.roboto-medium;
    font-size: 1.273rem;
    letter-spacing: 0.05em;
    @include m.no-wrap;
  }

  // Section Headers

  .section-header-container {
    padding: 0.636rem 0 0.636rem 0.727rem;
  }

  .section-header-general {
    @include f.roboto-medium-extra;
    font-size: 1.273rem;
    letter-spacing: 0.05em;
    text-align: center;
  }

  .section-title,
  .section-header-product-title,
  .section-header-producer,
  .section-header-brand,
  .section-header-classification {
    text-align: start;
  }

  // Products

  .product-container {
    @include f.roboto-medium;
    height: 2.545rem;
    padding-left: 0.727rem;
    font-size: 1.091rem;
    color: #000;
    letter-spacing: 0.05em;
  }

  .product-brand {
    @include f.roboto-medium-extra;
    text-align: start;
  }

  .product-title-container {
    padding-right: 2.55rem;
  }

  .product-title-flex-container {
    align-items: center;
  }

  .product-label {
    @include f.roboto-medium;
    padding: 0.25rem 0.5rem;
    font-size: 0.727rem;
    border-radius: 0.364rem;
  }

  .product-strain-type {
    letter-spacing: -0.03em;
    text-align: start;
  }

  .product-cannabinoid,
  .product-terpene,
  .product-quantity,
  .product-variant-price,
  .product-quantity-and-size,
  .product-size {
    letter-spacing: -0.03em;
    text-align: center;
  }

  .product-variant-price {
    @include f.roboto-bold;
    letter-spacing: -0.03em;
    text-align: center;
  }

  .product-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .badge {
    height: 1.273rem;
  }

  .classification-name {
    @include f.roboto-medium;
    font-size: 10px;
  }

  .footer-container {
    padding-top: 1rem;
    padding-bottom: 0;

    .footer-classification-board {
      padding: 0;
    }
  }

  // Font Styles
  .force-bold-style * {
    @include f.roboto-bold;
  }
}
