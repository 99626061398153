@use 'src/scss/font' as f;

.tight-theme {
  // Header

  .main-header {
    @include f.rubik-bold;
    font-size: 4rem;
    letter-spacing: 0;
    text-align: left;
  }

  // Section container

  .sections-container {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  // Sections

  .title-section {
    @include f.rubik-medium-bold;
    font-size: 3rem;
    letter-spacing: -0.02em;
    padding: 0.5rem;
  }

  .section-container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .section-header-container {
    height: 3.25rem;
    border-bottom: 0.125rem solid;
    padding: 0.5rem 0 0.5rem 1rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .section-title {
    @include f.rubik-medium-bold;
    font-size: 2.25rem;
    letter-spacing: -0.02em;
  }

  .section-header-pre-roll {
    @include f.rubik-medium-bold;
    vertical-align: middle;
    font-size: 1.5rem;
    letter-spacing: -0.02em;
  }

  .section-header-general {
    @include f.rubik-medium-bold;
    font-size: 1.5rem;
    letter-spacing: -0.02em;
  }

  .section-header-variant-price {
    text-align: center;
  }

  // Product

  .product-column-general {
    @include f.lato-regular;
    font-size: 1.125rem;
  }

  .product-container {
    align-items: center;
    height: 3.25rem;
    padding: 0 0 0 1rem;
  }

  .product-title {
    @include f.lato-bold;
    font-size: 1.125rem;
    letter-spacing: -0.02em;
  }

  .product-label {
    @include f.lato-regular;
    margin: 0 0.5rem;
    font-size: 0.75rem;
    letter-spacing: -0.02em;
  }

  .product-subtitle {
    @include f.lato-regular;
    line-height: 1rem;
    font-size: 0.8125rem;
    letter-spacing: -0.02em;
  }

  .product-variant-price,
  .product-cannabinoid,
  .product-terpene {
    @include f.lato-regular;
    font-size: 1.125rem;
    letter-spacing: -0.02em;
  }

  .product-variant-price {
    text-align: center;
  }

  .product-quantity,
  .product-size {
    font-size: 1.125rem;
  }

  .product-low-amount {
    @include f.lato-regular;
    font-size: 1.125rem;
  }

  .product-column {
    @include f.lato-regular;
    font-size: 1.125rem;
  }

  .badge {
    height: 2rem;
  }

  .product-row-overlay {
    border-radius: 0.25rem;
  }

  .tight-print {
    height: 2.5rem;
  }

  // Font Styling

  .force-bold-style * {
    @include f.lato-black;
  }
}

html.print-menu {
  .tight-theme {
    .section-header-container {
      height: 32px;
    }

    .title-section {
      font-size: 3.2rem;
      line-height: 1.3;
    }

    .product-container {
      height: 26px;
    }

    .header-container,
    .section-container {
      padding-left: 0;
      padding-right: 0;
    }

    .sections-container {
      margin-left: 0;
      margin-right: 0;
    }

    .footer-container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
