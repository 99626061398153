@use 'src/scss/font' as f;

.clean-theme {
  .main-header {
    @include f.playfair-display-regular();
    font-size: 4.625rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .section-header-container {
    @include f.playfair-display-regular();
    font-size: 2rem;
    letter-spacing: 0.01625rem;
    margin-top: 1.25rem;
  }

  .section-header-general {
    @include f.playfair-display-regular();
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.01625rem;
  }

  .product-label {
    @include f.open-sans-regular();
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  .product-title {
    @include f.open-sans-regular();
    font-size: 1.375rem;
    letter-spacing: 0.01625rem;
  }

  .product-container {
    height: 4.1rem;
    align-items: center;
  }

  .product-subtitle {
    @include f.open-sans-regular();
    font-size: 0.875rem;
    letter-spacing: 0.01625rem;
  }

  .product-column-general {
    @include f.open-sans-regular();
    font-size: 1.25rem;
    letter-spacing: 0.01625rem;
  }

  .badge {
    margin-left: 0.625rem;
    height: 1.25rem;
  }

  .product-badge {
    align-self: center;
  }

  .section-container {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .sections-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  // Font Styling

  .force-bold-style * {
    @include f.open-sans-semi-bold();
  }
}
