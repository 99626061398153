@use 'src/scss/font' as f;

.lux-leaf-theme {
  $lux-leaf-sativa: #fa8128;
  $lux-leaf-indica: #663399;
  $lux-leaf-hybrid: #339933;
  $lux-leaf-blend: #993369;
  $lux-leaf-cbd: #4690a8;

  // Header

  .header-container {
    padding: 0 1rem 0 1rem;
  }

  .main-header {
    @include f.karla-bold-italic();
    font-size: 4rem;
    text-align: center;
  }

  // Sections

  .sections-container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .title-section {
    @include f.karla-bold();
    font-size: 3rem;
    text-transform: uppercase;
  }

  .section-container {
    margin: 0 0.5rem 1rem 0.5rem;
  }

  .section-header-container {
    @include f.karla-bold-italic();
  }

  .section-title {
    font-size: 3rem;
  }

  .section-header-general {
    @include f.karla-medium-italic();
    font-size: 2rem;
    text-align: center;
  }

  .section-header-badge {
    text-align: center;
  }

  // Product

  .product-container {
    height: 3.75rem;
    border-bottom: 1px solid gray;
  }

  .product-column {
    flex-direction: row;
  }

  .product-column.product-column-StrainType {
    flex-direction: column;
  }

  .product-column-general {
    @include f.karla-regular();
    @include f.f26px();
    text-align: center;
    letter-spacing: -0.05rem;
  }

  .product-column-Brand {
    letter-spacing: -0.1rem;
  }

  .product-quantity-and-size {
    line-height: 1;
  }

  .product-variant-price {
    line-height: 1;
  }

  .product-cannabinoid,
  .product-terpene,
  .product-quantity,
  .product-variant-price-integer,
  .product-size,
  .product-variant-secondary-price {
  }

  @mixin classification-symbol {
    height: 1rem;
    width: 1rem;
    font-size: 0;
    border-radius: 50%;
  }

  @mixin color-classification-symbol($color) {
    background-color: $color;
    color: $color;
  }

  .product-strain-type {
    color: #ffffff;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include classification-symbol();
    @include f.karla-regular();

    &.s {
      @include color-classification-symbol($lux-leaf-sativa);
    }

    &.sativa {
      background-color: $lux-leaf-sativa;
    }

    &.i {
      @include color-classification-symbol($lux-leaf-indica);
    }

    &.indica {
      background-color: $lux-leaf-indica;
    }

    &.h {
      @include color-classification-symbol($lux-leaf-hybrid);
    }

    &.hybrid {
      background-color: $lux-leaf-hybrid;
    }

    &.b {
      @include color-classification-symbol($lux-leaf-blend);
    }

    &.blend {
      background-color: $lux-leaf-blend;
    }

    &.c {
      @include color-classification-symbol($lux-leaf-cbd);
    }

    &.cbd {
      background-color: $lux-leaf-cbd;
    }

    &.sativa,
    &.indica,
    &.hybrid,
    &.blend,
    &.cbd {
      color: white;
      width: 4rem;
      height: 1.8rem;
      border-radius: 0.25rem;
      @include f.f18px();
    }
  }

  .product-title-container {
    padding-left: 0.5rem;
    padding-right: 0;
  }

  .product-title-flex-container {
    gap: 0.5rem;
  }

  .product-title {
    @include f.f28px();
    @include f.karla-bold();
    text-transform: uppercase;
    align-self: center;
    letter-spacing: -0.1rem;
  }

  .product-subtitle {
    @include f.f20px();
    @include f.karla-regular();
  }

  .product-label {
    @include f.f18px();
    @include f.karla-regular();
    margin-left: 0;
    padding: 0.25rem 0.5rem;
  }

  .product-badge {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0.5rem;
    order: 3;
  }

  .product-brand {
    @supports (-webkit-line-clamp: 2) {
      white-space: unset;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-height: 1;
    }
  }

  .badge {
    height: 1.625rem;
  }

  // Footer

  .classification-footer-container {
    color: black;

    &.no-classifications {
      height: 1rem;
    }

    .footer-classification-board {
      @include f.f28px;
    }

    .classification-mapping {
      @include f.karla-regular();
      align-items: center;

      @mixin classification-symbol {
        height: 1rem;
        width: 1rem;
        font-size: 0;
        border-radius: 50%;
      }

      @mixin color-classification-symbol($color) {
        background-color: $color;
        color: $color;
      }

      .sativa {
        @include classification-symbol();
        @include color-classification-symbol($lux-leaf-sativa);
      }

      .indica {
        @include classification-symbol();
        @include color-classification-symbol($lux-leaf-indica);
      }

      .hybrid {
        @include classification-symbol();
        @include color-classification-symbol($lux-leaf-hybrid);
      }

      .cbd {
        @include classification-symbol();
        @include color-classification-symbol($lux-leaf-cbd);
      }

      .classification-name {
        text-transform: capitalize;
      }
    }
  }

  // Font Styling

  .force-bold-style * {
    @include f.karla-semibold();
  }
}
