@use 'src/scss/font' as f;

.ingram-and-sons-theme {
  .company-logo {
    max-height: 8rem;
  }

  // Header

  .header-container-portrait {
    padding-top: 7.5rem;
  }

  .header-container-landscape {
    padding-top: 3.125rem;
  }

  .main-header {
    @include f.spectral-extra-bold;
    height: auto;
    line-height: 1;
    font-size: 7rem;
    letter-spacing: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Section container

  .sections-container {
    margin-left: 2.125rem;
    margin-right: 2.125rem;
  }

  // Sections

  .title-section-container {
    color: black;
    border-radius: 0.625rem;
    word-wrap: break-word;
    word-break: break-word;
  }

  .title-section {
    @include f.spectral-bold;
    @include f.f64px;
    padding: 0.1rem 1rem;
  }

  .title-section-content-wrapper {
    border-radius: 0.625rem;
  }

  .section-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-header-container {
    color: white;
    background-color: black;
    border-radius: 0.625rem;
    height: 5.125rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
  }

  .section-column-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section-title {
    @include f.anton-regular;
    font-size: 3rem;
    letter-spacing: 0.01625rem;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section-header-pre-roll {
    @include f.anton-regular;
    vertical-align: middle;
    font-size: 1.25rem;
    line-height: 1.325;
    letter-spacing: 0.0125rem;
  }

  .section-header-thc,
  .section-header-cbd,
  .section-header-quantity,
  .section-header-size,
  .section-header-variant-price,
  .section-header-badge {
    @include f.anton-regular;
    font-size: 2rem;
    line-height: 1.625;
    letter-spacing: 0.0125rem;
  }

  // Product

  .product-container {
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5.5rem;
  }

  .product-title {
    @include f.anton-regular;
    font-size: 2rem;
    line-height: 1.25;
    letter-spacing: 0.01375rem;
  }

  .product-label-container {
    vertical-align: middle;
  }

  .badge {
    height: 2.125rem;
  }

  .product-label {
    @include f.montserrat-medium;
    max-height: 2rem;
    font-size: 1rem;
    letter-spacing: 0.0625rem;
    vertical-align: middle;
  }

  .product-subtitle {
    @include f.montserrat-medium;
    font-size: 1.5rem;
    letter-spacing: 0.009375rem;
  }

  .product-variant-price {
    @include f.montserrat-medium;
    font-size: 1.75rem;
    line-height: 1.75;
    letter-spacing: 0.0125rem;
  }

  .product-variant-price-decoration {
    font-size: 1.1rem;
    line-height: 1.2;
    letter-spacing: 0.0125rem;
    vertical-align: text-top;
  }

  .product-cannabinoid,
  .product-terpene {
    @include f.montserrat-medium;
    font-size: 1.5rem;
    line-height: 1.625;
    letter-spacing: 0.01rem;
  }

  .product-low-amount {
    @include f.montserrat-medium;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .product-column {
    @include f.montserrat-medium;
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .product-size {
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .footer-container {
    padding: 2rem 3.125rem;
  }

  // Font Styling

  .force-bold-style * {
    @include f.montserrat-bold;
  }
}
