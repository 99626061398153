@use 'src/scss/font' as f;

.main-subtitle {
  font-size: 2.5rem;
  letter-spacing: 0;
  flex: 1 1 auto;
}

.product-variant-price-secondary,
.product-quantity-secondary,
.product-strain-type,
.product-cannabinoid-secondary,
.product-terpene-secondary,
.product-producer-secondary,
.product-brand-secondary {
  @include f.f24px;
}
