/**
  Notes:

  :not(ng-deep-bold) {
    This means only add my font-weight if the class ng-deep-bold isn't one of my classes.
    Need this because of styling that can be set at a parent level.
    ie - bold is set on product-container level, make all child divs be bold.
  }
 */

// sizes - all sizes are calculated using the base font size of 16px

@mixin f1px {
  font-size: 0.0625rem;
}
@mixin f2px {
  font-size: 0.125rem;
}
@mixin f3px {
  font-size: 0.1875rem;
}
@mixin f4px {
  font-size: 0.25rem;
}
@mixin f5px {
  font-size: 0.3125rem;
}
@mixin f6px {
  font-size: 0.375rem;
}
@mixin f7px {
  font-size: 0.4375rem;
}
@mixin f8px {
  font-size: 0.5rem;
}
@mixin f9px {
  font-size: 0.5625rem;
}
@mixin f10px {
  font-size: 0.625rem;
}
@mixin f11px {
  font-size: 0.6875rem;
}
@mixin f12px {
  font-size: 0.75rem;
}
@mixin f13px {
  font-size: 0.8125rem;
}
@mixin f14px {
  font-size: 0.875rem;
}
@mixin f15px {
  font-size: 0.9375rem;
}
@mixin f16px {
  font-size: 1rem;
}
@mixin f17px {
  font-size: 1.0625rem;
}
@mixin f18px {
  font-size: 1.125rem;
}
@mixin f19px {
  font-size: 1.1875rem;
}
@mixin f20px {
  font-size: 1.25rem;
}
@mixin f21px {
  font-size: 1.3125rem;
}
@mixin f22px {
  font-size: 1.375rem;
}
@mixin f23px {
  font-size: 1.4375rem;
}
@mixin f24px {
  font-size: 1.5rem;
}
@mixin f25px {
  font-size: 1.5625rem;
}
@mixin f26px {
  font-size: 1.625rem;
}
@mixin f27px {
  font-size: 1.6875rem;
}
@mixin f28px {
  font-size: 1.75rem;
}
@mixin f29px {
  font-size: 1.8125rem;
}
@mixin f30px {
  font-size: 1.875rem;
}
@mixin f31px {
  font-size: 1.9375rem;
}
@mixin f32px {
  font-size: 2rem;
}
@mixin f33px {
  font-size: 2.0625rem;
}
@mixin f34px {
  font-size: 2.125rem;
}
@mixin f35px {
  font-size: 2.1875rem;
}
@mixin f36px {
  font-size: 2.25rem;
}
@mixin f37px {
  font-size: 2.3125rem;
}
@mixin f38px {
  font-size: 2.375rem;
}
@mixin f39px {
  font-size: 2.4375rem;
}
@mixin f40px {
  font-size: 2.5rem;
}
@mixin f41px {
  font-size: 2.5625rem;
}
@mixin f42px {
  font-size: 2.625rem;
}
@mixin f43px {
  font-size: 2.6875rem;
}
@mixin f44px {
  font-size: 2.75rem;
}
@mixin f45px {
  font-size: 2.8125rem;
}
@mixin f46px {
  font-size: 2.875rem;
}
@mixin f47px {
  font-size: 2.9375rem;
}
@mixin f48px {
  font-size: 3rem;
}
@mixin f49px {
  font-size: 3.0625rem;
}
@mixin f50px {
  font-size: 3.125rem;
}
@mixin f51px {
  font-size: 3.1875rem;
}
@mixin f52px {
  font-size: 3.25rem;
}
@mixin f53px {
  font-size: 3.3125rem;
}
@mixin f54px {
  font-size: 3.375rem;
}
@mixin f55px {
  font-size: 3.4375rem;
}
@mixin f56px {
  font-size: 3.5rem;
}
@mixin f57px {
  font-size: 3.5625rem;
}
@mixin f58px {
  font-size: 3.625rem;
}
@mixin f59px {
  font-size: 3.6875rem;
}
@mixin f60px {
  font-size: 3.75rem;
}
@mixin f61px {
  font-size: 3.8125rem;
}
@mixin f62px {
  font-size: 3.875rem;
}
@mixin f63px {
  font-size: 3.9375rem;
}
@mixin f64px {
  font-size: 4rem;
}
@mixin f65px {
  font-size: 4.0625rem;
}
@mixin f66px {
  font-size: 4.125rem;
}
@mixin f67px {
  font-size: 4.1875rem;
}
@mixin f68px {
  font-size: 4.25rem;
}
@mixin f69px {
  font-size: 4.3125rem;
}
@mixin f70px {
  font-size: 4.375rem;
}
@mixin f71px {
  font-size: 4.4375rem;
}
@mixin f72px {
  font-size: 4.5rem;
}
@mixin f73px {
  font-size: 4.5625rem;
}
@mixin f74px {
  font-size: 4.625rem;
}
@mixin f75px {
  font-size: 4.6875rem;
}
@mixin f76px {
  font-size: 4.75rem;
}
@mixin f77px {
  font-size: 4.8125rem;
}
@mixin f78px {
  font-size: 4.875rem;
}
@mixin f79px {
  font-size: 4.9375rem;
}
@mixin f80px {
  font-size: 5rem;
}
@mixin f81px {
  font-size: 5.0625rem;
}
@mixin f82px {
  font-size: 5.125rem;
}
@mixin f83px {
  font-size: 5.1875rem;
}
@mixin f84px {
  font-size: 5.25rem;
}
@mixin f85px {
  font-size: 5.3125rem;
}
@mixin f86px {
  font-size: 5.375rem;
}
@mixin f87px {
  font-size: 5.4375rem;
}
@mixin f88px {
  font-size: 5.5rem;
}
@mixin f89px {
  font-size: 5.5625rem;
}
@mixin f90px {
  font-size: 5.625rem;
}
@mixin f91px {
  font-size: 5.6875rem;
}
@mixin f92px {
  font-size: 5.75rem;
}
@mixin f93px {
  font-size: 5.8125rem;
}
@mixin f94px {
  font-size: 5.875rem;
}
@mixin f95px {
  font-size: 5.9375rem;
}
@mixin f96px {
  font-size: 6rem;
}
@mixin f97px {
  font-size: 6.0625rem;
}
@mixin f98px {
  font-size: 6.125rem;
}
@mixin f99px {
  font-size: 6.1875rem;
}
@mixin f100px {
  font-size: 6.25rem;
}

.f12px {
  @include f12px;
}

.f14px {
  @include f14px;
}

.f16px {
  @include f16px;
}

.f18px {
  @include f18px;
}

.f20px {
  @include f20px;
}

.f24px {
  @include f24px;
}

.f32px {
  @include f32px;
}

.f48px {
  @include f48px;
}

.f64px {
  @include f64px;
}

// Archivo

@mixin archivo-thin {
  font-family: Archivo, sans-serif;
  font-weight: 100;
}

@mixin archivo-thin-italic {
  font-family: Archivo, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin archivo-extra-light {
  font-family: Archivo, sans-serif;
  font-weight: 200;
}

@mixin archivo-extra-light-italic {
  font-family: Archivo, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin archivo-light {
  font-family: Archivo, sans-serif;
  font-weight: 300;
}

@mixin archivo-light-italic {
  font-family: Archivo, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin archivo-regular {
  font-family: Archivo, sans-serif;
  font-weight: 400;
}

@mixin archivo-italic {
  font-family: Archivo, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin archivo-medium {
  font-family: Archivo, sans-serif;
  font-weight: 500;
}

@mixin archivo-medium-italic {
  font-family: Archivo, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin archivo-semi-bold {
  font-family: Archivo, sans-serif;
  font-weight: 600;
}

@mixin archivo-semi-bold-italic {
  font-family: Archivo, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin archivo-bold {
  font-family: Archivo, sans-serif;
  font-weight: 700;
}

@mixin archivo-bold-italic {
  font-family: Archivo, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin archivo-extra-bold {
  font-family: Archivo, sans-serif;
  font-weight: 800;
}

@mixin archivo-extra-bold-italic {
  font-family: Archivo, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin archivo-black {
  font-family: Archivo, sans-serif;
  font-weight: 900;
}

@mixin archivo-black-italic {
  font-family: Archivo, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Archivo Black

@mixin archivo-black-regular {
  font-family: archivo-black, sans-serif;
  font-weight: 400;
  font-style: normal;
}

// Anton

@mixin anton-regular {
  font-family: anton, sans-serif;
  font-style: normal;
  font-weight: 400;
}

// Big Noodle

@mixin big-noodle {
  font-family: 'Big-Noodle-Titling', sans-serif;
}

// Dongle

@mixin dongle-bold {
  font-family: 'Dongle', sans-serif;
  font-weight: 700;
}

@mixin dongle-regular {
  font-family: 'Dongle', sans-serif;
  font-weight: 400;
}

@mixin dongle-light {
  font-family: 'Dongle', sans-serif;
  font-weight: 300;
}

// Ubuntu Titled

@mixin ubuntu-bold {
  font-family: 'Ubuntu-Titled', sans-serif;
}

// Mont Font

@mixin mont-heavy {
  font-family: 'Mont-Heavy', sans-serif;
}

@mixin mont-extra-light {
  font-family: 'Mont-ExtraLight', sans-serif;
  font-weight: 200;
}

// SuisseIntl Font

@mixin suisse-bold {
  font-family: 'SuisseIntl-Bold', sans-serif;
  font-weight: bold;
}

@mixin suisse-semi-bold {
  font-family: 'SuisseIntl-SemiBold', sans-serif;
  font-weight: 600;
}

@mixin suisse-medium {
  font-family: 'SuisseIntl-Medium', sans-serif;
  font-weight: 500;
}

@mixin suisse-regular {
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-weight: normal;
}

@mixin suisse-bold-italic {
  font-family: 'SuisseIntl-BoldItalic', sans-serif;
  font-weight: bold;
  font-style: italic;
}

@mixin suisse-semi-bold-italic {
  font-family: 'SuisseIntl-SemiBoldItalic', sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin suisse-medium-italic {
  font-family: 'SuisseIntl-MediumItalic', sans-serif;
  font-style: italic;
  font-weight: 500;
}

@mixin suisse-regular-italic {
  font-family: 'SuisseIntl-RegularItalic', sans-serif;
  font-weight: normal;
  font-style: italic;
}

// Montserrat Font

@mixin montserrat-thin {
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}

@mixin montserrat-thin-italic {
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin montserrat-extra-light {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
}

@mixin montserrat-extra-light-italic {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin montserrat-light {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

@mixin montserrat-light-italic {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin montserrat-regular {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

@mixin montserrat-italic {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin montserrat-medium {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

@mixin montserrat-medium-italic {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin montserrat-semi-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

@mixin montserrat-semi-bold-italic {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin montserrat-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

@mixin montserrat-bold-italic {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin montserrat-extra-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

@mixin montserrat-extra-bold-italic {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin montserrat-black {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

@mixin montserrat-black-italic {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Realist Font

@mixin realist-thin {
  font-family: realistnarrow, sans-serif;
  font-weight: 100;
}

@mixin realist-thin-italic {
  font-family: realistnarrow, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin realist-light {
  font-family: realistnarrow, sans-serif;
  font-weight: 200;
}

@mixin realist-light-italic {
  font-family: realistnarrow, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin realist-semi-light {
  font-family: realistnarrow, sans-serif;
  font-weight: 300;
}

@mixin realist-semi-light-italic {
  font-family: realistnarrow, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin realist-regular {
  font-family: realistnarrow, sans-serif;
  font-weight: 400;
}

@mixin realist-regular-italic {
  font-family: realistnarrow, sans-serif;
  font-style: italic;
  font-weight: 400;
}

@mixin realist-medium {
  font-family: realistnarrow, sans-serif;
  font-weight: 600;
}

@mixin realist-medium-italic {
  font-family: realistnarrow, sans-serif;
  font-style: italic;
  font-weight: 600;
}

@mixin realist-bold {
  font-family: realistnarrow, sans-serif;
  font-weight: 700;
}

@mixin realist-bold-italic {
  font-family: realistnarrow, sans-serif;
  font-style: italic;
  font-weight: 700;
}

@mixin realist-extra-bold {
  font-family: realistnarrow, sans-serif;
  font-weight: 800;
}

@mixin realist-extra-bold-italic {
  font-family: realistnarrow, sans-serif;
  font-style: italic;
  font-weight: 800;
}

@mixin realist-black {
  font-family: realistnarrow, sans-serif;
  font-weight: 900;
}

@mixin realist-black-italic {
  font-family: realistnarrow, sans-serif;
  font-style: italic;
  font-weight: 900;
}

// Realist Wide Font

@mixin realist-wide-thin {
  font-family: realistwide, sans-serif;
  font-weight: 100;
}

@mixin realist-wide-thin-italic {
  font-family: realistwide, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin realist-wide-light {
  font-family: realistwide, sans-serif;
  font-weight: 200;
}

@mixin realist-wide-light-italic {
  font-family: realistwide, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin realist-wide-semi-light {
  font-family: realistwide, sans-serif;
  font-weight: 300;
}

@mixin realist-wide-semi-light-italic {
  font-family: realistwide, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin realist-wide-regular {
  font-family: realistwide, sans-serif;
  font-weight: 400;
}

@mixin realist-wide-regular-italic {
  font-family: realistwide, sans-serif;
  font-style: italic;
  font-weight: 400;
}

@mixin realist-wide-medium {
  font-family: realistwide, sans-serif;
  font-weight: 600;
}

@mixin realist-wide-medium-italic {
  font-family: realistwide, sans-serif;
  font-style: italic;
  font-weight: 600;
}

@mixin realist-wide-bold {
  font-family: realistwide, sans-serif;
  font-weight: 700;
}

@mixin realist-wide-bold-italic {
  font-family: realistwide, sans-serif;
  font-style: italic;
  font-weight: 700;
}

@mixin realist-wide-extra-bold {
  font-family: realistwide, sans-serif;
  font-weight: 800;
}

@mixin realist-wide-extra-bold-italic {
  font-family: realistwide, sans-serif;
  font-style: italic;
  font-weight: 800;
}

@mixin realist-wide-black {
  font-family: realistwide, sans-serif;
  font-weight: 900;
}

@mixin realist-wide-black-italic {
  font-family: realistwide, sans-serif;
  font-style: italic;
  font-weight: 900;
}

// Rubik Font

@mixin rubik-light {
  font-family: rubik, sans-serif;
  font-weight: 300;
}

@mixin rubik-light-italic {
  font-family: rubik, sans-serif;
  font-style: italic;
  font-weight: 300;
}

@mixin rubik-regular {
  font-family: rubik, sans-serif;
  font-weight: 400;
}

@mixin rubik-italic {
  font-family: rubik, sans-serif;
  font-style: italic;
  font-weight: 400;
}

@mixin rubik-medium {
  font-family: rubik, sans-serif;
  font-weight: 500;
}

@mixin rubik-medium-italic {
  font-family: rubik, sans-serif;
  font-style: italic;
  font-weight: 500;
}

@mixin rubik-medium-bold {
  font-family: rubik, sans-serif;
  font-weight: 600;
}

@mixin rubik-bold {
  font-family: rubik, sans-serif;
  font-weight: 700;
}

@mixin rubik-bold-italic {
  font-family: rubik, sans-serif;
  font-style: italic;
  font-weight: 700;
}

@mixin rubik-black {
  font-family: rubik, sans-serif;
  font-weight: 900;
}

@mixin rubik-black-italic {
  font-family: rubik, sans-serif;
  font-style: italic;
  font-weight: 900;
}

// Poppins Font

@mixin poppins-thin {
  font-family: poppins, sans-serif;
  font-weight: 100;
}

@mixin poppins-thin-italic {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 100;
}

@mixin poppins-extra-light {
  font-family: poppins, sans-serif;
  font-weight: 200;
}

@mixin poppins-extra-light-italic {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 200;
}

@mixin poppins-light {
  font-family: poppins, sans-serif;
  font-weight: 300;
}

@mixin poppins-light-italic {
  font-family: poppins, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin poppins-regular {
  font-family: poppins, sans-serif;
  font-weight: 400;
}

@mixin poppins-italic {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 400;
}

@mixin poppins-medium {
  font-family: poppins, sans-serif;
  font-weight: 500;
}

@mixin poppins-medium-italic {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin poppins-semi-bold {
  font-family: poppins, sans-serif;
  font-weight: 600;
}

@mixin poppins-semi-bold-italic {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 600;
}

@mixin poppins-bold-italic {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 700;
}

@mixin poppins-bold {
  font-family: poppins, sans-serif;
  font-weight: 700;
}

@mixin poppins-extra-bold {
  font-family: poppins, sans-serif;
  font-weight: 800;
}

@mixin poppins-extra-bold-italic {
  font-family: poppins, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin poppins-black {
  font-family: poppins, sans-serif;
  font-weight: 900;
}

@mixin poppins-black-italic {
  font-family: poppins, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// source-sans-pro

@mixin source-sans-pro-regular {
  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin source-sans-pro-italic {
  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin source-sans-pro-semi-bold {
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin source-sans-pro-semi-bold-italic {
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin source-sans-pro-bold {
  font-family: source-sans-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin source-sans-pro-bold-italic {
  font-family: source-sans-pro, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin source-sans-pro-black {
  font-family: source-sans-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin source-sans-pro-black-italic {
  font-family: source-sans-pro, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Rift Soft

@mixin rift-soft-bold {
  font-family: rift-soft, sans-serif;
  font-weight: 700;
}

@mixin rift-soft-bold-italic {
  font-family: rift-soft, sans-serif;
  font-weight: 700;
  font-style: italic;
}

// Arial

@mixin arial-regular {
  font-family: Arial, sans-serif;
  font-weight: 400;
}

@mixin arial-semi-bold {
  font-family: Arial, sans-serif;
  font-weight: 600;
}

@mixin arial-bold {
  font-family: Arial, sans-serif;
  font-weight: 800;
}

// Heebo Font

@mixin heebo-thin {
  font-family: heebo, sans-serif;
  font-weight: 200;
}

@mixin heebo-light {
  font-family: heebo, sans-serif;
  font-weight: 300;
}

@mixin heebo-regular {
  font-family: heebo, sans-serif;
  font-weight: 400;
}

@mixin heebo-medium {
  font-family: heebo, sans-serif;
  font-weight: 500;
}

@mixin heebo-bold {
  font-family: heebo, sans-serif;
  font-weight: 700;
}

@mixin heebo-extra-bold {
  font-family: heebo, sans-serif;
  font-weight: 800;
}

@mixin heebo-black {
  font-family: heebo, sans-serif;
  font-weight: 900;
}

// Lato Font

@mixin lato-hairline {
  font-family: lato, sans-serif;
  font-weight: 100;
}

@mixin lato-hairline-italic {
  font-family: lato, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin lato-thin {
  font-family: lato, sans-serif;
  font-weight: 200;
}

@mixin lato-thin-italic {
  font-family: lato, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin lato-light {
  font-family: lato, sans-serif;
  font-weight: 300;
}

@mixin lato-light-italic {
  font-family: lato, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin lato-regular {
  font-family: lato, sans-serif;
  font-weight: 400;
}

@mixin lato-italic {
  font-family: lato, sans-serif;
  font-style: italic;
  font-weight: 400;
}

@mixin lato-medium {
  font-family: lato, sans-serif;
  font-weight: 500;
}

@mixin lato-medium {
  font-family: lato, sans-serif;
  font-style: italic;
  font-weight: 500;
}

@mixin lato-semi-bold {
  font-family: lato, sans-serif;
  font-weight: 600;
}

@mixin lato-semi-bold {
  font-family: lato, sans-serif;
  font-style: italic;
  font-weight: 600;
}

@mixin lato-bold {
  font-family: lato, sans-serif;
  font-weight: 700;
}

@mixin lato-bold-italic {
  font-family: lato, sans-serif;
  font-style: italic;
  font-weight: 700;
}

@mixin lato-heavy {
  font-family: lato, sans-serif;
  font-weight: 800;
}

@mixin lato-heavy-italic {
  font-family: lato, sans-serif;
  font-style: italic;
  font-weight: 800;
}

@mixin lato-black {
  font-family: lato, sans-serif;
  font-weight: 900;
}

@mixin lato-black-italic {
  font-family: lato, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Futura PT

@mixin futura-pt-light {
  font-family: futura-pt, sans-serif;
  font-weight: 300;
}

@mixin futura-pt-light-italic {
  font-family: futura-pt, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin futura-pt-book {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
}

@mixin futura-pt-book-italic {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin futura-pt-medium {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
}

@mixin futura-pt-medium-italic {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin futura-pt-demi {
  font-family: futura-pt, sans-serif;
  font-weight: 600;
}

@mixin futura-pt-demi-italic {
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin futura-pt-heavy {
  font-family: futura-pt, sans-serif;
  font-weight: 700;
}

@mixin futura-pt-heavy-italic {
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin futura-pt-extra-bold {
  font-family: futura-pt, sans-serif;
  font-weight: 800;
}

@mixin futura-pt-extra-bold-italic {
  font-family: futura-pt, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin futura-pt-bold {
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
}

@mixin futura-pt-bold-italic {
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  font-style: italic;
}

// Eds Market Narrow Slant

@mixin eds-market-narrow-slant {
  font-family: eds-market-narrow-slant, sans-serif;
  font-weight: 400;
  font-style: normal;
}

// Shelby

@mixin shelby-regular {
  font-family: shelby, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin shelby-bold {
  font-family: shelby, sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Acumin Pro Wide (Sprout Theme)

@mixin acumin-pro-wide-bold {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin acumin-pro-wide-bold-italic {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin acumin-pro-wide-light {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin acumin-pro-wide-light-italic {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin acumin-pro-wide-medium {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin acumin-pro-wide-medium-italic {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin acumin-pro-wide-semibold {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin acumin-pro-wide-semibold-italic {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin acumin-pro-wide-regular {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin acumin-pro-wide-italic {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 400;
  font-style: italic;
}

// Acumin Pro Extra Condensed

@mixin acumin-pro-extra-condensed-thin {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin acumin-pro-extra-condensed-thin-italic {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin acumin-pro-extra-condensed-extra-light {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin acumin-pro-extra-condensed-extra-light-italic {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin acumin-pro-extra-condensed-light {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin acumin-pro-extra-condensed-light-italic {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin acumin-pro-extra-condensed-regular {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin acumin-pro-extra-condensed-regular-italic {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin acumin-pro-extra-condensed-medium {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin acumin-pro-extra-condensed-medium-italic {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin acumin-pro-extra-condensed-semi-bold {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin acumin-pro-extra-condensed-semi-bold-italic {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin acumin-pro-extra-condensed-bold {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin acumin-pro-extra-condensed-bold-italic {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin acumin-pro-extra-condensed-black {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin acumin-pro-extra-condensed-black-italic {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin acumin-pro-extra-condensed-ultra-black {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin acumin-pro-extra-condensed-ultra-black-italic {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Banks & Miles

@mixin banks-miles {
  font-family: Banks-Miles, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin banks-miles-italic {
  font-family: Banks-Miles, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin banks-miles-medium {
  font-family: Banks-Miles, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin banks-miles-medium-italic {
  font-family: Banks-Miles, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin banks-miles-bold {
  font-family: Banks-Miles, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin banks-miles-bold-italic {
  font-family: Banks-Miles, sans-serif;
  font-weight: 700;
  font-style: italic;
}

// Facile

@mixin facile-sans {
  font-family: Facile-Sans, sans-serif;
  font-style: normal;
}

// Proxima Nova

@mixin proxima-nova-black {
  font-family: proxima-nova, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin proxima-nova-black-italic {
  font-family: proxima-nova, sans-serif;
  font-weight: 900;
  font-style: italic;
}

@mixin proxima-nova-bold {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin proxima-nova-bold-italic {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin proxima-nova-extrabold {
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin proxima-nova-extrabold-italic {
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin proxima-nova-thin {
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin proxima-nova-thin-italic {
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin proxima-nova-semibold {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin proxima-nova-semibold-italic {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin proxima-nova-regular {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin proxima-nova-italic {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin proxima-nova-light {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin proxima-nova-light-italic {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin proxima-nova-medium {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin proxima-nova-medium-italic {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-style: italic;
}

// Windsor

@mixin windsor-bold {
  font-family: 'Windsor-Bold', sans-serif;
  font-weight: 600;
}

// Gilroy

@mixin gilroy-thin {
  font-family: Gilroy, sans-serif;
  font-weight: 100;
}

@mixin gilroy-thin-italic {
  font-family: Gilroy, sans-serif;
  font-style: italic;
  font-weight: 100;
}

@mixin gilroy-extra-light {
  font-family: Gilroy, sans-serif;
  font-weight: 200;
}

@mixin gilroy-extra-light-italic {
  font-family: Gilroy, sans-serif;
  font-style: italic;
  font-weight: 200;
}

@mixin gilroy-light {
  font-family: Gilroy, sans-serif;
  font-weight: 300;
}

@mixin gilroy-light-italic {
  font-family: Gilroy, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin gilroy-regular {
  font-family: Gilroy, sans-serif;
  font-weight: 400;
}

@mixin gilroy-italic {
  font-family: Gilroy, sans-serif;
  font-style: italic;
  font-weight: 400;
}

@mixin gilroy-medium {
  font-family: Gilroy, sans-serif;
  font-weight: 500;
}

@mixin gilroy-medium-italic {
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin gilroy-semi-bold {
  font-family: Gilroy, sans-serif;
  font-weight: 600;
}

@mixin gilroy-semi-bold-italic {
  font-family: Gilroy, sans-serif;
  font-style: italic;
  font-weight: 600;
}

@mixin gilroy-bold-italic {
  font-family: Gilroy, sans-serif;
  font-style: italic;
  font-weight: 700;
}

@mixin gilroy-bold {
  font-family: Gilroy, sans-serif;
  font-weight: 700;
}

@mixin gilroy-extra-bold {
  font-family: Gilroy, sans-serif;
  font-weight: 800;
}

@mixin gilroy-extra-bold-italic {
  font-family: Gilroy, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin gilroy-heavy {
  font-family: Gilroy, sans-serif;
  font-weight: 900;
}

@mixin gilroy-heavy-italic {
  font-family: Gilroy, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Bebas Neue

@mixin bebas-neue-pro-thin {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin bebas-neue-pro-thin-italic {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin bebas-neue-pro-italics {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin bebas-neue-pro-regular {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin bebas-neue-pro-middle {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin bebas-neue-pro-middle-italic {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin bebas-neue-pro-bold {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin bebas-neue-pro-bold-italic {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin bebas-neue-pro-expanded-bold {
  // not a bug, expanded is a different font family
  font-family: bebas-neue-pro-expanded, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin bebas-neue-pro-expanded-bold-italic {
  // not a bug, expanded is a different font family
  font-family: bebas-neue-pro-expanded, sans-serif;
  font-weight: 700;
  font-style: italic;
}

// Myriad-Pro

@mixin myriad-pro-bold {
  font-family: myriad-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin myriad-pro-bold-italic {
  font-family: myriad-pro, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin myriad-pro-italic {
  font-family: myriad-pro, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin myriad-pro-regular {
  font-family: myriad-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin myriad-pro-semi-bold {
  font-family: myriad-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin myriad-pro-semi-bold-italic {
  font-family: myriad-pro, sans-serif;
  font-weight: 600;
  font-style: italic;
}

// Open Sans

@mixin open-sans-italic {
  font-family: open-sans, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin open-sans-regular {
  font-family: open-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin open-sans-semi-bold {
  font-family: open-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin open-sans-semi-bold-italic {
  font-family: open-sans, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin open-sans-bold {
  font-family: open-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin open-sans-bold-italic {
  font-family: open-sans, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin open-sans-bold-extra {
  font-family: open-sans, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin open-sans-bold-extra-italic {
  font-family: open-sans, sans-serif;
  font-weight: 800;
  font-style: italic;
}

// soleil

@mixin soleil-italic {
  font-family: Soleil, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin soleil-normal {
  font-family: Soleil, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin soleil-semi-bold-italic {
  font-family: Soleil, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin soleil-semi-bold-normal {
  font-family: Soleil, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin soleil-bold-italic {
  font-family: Soleil, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin soleil-bold-normal {
  font-family: Soleil, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin soleil-extra-bold-italic {
  font-family: Soleil, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin soleil-extra-bold-normal {
  font-family: Soleil, sans-serif;
  font-weight: 700;
  font-style: normal;
}

// P22 Mackinac Pro

@mixin P22-Mackinac-Pro-bold {
  font-family: P22-Mackinac-Pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Myriad Pro

@mixin myriad-pro-bold {
  font-family: myriad-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin myriad-pro-bold-italic {
  font-family: myriad-pro, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin myriad-pro-italic {
  font-family: myriad-pro, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin myriad-pro-regular {
  font-family: myriad-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin myriad-pro-semi-bold {
  font-family: myriad-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin myriad-pro-semi-bold-italic {
  font-family: myriad-pro, sans-serif;
  font-weight: 600;
  font-style: italic;
}

// Raleway

@mixin raleway-thin {
  font-family: raleway, sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin raleway-thin-italic {
  font-family: raleway, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin raleway-extra-light {
  font-family: raleway, sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin raleway-extra-light-italic {
  font-family: raleway, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin raleway-light {
  font-family: raleway, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin raleway-light-italic {
  font-family: raleway, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin raleway-regular {
  font-family: raleway, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin raleway-italic {
  font-family: raleway, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin raleway-medium {
  font-family: raleway, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin raleway-medium-italic {
  font-family: raleway, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin raleway-semi-bold {
  font-family: raleway, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin raleway-semi-bold-italic {
  font-family: raleway, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin raleway-bold {
  font-family: raleway, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin raleway-bold-italic {
  font-family: raleway, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin raleway-extra-bold {
  font-family: raleway, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin raleway-extra-bold-italic {
  font-family: raleway, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin raleway-black {
  font-family: raleway, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin raleway-black-italic {
  font-family: raleway, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Playfair Display

@mixin playfair-display-regular {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: normal;
}

@mixin playfair-display-italic {
  font-family: playfair-display, serif;
  font-weight: 400;
  font-style: italic;
}

@mixin playfair-display-bold {
  font-family: playfair-display, serif;
  font-weight: 700;
  font-style: normal;
}

@mixin playfair-display-bold-italic {
  font-family: playfair-display, serif;
  font-weight: 700;
  font-style: italic;
}

@mixin playfair-display-black {
  font-family: playfair-display, serif;
  font-weight: 900;
  font-style: normal;
}

@mixin playfair-display-black-italic {
  font-family: playfair-display, serif;
  font-weight: 900;
  font-style: italic;
}

// Pragmatica

@mixin pragmatica-light {
  font-family: pragmatica, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin pragmatica-book {
  font-family: pragmatica, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin pragmatica-extended-medium {
  font-family: pragmatica-extended, sans-serif;
  font-weight: 500;
  font-style: normal;
}

//apparat

@mixin apparat-medium {
  font-family: apparat, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin apparat-medium-italic {
  font-family: apparat, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin apparat-semibold {
  font-family: apparat, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin apparat-semibold-italic {
  font-family: apparat, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin apparat-bold {
  font-family: apparat, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin apparat-bold-italic {
  font-family: apparat, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin apparat-extraBold {
  font-family: apparat, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin apparat-extraBold-italic {
  font-family: apparat, sans-serif;
  font-weight: 700;
  font-style: italic;
}

// Merriweather

@mixin merriweather-light {
  font-family: merriweather, serif;
  font-weight: 300;
  font-style: normal;
}

@mixin merriweather-light-italic {
  font-family: merriweather, serif;
  font-weight: 300;
  font-style: italic;
}

@mixin merriweather-regular {
  font-family: merriweather, serif;
  font-weight: 400;
  font-style: normal;
}

@mixin merriweather-italic {
  font-family: merriweather, serif;
  font-weight: 400;
  font-style: italic;
}

@mixin merriweather-bold {
  font-family: merriweather, serif;
  font-weight: 700;
  font-style: normal;
}

@mixin merriweather-bold-italic {
  font-family: merriweather, serif;
  font-weight: 700;
  font-style: italic;
}

@mixin merriweather-ultra-bold {
  font-family: merriweather, serif;
  font-weight: 900;
  font-style: normal;
}

@mixin merriweather-ultra-bold-italic {
  font-family: merriweather, serif;
  font-weight: 900;
  font-style: italic;
}

// Oswald

@mixin oswald-extra-light {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
}

@mixin oswald-light {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@mixin oswald-medium {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@mixin oswald-semi-bold {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@mixin oswald-bold {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@mixin oswald-extra-bold {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@mixin oswald-black {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

// Spectral

@mixin spectral-extra-light {
  font-family: Spectral, serif;
  font-weight: 200;
  font-style: normal;
}

@mixin spectral-extra-light-italic {
  font-family: Spectral, serif;
  font-weight: 200;
  font-style: italic;
}

@mixin spectral-light {
  font-family: Spectral, serif;
  font-weight: 300;
  font-style: normal;
}

@mixin spectral-light-italic {
  font-family: Spectral, serif;
  font-weight: 300;
  font-style: italic;
}

@mixin spectral-regular {
  font-family: Spectral, serif;
  font-weight: 400;
  font-style: normal;
}

@mixin spectral-regular-italic {
  font-family: Spectral, serif;
  font-weight: 400;
  font-style: italic;
}

@mixin spectral-medium {
  font-family: Spectral, serif;
  font-weight: 500;
  font-style: normal;
}

@mixin spectral-medium-italic {
  font-family: Spectral, serif;
  font-weight: 500;
  font-style: italic;
}

@mixin spectral-semi-bold {
  font-family: Spectral, serif;
  font-weight: 600;
  font-style: normal;
}

@mixin spectral-semi-bold-italic {
  font-family: Spectral, serif;
  font-weight: 600;
  font-style: italic;
}

@mixin spectral-bold {
  font-family: Spectral, serif;
  font-weight: 700;
  font-style: normal;
}

@mixin spectral-bold-italic {
  font-family: Spectral, serif;
  font-weight: 700;
  font-style: italic;
}

@mixin spectral-extra-bold {
  font-family: Spectral, serif;
  font-weight: 800;
  font-style: normal;
}

@mixin spectral-extra-bold-italic {
  font-family: Spectral, serif;
  font-weight: 800;
  font-style: italic;
}

// Tzimmies

@mixin tzimmies-thin {
  font-family: tzimmes, serif;
  font-weight: 100;
  font-style: normal;
}

@mixin tzimmies-thin-italic {
  font-family: tzimmes, serif;
  font-weight: 100;
  font-style: italic;
}

@mixin tzimmies-extra-light {
  font-family: tzimmes, serif;
  font-weight: 200;
  font-style: normal;
}

@mixin tzimmies-extra-light-italic {
  font-family: tzimmes, serif;
  font-weight: 200;
  font-style: italic;
}

@mixin tzimmies-light {
  font-family: tzimmes, serif;
  font-weight: 300;
  font-style: normal;
}

@mixin tzimmies-light-italic {
  font-family: tzimmes, serif;
  font-weight: 300;
  font-style: italic;
}

@mixin tzimmies-regular {
  font-family: tzimmes, serif;
  font-weight: 400;
  font-style: normal;
}

@mixin tzimmies-regular-italic {
  font-family: tzimmes, serif;
  font-weight: 400;
  font-style: italic;
}

@mixin tzimmies-medium {
  font-family: tzimmes, serif;
  font-weight: 500;
  font-style: normal;
}

@mixin tzimmies-medium-italic {
  font-family: tzimmes, serif;
  font-weight: 500;
  font-style: italic;
}

@mixin tzimmies-semibold {
  font-family: tzimmes, serif;
  font-weight: 600;
  font-style: normal;
}

@mixin tzimmies-semibold-italic {
  font-family: tzimmes, serif;
  font-weight: 600;
  font-style: italic;
}

@mixin tzimmies-bold {
  font-family: tzimmes, serif;
  font-weight: 700;
  font-style: normal;
}

@mixin tzimmies-bold-italic {
  font-family: tzimmes, serif;
  font-weight: 700;
  font-style: italic;
}

@mixin tzimmies-ultrabold {
  font-family: tzimmes, serif;
  font-weight: 800;
  font-style: normal;
}

@mixin tzimmies-ultrabold-italic {
  font-family: tzimmes, serif;
  font-weight: 800;
  font-style: italic;
}

@mixin tzimmies-extrabold {
  font-family: tzimmes, serif;
  font-weight: 900;
  font-style: normal;
}

@mixin tzimmies-extrabold-italic {
  font-family: tzimmes, serif;
  font-weight: 900;
  font-style: italic;
}

// Work Sans

@mixin work-sans-thin {
  font-family: Work-Sans, sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin work-sans-thin-italic {
  font-family: Work-Sans, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin work-sans-extra-light {
  font-family: Work-Sans, sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin work-sans-extra-light-italic {
  font-family: Work-Sans, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin work-sans-light {
  font-family: Work-Sans, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin work-sans-light-italic {
  font-family: Work-Sans, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin work-sans-regular {
  font-family: Work-Sans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin work-sans-regular-italic {
  font-family: Work-Sans, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin work-sans-medium {
  font-family: Work-Sans, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin work-sans-medium-italic {
  font-family: Work-Sans, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin work-sans-semibold {
  font-family: Work-Sans, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin work-sans-semibold-italic {
  font-family: Work-Sans, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin work-sans-bold {
  font-family: Work-Sans, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin work-sans-bold-italic {
  font-family: Work-Sans, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin work-sans-extra-bold {
  font-family: Work-Sans, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin work-sans-extra-bold-italic {
  font-family: Work-Sans, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin work-sans-black {
  font-family: Work-Sans, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin work-sans-black-italic {
  font-family: Work-Sans, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Roboto

@mixin roboto-thin {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin roboto-thin-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin roboto-light {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin roboto-light-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin roboto-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin roboto-regular-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin roboto-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin roboto-medium-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin roboto-medium-extra {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin roboto-medium-extra-talic {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin roboto-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin roboto-bold-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin roboto-black {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin roboto-black-extra {
  font-family: 'Roboto', sans-serif;
  font-weight: 1000;
  font-style: normal;
}

@mixin roboto-black-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: italic;
}

// PT Mono
@mixin PT-mono-regular {
  font-family: pt-mono, monospace, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin PT-mono-bold {
  font-family: pt-mono, monospace, sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Karla

@mixin karla-extra-light {
  font-family: 'Karla', sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin karla-extra-light-italic {
  font-family: 'Karla', sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin karla-light {
  font-family: 'Karla', sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin karla-light-italic {
  font-family: 'Karla', sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin karla-regular {
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin karla-regular-italic {
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin karla-medium {
  font-family: 'Karla', sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin karla-medium-italic {
  font-family: 'Karla', sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin karla-semibold {
  font-family: 'Karla', sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin karla-semibold-italic {
  font-family: 'Karla', sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin karla-bold {
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin karla-bold-italic {
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin karla-extra-bold {
  font-family: 'Karla', sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin karla-extra-bold-italic {
  font-family: 'Karla', sans-serif;
  font-weight: 800;
  font-style: italic;
}

// Rama Gothic M

@mixin rama-gothic-m-regular {
  font-family: 'RamaGothicM', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin rama-gothic-m-semibold {
  font-family: 'RamaGothicM', sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin rama-gothic-m-bold {
  font-family: 'RamaGothicM', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin rama-gothic-m-black {
  font-family: 'RamaGothicM', sans-serif;
  font-weight: 900;
  font-style: normal;
}

// Metropolis

@mixin metropolis-thin {
  font-family: 'Metropolis', sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin metropolis-thin-italic {
  font-family: 'Metropolis', sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin metropolis-extra-light {
  font-family: 'Metropolis', sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin metropolis-extra-light-italic {
  font-family: 'Metropolis', sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin metropolis-light {
  font-family: 'Metropolis', sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin metropolis-light-italic {
  font-family: 'Metropolis', sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin metropolis-regular {
  font-family: 'Metropolis', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin metropolis-regular-italic {
  font-family: 'Metropolis', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin metropolis-medium {
  font-family: 'Metropolis', sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin metropolis-medium-italic {
  font-family: 'Metropolis', sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin metropolis-semibold {
  font-family: 'Metropolis', sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin metropolis-semibold-italic {
  font-family: 'Metropolis', sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin metropolis-bold {
  font-family: 'Metropolis', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin metropolis-bold-italic {
  font-family: 'Metropolis', sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin metropolis-extra-bold {
  font-family: 'Metropolis', sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin metropolis-extra-bold-italic {
  font-family: 'Metropolis', sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin metropolis-black {
  font-family: 'Metropolis', sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin metropolis-black-italic {
  font-family: 'Metropolis', sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Neue Haas Grotesk Display

@mixin neue-haas-grotesk-display-light {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin neue-haas-grotesk-display-light-italic {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin neue-haas-grotesk-display-regular {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin neue-haas-grotesk-display-regular-italic {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin neue-haas-grotesk-display-medium {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin neue-haas-grotesk-display-medium-italic {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin neue-haas-grotesk-display-semi-bold {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin neue-haas-grotesk-display-semi-bold-italic {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin neue-haas-grotesk-display-bold {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin neue-haas-grotesk-display-bold-italic {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin neue-haas-grotesk-display-black {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin neue-haas-grotesk-display-black-italic {
  font-family: 'NeueHaasGroteskDisplay', sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Indivisible

@mixin indivisible-regular {
  font-family: indivisible, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin indivisible-regular-italic {
  font-family: indivisible, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin indivisible-medium-italic {
  font-family: indivisible, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin indivisible-semi-bold {
  font-family: indivisible, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin indivisible-semi-bold-italic {
  font-family: indivisible, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin indivisible-bold {
  font-family: indivisible, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin indivisible-bold-italic {
  font-family: indivisible, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin indivisible-black {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin indivisible-black-italic {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Nunito

@mixin nunito-thin {
  font-family: nunito, sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin nunito-thin-italic {
  font-family: nunito, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin nunito-extra-light {
  font-family: nunito, sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin nunito-extra-light-italic {
  font-family: nunito, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin nunito-light {
  font-family: nunito, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin nunito-light-italic {
  font-family: nunito, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin nunito-regular {
  font-family: nunito, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin nunito-regular-italic {
  font-family: nunito, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin nunito-medium {
  font-family: nunito, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin nunito-medium-italic {
  font-family: nunito, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin nunito-semi-bold {
  font-family: nunito, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin nunito-semi-bold-italic {
  font-family: nunito, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin nunito-bold {
  font-family: nunito, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin nunito-bold-italic {
  font-family: nunito, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin nunito-extra-bold {
  font-family: nunito, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin nunito-extra-bold-italic {
  font-family: nunito, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin nunito-black {
  font-family: nunito, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin nunito-black-italic {
  font-family: nunito, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Nunito Sans

@mixin nunito-sans-thin {
  font-family: nunito-sans, sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin nunito-sans-thin-italic {
  font-family: nunito-sans, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin nunito-sans-extra-light {
  font-family: nunito-sans, sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin nunito-sans-extra-light-italic {
  font-family: nunito-sans, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin nunito-sans-light {
  font-family: nunito-sans, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin nunito-sans-light-italic {
  font-family: nunito-sans, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin nunito-sans-regular {
  font-family: nunito-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin nunito-sans-regular-italic {
  font-family: nunito-sans, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin nunito-sans-medium {
  font-family: nunito-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin nunito-sans-medium-italic {
  font-family: nunito-sans, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin nunito-sans-semi-bold {
  font-family: nunito-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin nunito-sans-semi-bold-italic {
  font-family: nunito-sans, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin nunito-sans-bold {
  font-family: nunito-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin nunito-sans-bold-italic {
  font-family: nunito-sans, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin nunito-sans-extra-bold {
  font-family: nunito-sans, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin nunito-sans-extra-bold-italic {
  font-family: nunito-sans, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin nunito-sans-black {
  font-family: nunito-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin nunito-sans-black-italic {
  font-family: nunito-sans, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Courier STD

@mixin courier-regular {
  font-family: courier-std, monospace;
  font-weight: 400;
  font-style: normal;
}

@mixin courier-regular-italic {
  font-family: courier-std, monospace;
  font-weight: 400;
  font-style: italic;
}

@mixin courier-bold {
  font-family: courier-std, monospace;
  font-weight: 700;
  font-style: normal;
}

@mixin courier-bold-italic {
  font-family: courier-std, monospace;
  font-weight: 700;
  font-style: italic;
}

// Inter

@mixin inter-thin {
  font-family: Inter, sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin inter-thin-italic {
  font-family: Inter, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin inter-extra-light {
  font-family: Inter, sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin inter-extra-light-italic {
  font-family: Inter, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin inter-light {
  font-family: Inter, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin inter-light-italic {
  font-family: Inter, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin inter-regular {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin inter-regular-italic {
  font-family: inter, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin inter-medium {
  font-family: inter, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin inter-medium-italic {
  font-family: inter, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin inter-semi-bold {
  font-family: inter, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin inter-semi-bold-italic {
  font-family: inter, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin inter-bold {
  font-family: inter, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin inter-bold-italic {
  font-family: inter, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin inter-extra-bold {
  font-family: inter, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin inter-extra-bold-italic {
  font-family: inter, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin inter-black {
  font-family: inter, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin inter-black-italic {
  font-family: inter, sans-serif;
  font-weight: 900;
  font-style: italic;
}
