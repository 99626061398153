@use 'src/scss/font' as f;

.print-menu.soul-bud-print-portrait-theme {
  page: soul-bud-portrait-pages;
}

@page soul-bud-portrait-pages {
  margin: 0.25in;
}

.soul-bud-print-portrait-theme {
  $soul-bud-sativa: #ab8876;
  $soul-bud-indica: #9caf88;
  $soul-bud-hybrid: #7a99ac;
  $soul-bud-blend: #8e8ba6;
  $soul-bud-cbd: #6d60dc;

  // Header

  .main-header {
    @include f.PT-mono-bold();
    font-size: 3.64rem;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .header-container {
    justify-content: center;
    padding: 1rem 0 1rem 0;
  }

  .company-logo {
    max-width: 20rem;
    max-height: 4.25rem;
  }

  // Sections

  .title-section {
    @include f.poppins-semi-bold();
    font-size: 1.27rem;
    text-transform: uppercase;
    padding: 0.5rem;
  }

  .title-section-container {
    margin-bottom: 0.5rem !important;
  }

  .sections-container {
    margin-left: 0.025rem;
    margin-right: 0.025rem;
  }

  .section-container {
    margin-bottom: 0.5rem;
  }

  .section-header-container {
    height: 2rem;
    padding-left: 0.5rem;
    text-transform: uppercase;
  }

  .section-column-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section-title {
    @include f.poppins-medium();
    font-size: 1.09rem;
  }

  .section-header-general {
    @include f.poppins-medium();
    font-size: 1.09rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Product

  .product-container {
    height: 2rem;
  }

  .product-column {
    flex-direction: row;
    height: 80%;
  }

  .product-column-general {
    @include f.poppins-medium();
    font-size: 0.9rem;
    text-align: center;
  }

  .product-cannabinoid,
  .product-terpene,
  .product-quantity,
  .product-variant-price-integer,
  .product-size,
  .product-variant-secondary-price {
    padding-left: 0.25rem;
  }

  .product-strain-type {
    background-color: transparent;
    @include f.poppins-bold();
    display: flex;
    justify-content: center;
    align-items: center;

    &.s,
    &.i,
    &.b,
    &.h,
    &.c {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.9rem;
    }

    &.sativa,
    &.indica,
    &.blend,
    &.hybrid,
    &.cbd {
      width: 4rem;
      height: 1.5rem;
      font-size: 0.9rem;
    }

    &.s,
    &.sativa {
      color: $soul-bud-sativa;
    }

    &.i,
    &.indica {
      color: $soul-bud-indica;
    }

    &.b,
    &.blend {
      color: $soul-bud-blend;
    }

    &.h,
    &.hybrid {
      color: $soul-bud-hybrid;
    }

    &.c,
    &.cbd {
      color: $soul-bud-cbd;
    }
  }

  .product-title-container {
    padding-left: 0.5rem;
    padding-right: 0;
  }

  .product-title {
    font-size: 0.9rem;
    @include f.poppins-medium();
  }

  .product-brand {
    font-size: 0.9rem;
    @include f.poppins-italic();
    margin-left: 0.5rem;
  }

  .product-flag-label {
  }

  .product-label {
    font-size: 0.73rem;
    @include f.poppins-medium();
    padding: 0 1rem;
    margin-left: 1rem;
    margin-right: 0.1rem;
    align-items: flex-start;
  }

  .flag-body {
    padding: 0 1rem;
  }

  .product-badge {
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }

  .badge {
    height: 1.3rem;
  }

  .classification-mapping {
    @include f.poppins-bold();
    @include f.f16px();
    flex-direction: row;
    text-align: -webkit-center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;

    .sativa {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $soul-bud-sativa;
    }

    .hybrid {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $soul-bud-hybrid;
    }

    .indica {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $soul-bud-indica;
    }

    .blend {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $soul-bud-blend;
    }

    .cbd {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $soul-bud-cbd;
    }
  }

  // Font Styling
  .force-bold-style * {
    @include f.poppins-extra-bold;
  }
}
