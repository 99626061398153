@use 'src/scss/font' as f;

.true-north-theme {
  $product-section-title-height: 6.25rem;

  // Header

  .main-header {
    @include f.gilroy-extra-bold;
    font-size: 4rem;
    letter-spacing: 0;
    text-align: left;
  }

  .header-container {
    padding: 1rem 3.125rem 1rem 3.125rem;
  }

  .header-meta-desc {
    @include f.f24px;
    @include f.montserrat-bold;
    color: black;
  }

  // Section container

  .sections-container {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 0;
  }

  // Sections

  .title-section {
    @include f.gilroy-extra-bold;
    @include f.f56px;
    margin-bottom: 0.5rem;
    letter-spacing: -0.02em;
    margin-left: 5.5%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .section-container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .title-section-content-wrapper {
    flex: 1;
    height: $product-section-title-height;
    display: flex;
    align-items: flex-end;
    overflow-x: hidden;
  }

  .title-section-container {
    height: $product-section-title-height;
    display: flex;
    color: black;
    align-items: flex-end;
    margin-right: 0.75rem;
    margin-left: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0;
  }

  .section-header-container {
    padding: 0.35rem 0;
  }

  .section-title {
    @include f.gilroy-extra-bold;
    @include f.f20px;
    letter-spacing: -0.02em;
    margin-left: 0.5rem;
  }

  .section-header-pre-roll {
    @include f.gilroy-extra-bold;
    @include f.f20px;
    vertical-align: middle;
    letter-spacing: -0.02em;
  }

  .section-header-general {
    @include f.gilroy-extra-bold;
    @include f.f20px;
    text-align: left;
    letter-spacing: -0.02em;
  }

  .section-header-variant-price {
  }

  // Product

  .product-column-general {
    @include f.montserrat-medium;
    font-size: 1rem;
  }

  .product-container {
    align-items: center;
    height: 2.2rem;
  }

  .product-title {
    @include f.montserrat-medium;
    font-size: 1rem;
    letter-spacing: -0.02em;
    padding-left: 0.5rem;
  }

  .product-label {
    @include f.gilroy-semi-bold;
    margin: 0 0.5rem;
    font-size: 0.75rem;
    letter-spacing: -0.02em;
  }

  .product-subtitle {
    @include f.montserrat-medium;
    line-height: 1rem;
    font-size: 0.8125rem;
    letter-spacing: -0.02em;
  }

  .product-cannabinoid,
  .product-terpene {
    @include f.montserrat-medium;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: -0.02em;
  }

  .product-thc {
    @include f.montserrat-bold;
  }

  .product-variant-price {
    text-transform: capitalize;
    @include f.montserrat-bold;
  }

  .product-quantity,
  .product-size {
    font-size: 1rem;
  }

  .product-low-amount {
    @include f.montserrat-medium;
    font-size: 1rem;
  }

  .product-column {
    @include f.montserrat-medium;
    text-align: left;
    font-size: 1rem;
  }

  .product-badge {
    text-align: center;
  }

  .badge {
    height: 2rem;
  }

  .product-row-overlay {
  }

  // Font Styling

  .force-bold-style * {
    @include f.montserrat-bold;
  }
}
