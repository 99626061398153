@use 'src/scss/font' as f;
@use 'src/scss/variables' as v;

.print-menu.fire-and-flower-print-theme {
  page: fire-and-flower-print-theme-pages;
}

@page fire-and-flower-print-theme-pages {
  margin: 0.5in;
}

.fire-and-flower-print-theme {
  $rem11toRem16: calc(16 / 11);

  // Header

  .header-container {
    line-height: 8rem;
    justify-content: center;
    padding: 0 0.25rem;

    .company-logo {
      height: calc(#{$rem11toRem16} * 3.75rem);
    }
  }

  .main-header {
    @include f.futura-pt-demi();
    font-size: calc(#{$rem11toRem16} * 3rem);
  }

  // Section container

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  .section-header-container {
    padding: calc(#{$rem11toRem16} * 0.125rem) 0 calc(#{$rem11toRem16} * 0.1875rem) 0;
    border: 1px solid;
  }

  // Sections

  .title-section {
    @include f.futura-pt-demi();
    font-size: calc(#{$rem11toRem16} * 3rem);
  }

  .title-section-container {
  }

  // Product

  $spacing: 1.5rem;

  .title-section-container {
    // see getTitleSectionTopMargin in fire-and-flower-print-menu
    // margin-top: calc(#{$rem11toRem16} * #{$spacing});
  }

  .product-section-container {
    margin-top: calc(#{$rem11toRem16} * #{$spacing});
  }

  .image-section {
    margin-top: calc(#{$rem11toRem16} * #{$spacing});
  }

  .section-title-text {
    @include f.futura-pt-demi();
    font-size: calc(#{$rem11toRem16} * 1.25rem);
    line-height: 1;
  }

  .section-header-general {
    @include f.futura-pt-demi();
    font-size: calc(#{$rem11toRem16} * 1rem);
    line-height: 1;
  }

  .product-title-container {
    padding-left: calc(#{$rem11toRem16} * 0.25rem);
  }

  .product-title {
    @include f.futura-pt-medium();
  }

  .product-container {
    @include f.futura-pt-book();
    font-size: calc(#{$rem11toRem16} * 1rem);
    height: calc(#{$rem11toRem16} * 2.75rem);
  }

  .section-title {
    padding-left: calc(#{$rem11toRem16} * 0.25rem);
  }

  .product-label-container {
    vertical-align: middle;
  }

  .product-label {
    @include f.open-sans-semi-bold();
    font-size: calc(#{$rem11toRem16} * 0.625rem);
    display: flex;
    justify-content: right;
    vertical-align: middle;
    margin-left: 0;
    margin-right: calc(#{$rem11toRem16} * 0.5rem);
  }

  .badge {
    height: calc(#{$rem11toRem16} * 1.25rem);
  }

  // Font Styling

  .force-bold-style * {
    @include f.futura-pt-bold();
  }
}
