@use 'src/scss/font' as f;

$font-offset: 1.25rem;

.northern-lights {
  &.landscape-menu {
    .section-container {
      margin: 0 0.5rem;
    }
  }

  .header-container {
    @include f.shelby-regular;

    .main-header {
      @include f.f96px;
      padding-top: 1rem;
      padding-left: $font-offset;
    }

    .company-logo {
      max-height: 4rem;
    }
  }

  .section-container {
    .title-section {
      @include f.shelby-regular;
      @include f.f96px;
      padding-top: 1.5rem;
      padding-left: $font-offset;
    }
  }

  .section-header-container {
    @include f.roboto-medium-extra;
    text-transform: uppercase;
    padding: 0.625rem 0;

    .section-title {
      @include f.f32px;
      letter-spacing: 0.2rem;
      padding-left: 1.25rem;
    }

    .section-header-general {
      @include f.f20px;
      letter-spacing: 0.125rem;
    }
  }

  .product-title-container {
    padding-left: 0.25rem;
  }

  .product-title,
  .product-column-general {
    @include f.roboto-medium;
    @include f.f20px;
  }

  .product-container {
    height: 4.5rem;

    .product-column:not(.product-column-Badge) {
      justify-content: flex-start;
      box-sizing: border-box;
      padding-top: 0.725rem;
    }

    .product-column.product-column-Badge {
      min-width: 3rem;
    }

    .product-column-general.product-badge {
      display: flex;
    }
  }

  .product-title-flex-container {
    justify-content: space-between;
  }

  .product-subtitle {
    @include f.roboto-regular;
    @include f.f20px;
  }

  .product-label {
    @include f.poppins-medium;
    @include f.f18px;
    line-height: 100%;
    padding: 0.25rem 0.5rem;
    margin-left: 0.625rem;
  }
}
