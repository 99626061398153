@use 'src/scss/font' as f;

.runtz-theme {
  // Header

  .main-header {
    @include f.work-sans-semibold;
    @include f.f64px;
  }

  .header-container {
    justify-content: center;
    padding: 1rem 3.3rem;
    margin-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .company-logo {
    max-width: 20rem;
    max-height: 4.25rem;
  }

  // Section container

  .runtz-sections-container {
    border-radius: 0.5rem;
    padding: 0 0 1rem 0;
  }

  .sections-container {
    margin-left: 2.125rem;
    margin-right: 2.125rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
  }

  // Sections

  .title-section {
    @include f.work-sans-semibold;
    @include f.f48px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .section-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-header-container {
    height: 4rem;
  }

  .section-column-title {
    display: flex;
  }

  .section-title {
    @include f.work-sans-semibold;
    @include f.f38px;
    flex: unset;
    max-width: fit-content;
  }

  .section-header-general {
    @include f.work-sans-medium;
    @include f.f25px;
  }

  // Product

  .product-container {
    align-items: center;
    height: 3rem;
  }

  .product-column-general {
    @include f.work-sans-medium;
    @include f.f20px;
  }

  .product-title {
    align-self: center;
  }

  .product-title-container {
    @include f.work-sans-medium;
    @include f.f22px;
  }

  .product-label {
    @include f.poppins-medium;
    @include f.f18px;
    line-height: 100%;
    padding: 0.25rem 0.5rem;
    margin-left: 0.625rem;
  }

  .product-low-amount {
  }

  .badge {
    height: 1.75rem;
  }

  .image-section {
    margin-top: 1rem;
  }

  .footer-container {
    padding: 1.0625rem 2.125rem;
  }

  .force-bold-style * {
    @include f.work-sans-bold;
  }
}
