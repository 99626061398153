@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.print-menu.calyx-trichomes-theme {
  page: calyx-trichomes-pages;
}

@page calyx-trichomes-pages {
  margin: 0.5in;
}

.calyx-trichomes-theme {
  // Header
  .header-container {
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    align-items: unset;
    border-bottom: 0;
    padding: 0;
    justify-content: flex-start;
  }

  .menu-title {
    font-size: 2rem;
    @include m.no-wrap;
    @include f.facile-sans;
  }

  .header-desc {
    @include f.f20px;
    @include f.banks-miles;
  }

  // Sections
  .sections-container {
    border-top-width: 0;
    margin-left: 0;
    margin-right: 0;
    overflow-y: overlay;
  }

  .section-header-bottom-border {
    border-bottom: none;
  }

  .section-header-container {
    height: 3.5rem;
  }

  .section-column {
    align-self: end;
  }

  .section-title {
    text-decoration-thickness: 1px;
    margin-right: 0.5rem;
    padding-top: 1px;
    @include f.f28px;
    @include f.facile-sans;

    .section-title-icon {
      height: 1.3rem;
      width: 1.3rem;
      align-self: flex-start;
    }
  }

  .section-header-general {
    text-decoration-thickness: 1px;
    text-align: center;
    @include f.f28px;
    @include f.facile-sans;
  }

  .section-header-badge {
    direction: rtl;
  }

  .section-header-producer {
    text-align: left;
  }

  .product-column-general {
  }

  .product-container {
    height: 3rem;
  }

  .product-brand {
    text-align: left;
  }

  .product-badge {
    display: flex;
  }

  // Product
  .product-title {
    line-height: 2rem;
  }

  .product-title-container {
  }

  .product-column {
    text-align: center;
    @include f.f18px;
  }

  .products-container {
    @include f.banks-miles-medium;
    @include f.f18px;
  }

  .product-label {
    @include f.banks-miles-bold;
    font-style: bold;
    font-size: 0.75rem;
    padding: 0.05rem 0.3rem;
  }

  // Title Section
  .title-section {
    @include f.facile-sans;
    @include f.f28px;
    padding-top: 0.5rem;
  }

  .force-bold-style * {
    @include f.banks-miles-bold;
  }
}

html.print-menu {
  .calyx-trichomes-theme {
    .header-container {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .sections-container {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
