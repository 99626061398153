@use 'src/scss/font' as f;

.mary-jane-theme {
  .company-logo {
    max-height: 8rem;
  }

  // Header

  .header-container-portrait {
    padding-top: 7.5rem;
  }

  .header-container-landscape {
    padding-top: 3.125rem;
  }

  .main-header {
    @include f.mont-heavy;
    height: auto;
    font-size: 7.5rem;
    letter-spacing: 0;
  }

  // Section container

  .sections-container {
    margin-left: 2.125rem;
    margin-right: 2.125rem;
  }

  // Sections

  .title-section {
    @include f.mont-heavy;
  }

  .section-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-header-container {
    color: white;
    background-color: black;
    border-radius: 0.625rem;
    height: 5.125rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
  }

  .section-title {
    @include f.mont-heavy;
    font-size: 2.75rem;
    letter-spacing: 0.01625rem;
    align-self: center;
  }

  .section-header-general {
    @include f.poppins-bold;
    font-size: 1.5rem;
    line-height: 1.625;
    letter-spacing: 0.0125rem;
  }

  .section-header-pre-roll {
    @include f.poppins-bold;
    vertical-align: middle;
    font-size: 1.25rem;
    line-height: 1.325;
    letter-spacing: 0.0125rem;
  }

  // Product

  .product-container {
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5.5rem;
  }

  .product-title {
    @include f.poppins-bold;
    font-size: 1.75rem;
    line-height: 1.8;
    letter-spacing: 0.01375rem;
  }

  .product-label-container {
    vertical-align: middle;
  }

  .product-label {
    @include f.poppins-regular;
    height: 1rem;
    font-size: 0.8rem;
    letter-spacing: 0.0625rem;
    vertical-align: middle;
  }

  .product-subtitle {
    @include f.poppins-medium;
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 0.009375rem;
  }

  .product-variant-price {
    @include f.poppins-bold;
    font-size: 1.65rem;
    line-height: 1.75;
    letter-spacing: 0.0125rem;
  }

  .product-variant-price-decoration {
    font-size: 1.1rem;
    line-height: 1.2;
    letter-spacing: 0.0125rem;
    vertical-align: text-top;
  }

  .product-cannabinoid,
  .product-terpene {
    @include f.poppins-bold;
    font-size: 1.5rem;
    line-height: 1.625;
    letter-spacing: 0.01rem;
  }

  .product-low-amount {
    @include f.poppins-medium;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .product-column {
    @include f.poppins-semi-bold;
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .product-size {
    font-size: 1.5rem;
    line-height: 1.625;
  }

  .footer-container {
    padding: 2rem 3.125rem;
  }

  // Font Styling

  .force-bold-style * {
    @include f.poppins-black;
  }
}

// Specific styling just for web version of theme

.mary-jane-theme.web-menu {
  .main-header {
    font-size: 5rem;
  }

  .company-logo {
    max-height: 6.5rem;
  }

  .product-cannabinoid,
  .product-terpene,
  .product-low-amount {
    font-size: 1rem;
  }
}
