@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.marketing-featured-category-theme {
  $extra-dark-grey-color: #73767f;
  $base-card-padding: 1.5rem;

  .card-image-header-container {
    position: relative;
    display: flex;
    padding: $base-card-padding $base-card-padding 2rem $base-card-padding;
    width: calc(100% - 3rem); // change this if base-card-padding changes
    height: 13.25rem;
  }

  .card-image-header {
    display: block;
    position: initial;
    top: $base-card-padding;
    left: $base-card-padding;
    right: $base-card-padding;
    bottom: 2rem;
  }

  .card-number {
    @include f.f32px;
    @include f.source-sans-pro-semi-bold;
  }

  .card-image-header-number {
    display: block;
    position: absolute;
    top: 2rem;
    left: 1.5rem;
    z-index: 2;
    margin-left: 0.5rem;
  }

  .card-title {
    @include f.f48px;
    @include m.line-clamp(1);
    padding: 0 $base-card-padding;
  }

  .card-title.hide-price-font-size {
    @include f.f58px;
  }

  .card-subtitle {
    @include f.f32px;
    padding: 0 $base-card-padding;
  }

  .product-menu-container {
    .sections-container {
      margin-bottom: 0.5rem;

      .content-inside-sections-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        white-space: nowrap;
        column-gap: 2.5rem;
      }
    }

    .section-container {
      width: 100%;
      height: 100%;
      overflow-y: hidden;
      background-color: white;
      border-radius: 0.5rem;
    }

    .card-container {
      flex: none;
      height: 100%;
    }

    .card-title {
      @include f.source-sans-pro-bold;
      line-height: 1;
    }

    .card-subtitle {
      @include f.source-sans-pro-regular;
      @include m.line-clamp(1);
      line-height: 1;
    }

    &.screen-is-portrait {
      .content-inside-sections-container {
        flex-wrap: wrap;
        gap: 2.5rem;
      }

      .card-container {
        height: calc(50% - 1.25rem);
      }
    }

    &.landscape-2-sections-on-screen {
      .card-container {
        width: calc(50% - 1.25rem);
      }
      .product-title {
        @include f.f28px;
        line-height: 1;
      }
      .product-title.hide-price-font-size {
        @include f.f34px;
        line-height: 1;
      }
      .product-subtitle {
        @include f.f20px;
      }
      .product-variant-price {
        @include f.f28px;
      }
    }

    &.landscape-3-sections-on-screen {
      .card-container {
        width: calc(33% - 1.3rem);
      }
    }

    &.landscape-4-sections-on-screen {
      .card-container {
        width: calc(25% - 1.85rem);
      }
    }

    &.portrait-2-sections-on-screen {
      .card-container {
        flex: none;
        width: 100%;
      }
      .product-title {
        @include f.f28px;
        line-height: 1;
      }
      .product-title.hide-price-font-size {
        @include f.f34px;
        line-height: 1;
      }
      .product-subtitle {
        @include f.f20px;
      }
      .product-variant-price {
        @include f.f28px;
      }
    }

    &.portrait-3-4-sections-on-screen {
      .card-container {
        flex: 1 1 45%;
        width: calc(50% - 1.25rem);

        // last child that's in the odd position
        &:nth-last-child(1):nth-child(odd) {
          flex: none;
        }
      }
    }
  }

  .product-column-VariantPrice {
    align-self: flex-start;
  }

  .products-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
  }

  .product-container {
    padding: 0 1.5rem;
  }

  .product-title {
    @include f.f24px;
    line-height: 1;
    @include f.source-sans-pro-semi-bold;
  }

  .product-title.hide-price-font-size {
    @include f.f29px;
    line-height: 1;
  }

  .product-subtitle {
    color: $extra-dark-grey-color;
    white-space: break-spaces;
    width: 100%;
    @include f.f18px;
    @include f.source-sans-pro-regular;
  }

  .product-variant-price {
    @include f.f24px;
    @include f.source-sans-pro-semi-bold;
  }

  .product-variant-previous-price {
    color: $extra-dark-grey-color;
    @include f.f18px;
    @include f.source-sans-pro-regular;
    text-decoration: line-through;
    text-align: end;
  }

  .product-label {
    @include f.f12px;
    @include f.poppins-regular;
  }

  .header-container {
    align-items: start;
    padding: 2rem 3rem 1rem 3rem;
  }

  @mixin title-font {
    @include f.source-sans-pro-bold;
    @include f.f72px;
    line-height: 1;
    letter-spacing: -0.02em;
  }

  .main-header {
    @include title-font;
  }

  .main-subtitle {
    @include f.source-sans-pro-regular;
    @include f.f32px;
    @include m.no-wrap;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
  }
}
