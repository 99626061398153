@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.print-menu.order-review-theme {
  page: order-review-pages;
}

@page order-review-pages {
  margin: 0.25in;
}

.order-review-theme {
  .main-header {
    @include f.poppins-bold;
    @include f.f28px;
  }

  .header-container {
    align-items: flex-end;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .company-logo {
    max-width: 12rem;
    max-height: 4rem;
  }

  // Section container

  .product-section-container {
  }

  .sections-container {
  }

  // Sections

  .title-section {
    @include f.poppins-bold;
    @include f.f20px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .section-container {
  }

  .section-header-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 1.1rem;
  }

  .section-column {
    height: 100%;
  }

  .section-column-title {
    display: flex;
    flex: unset;
    background-color: unset;
  }

  .section-title {
    @include f.poppins-bold;
    @include f.f20px;
    line-height: 100%;
    flex: unset;
    max-width: fit-content;
  }

  .section-header-general {
    @include f.poppins-medium;
    @include f.f12px;
    color: white;
  }

  // Product

  .product-container {
    align-items: center;
    height: 1.5rem;
    padding-left: 0.25rem;
  }

  .new-product-container {
    height: unset;
  }

  .new-product-columns {
    height: 2rem;
  }

  .product-column-new-badge {
    position: relative;
    width: unset;
    margin-top: 0.75rem;
  }

  .product-column-general {
    @include f.poppins-medium;
    @include f.f12px;
  }

  .product-title {
    align-self: center;
  }

  .new-product-title-container {
    .product-title-flex-container {
      gap: 0.5rem;
    }
  }

  .product-title-flex-container {
    gap: 0.5rem;
  }

  .product-title-container {
    height: 100%;
    display: flex;
    @include f.poppins-bold;
    @include f.f14px;
  }

  .product-label {
    @include f.poppins-medium;
    @include f.f10px;
    line-height: 100%;
    padding: 0.25rem 0.5rem;
    margin-left: 0;
  }

  .product-low-amount {
  }

  .product-badge {
    display: flex;
  }

  .product-new-badge {
    position: absolute;
    top: 0;
    right: 0;
    width: unset;
  }

  .badge {
    height: 1.25rem;
  }

  .new-product-badge {
  }

  .image-section {
    margin-top: 1rem;
  }

  .new-product-desc {
    @include f.poppins-medium;
    @include f.f12px;
    @include m.line-clamp(4);
  }

  .footer-container {
    padding: 1.0625rem 2.125rem;
  }

  .force-bold-style * {
    @include f.poppins-bold;
  }
}
