// Archivo

$archivo-thin-url: '/assets/fonts/Archivo/Archivo-Thin';
$archivo-thin-ttf: $archivo-thin-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-thin-ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

$archivo-thin-italic-url: '/assets/fonts/Archivo/Archivo-ThinItalic';
$archivo-thin-italic-ttf: $archivo-thin-italic-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-thin-italic-ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

$archivo-extra-light-url: '/assets/fonts/Archivo/Archivo-ExtraLight';
$archivo-extra-light-ttf: $archivo-extra-light-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-extra-light-ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

$archivo-extra-light-italic-url: '/assets/fonts/Archivo/Archivo-ExtraLightItalic';
$archivo-extra-light-italic-ttf: $archivo-extra-light-italic-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-extra-light-italic-ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

$archivo-light-url: '/assets/fonts/Archivo/Archivo-Light';
$archivo-light-ttf: $archivo-light-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-light-ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

$archivo-light-italic-url: '/assets/fonts/Archivo/Archivo-LightItalic';
$archivo-light-italic-ttf: $archivo-light-italic-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-light-italic-ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

$archivo-regular-url: '/assets/fonts/Archivo/Archivo-Regular';
$archivo-regular-ttf: $archivo-regular-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-regular-ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

$archivo-italic-url: '/assets/fonts/Archivo/Archivo-Italic';
$archivo-italic-ttf: $archivo-italic-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-italic-ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

$archivo-medium-url: '/assets/fonts/Archivo/Archivo-Medium';
$archivo-medium-ttf: $archivo-medium-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-medium-ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

$archivo-medium-italic-url: '/assets/fonts/Archivo/Archivo-MediumItalic';
$archivo-medium-italic-ttf: $archivo-medium-italic-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-medium-italic-ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

$archivo-semi-bold-url: '/assets/fonts/Archivo/Archivo-SemiBold';
$archivo-semi-bold-ttf: $archivo-semi-bold-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-semi-bold-ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

$archivo-semi-bold-italic-url: '/assets/fonts/Archivo/Archivo-SemiBoldItalic';
$archivo-semi-bold-italic-ttf: $archivo-semi-bold-italic-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-semi-bold-italic-ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

$archivo-bold-url: '/assets/fonts/Archivo/Archivo-Bold';
$archivo-bold-ttf: $archivo-bold-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-bold-ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

$archivo-bold-italic-url: '/assets/fonts/Archivo/Archivo-BoldItalic';
$archivo-bold-italic-ttf: $archivo-bold-italic-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-bold-italic-ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

$archivo-extra-bold-url: '/assets/fonts/Archivo/Archivo-ExtraBold';
$archivo-extra-bold-ttf: $archivo-extra-bold-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-extra-bold-ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

$archivo-extra-bold-italic-url: '/assets/fonts/Archivo/Archivo-ExtraBoldItalic';
$archivo-extra-bold-italic-ttf: $archivo-extra-bold-italic-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-extra-bold-italic-ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

$archivo-black-url: '/assets/fonts/Archivo/Archivo-Black';
$archivo-black-ttf: $archivo-black-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-black-ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

$archivo-black-italic-url: '/assets/fonts/Archivo/Archivo-BlackItalic';
$archivo-black-italic-ttf: $archivo-black-italic-url + '.ttf';
@font-face {
  font-family: 'Archivo';
  src: url($archivo-black-italic-ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

// Banks & Miles

$banks-miles-bold-url: '/assets/fonts/BanksMiles/banksmilessingleline-bold-webfont';
$banks-miles-bold-woff: $banks-miles-bold-url + '.woff';
$banks-miles-bold-woff2: $banks-miles-bold-url + '.woff2';
@font-face {
  font-family: 'Banks-Miles';
  src:
    url($banks-miles-bold-woff) format('woff'),
    url($banks-miles-bold-woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

$banks-miles-bold-italics-url: '/assets/fonts/BanksMiles/banksmilessingleline-bldobl-webfont';
$banks-miles-bold-italics-woff: $banks-miles-bold-italics-url + '.woff';
$banks-miles-bold-italics-woff2: $banks-miles-bold-italics-url + '.woff2';
@font-face {
  font-family: 'Banks-Miles';
  src:
    url($banks-miles-bold-italics-woff) format('woff'),
    url($banks-miles-bold-italics-woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}

$banks-miles-regular-url: '/assets/fonts/BanksMiles/banksmilessingleline-webfont';
$banks-miles-regular-woff: $banks-miles-regular-url + '.woff';
$banks-miles-regular-woff2: $banks-miles-regular-url + '.woff2';
@font-face {
  font-family: 'Banks-Miles';
  src:
    url($banks-miles-regular-woff) format('woff'),
    url($banks-miles-regular-woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

$banks-miles-italics-url: '/assets/fonts/BanksMiles/banksmilessingleline-obliqu-webfont';
$banks-miles-italics-woff: $banks-miles-italics-url + '.woff';
$banks-miles-italics-woff2: $banks-miles-italics-url + '.woff2';
@font-face {
  font-family: 'Banks-Miles';
  src:
    url($banks-miles-italics-woff) format('woff'),
    url($banks-miles-italics-woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}

$banks-miles-med-url: '/assets/fonts/BanksMiles/banksmilessingleline-med-webfont';
$banks-miles-med-woff: $banks-miles-med-url + '.woff';
$banks-miles-med-woff2: $banks-miles-med-url + '.woff2';
@font-face {
  font-family: 'Banks-Miles';
  src:
    url($banks-miles-med-woff) format('woff'),
    url($banks-miles-med-woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

$banks-miles-med-italics-url: '/assets/fonts/BanksMiles/banksmilessingleline-medobl-webfont';
$banks-miles-med-italics-woff: $banks-miles-med-italics-url + '.woff';
$banks-miles-med-italics-woff2: $banks-miles-med-italics-url + '.woff2';
@font-face {
  font-family: 'Banks-Miles';
  src:
    url($banks-miles-med-italics-woff) format('woff'),
    url($banks-miles-med-italics-woff2) format('woff2');
  font-weight: 600;
  font-style: italic;
}

// Big Noodle

$big-noodle-font-path: '/assets/fonts/BigNoodle/Big-Noodle-Titling.ttf';
@font-face {
  font-family: 'Big-Noodle-Titling';
  src: url($big-noodle-font-path) format('truetype');
}

// Dongle

$dongle-bold-font-path-ttf: '/assets/fonts/Dongle/Dongle-Bold.ttf';
$dongle-regular-font-path-ttf: '/assets/fonts/Dongle/Dongle-Regular.ttf';
$dongle-light-font-path-ttf: '/assets/fonts/Dongle/Dongle-Light.ttf';
@font-face {
  font-family: 'Dongle';
  src:
    url($dongle-bold-font-path-ttf) format('truetype'),
    url($dongle-regular-font-path-ttf) format('truetype'),
    url($dongle-light-font-path-ttf) format('truetype');
}

// Ubuntu

$ubuntu-font-path: '/assets/fonts/Ubuntu/UbuntuTitling-Bold.ttf';
@font-face {
  font-family: 'Ubuntu-Titled';
  src: url($ubuntu-font-path) format('truetype');
}

// Facile-Sans
$facile-font-path: '/assets/fonts/Facile/Facile Sans.otf';
$facile-font-path-woff2: '/assets/fonts/Facile/Facile Sans.woff2';
$facile-font-path-woff: '/assets/fonts/Facile/Facile Sans.woff';
$facile-font-path-ttf: '/assets/fonts/Facile/Facile Sans.ttf';
@font-face {
  font-family: 'Facile-Sans';
  src:
    url($facile-font-path) format('opentype'),
    url($facile-font-path-woff2) format('woff2'),
    url($facile-font-path-woff) format('woff'),
    url($facile-font-path-ttf) format('truetype');
}

// Mont

$mont-font-path-ttf: '/assets/fonts/Mont/Mont-Heavy.otf';
@font-face {
  font-family: 'Mont-Heavy';
  src: url($mont-font-path-ttf) format('opentype');
}

$mont-light-font-path-ttf: '/assets/fonts/Mont/Mont-ExtraLight.otf';
@font-face {
  font-family: 'Mont-ExtraLight';
  src: url($mont-light-font-path-ttf) format('opentype');
}

//SuisseIntl
$suisse-font-path-ttf: '/assets/fonts/SuisseIntl/SuisseIntl-Bold.otf';
@font-face {
  font-family: 'SuisseIntl-Bold';
  src: url($suisse-font-path-ttf) format('opentype');
}

$suisse-semi-font-path-ttf: '/assets/fonts/SuisseIntl/SuisseIntl-SemiBold.otf';
@font-face {
  font-family: 'SuisseIntl-SemiBold';
  src: url($suisse-semi-font-path-ttf) format('opentype');
}

$suisse-medium-font-path-ttf: '/assets/fonts/SuisseIntl/SuisseIntl-Medium.otf';
@font-face {
  font-family: 'SuisseIntl-Medium';
  src: url($suisse-medium-font-path-ttf) format('opentype');
}

$suisse-regular-font-path-ttf: '/assets/fonts/SuisseIntl/SuisseIntl-Regular.otf';
@font-face {
  font-family: 'SuisseIntl-Regular';
  src: url($suisse-regular-font-path-ttf) format('opentype');
}

$suisse-bold-italic-font-path-ttf: '/assets/fonts/SuisseIntl/SuisseIntl-BoldItalic.otf';
@font-face {
  font-family: 'SuisseIntl-BoldItalic';
  src: url($suisse-bold-italic-font-path-ttf) format('opentype');
}

$suisse-semi-bold-italic-font-path-ttf: '/assets/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.otf';
@font-face {
  font-family: 'SuisseIntl-SemiBoldItalic';
  src: url($suisse-semi-bold-italic-font-path-ttf) format('opentype');
}

$suisse-medium-italic-font-path-ttf: '/assets/fonts/SuisseIntl/SuisseIntl-MediumItalic.otf';
@font-face {
  font-family: 'SuisseIntl-MediumItalic';
  src: url($suisse-medium-italic-font-path-ttf) format('opentype');
}

$suisse-regular-italic-font-path-ttf: '/assets/fonts/SuisseIntl/SuisseIntl-RegularItalic.otf';
@font-face {
  font-family: 'SuisseIntl-Italic';
  src: url($suisse-regular-italic-font-path-ttf) format('opentype');
}

// Windsor

$windsor-font-path-ttf: '/assets/fonts/Windsor/Windsor-Bold.otf';
@font-face {
  font-family: 'Windsor-Bold';
  src: url($windsor-font-path-ttf) format('opentype');
}

// Gilroy

$gilroy-thin: '/assets/fonts/Gilroy/Gilroy-Thin.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

$gilroy-thin-italic: '/assets/fonts/Gilroy/Gilroy-ThinItalic.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-thin) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

$gilroy-ultra-light: '/assets/fonts/Gilroy/Gilroy-UltraLight.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-ultra-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$gilroy-ultra-light-italic: '/assets/fonts/Gilroy/Gilroy-UltraLightItalic.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-ultra-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$gilroy-light: '/assets/fonts/Gilroy/Gilroy-Light.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$gilroy-light-italic: '/assets/fonts/Gilroy/Gilroy-LightItalic.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$gilroy-regular: '/assets/fonts/Gilroy/Gilroy-Regular.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$gilroy-regular-italic: '/assets/fonts/Gilroy/Gilroy-RegularItalic.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$gilroy-medium: '/assets/fonts/Gilroy/Gilroy-Medium.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$gilroy-medium-italic: '/assets/fonts/Gilroy/Gilroy-MediumItalic.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$gilroy-semi-bold: '/assets/fonts/Gilroy/Gilroy-SemiBold.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-semi-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

$gilroy-semi-bold-italic: '/assets/fonts/Gilroy/Gilroy-SemiBoldItalic.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-semi-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

$gilroy-bold: '/assets/fonts/Gilroy/Gilroy-Bold.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$gilroy-bold-italic: '/assets/fonts/Gilroy/Gilroy-BoldItalic.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$gilroy-extra-bold: '/assets/fonts/Gilroy/Gilroy-ExtraBold.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-extra-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

$gilroy-extra-bold-italic: '/assets/fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-extra-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

$gilroy-heavy: '/assets/fonts/Gilroy/Gilroy-Heavy.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-heavy) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

$gilroy-heavy-italic: '/assets/fonts/Gilroy/Gilroy-HeavyItalic.ttf';
@font-face {
  font-family: 'Gilroy';
  src: url($gilroy-heavy-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}

// Spectral

$spectral-extra-light: '/assets/fonts/Spectral/Spectral-ExtraLight.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-extra-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$spectral-extra-light-italic: '/assets/fonts/Spectral/Spectral-ExtraLightItalic.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-extra-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$spectral-light: '/assets/fonts/Spectral/Spectral-Light.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$spectral-light-italic: '/assets/fonts/Spectral/Spectral-LightItalic.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$spectral-regular: '/assets/fonts/Spectral/Spectral-Regular.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$spectral-regular-italic: '/assets/fonts/Spectral/Spectral-Italic.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$spectral-medium: '/assets/fonts/Spectral/Spectral-Medium.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$spectral-medium-italic: '/assets/fonts/Spectral/Spectral-MediumItalic.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$spectral-semi-bold: '/assets/fonts/Spectral/Spectral-SemiBold.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-semi-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

$spectral-semi-bold-italic: '/assets/fonts/Spectral/Spectral-SemiBoldItalic.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-semi-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

$spectral-bold: '/assets/fonts/Spectral/Spectral-Bold.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$spectral-bold-italic: '/assets/fonts/Spectral/Spectral-BoldItalic.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$spectral-extra-bold: '/assets/fonts/Spectral/Spectral-ExtraBold.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-extra-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

$spectral-extra-bold-italic: '/assets/fonts/Spectral/Spectral-ExtraBoldItalic.ttf';
@font-face {
  font-family: 'Spectral';
  src: url($spectral-extra-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

// Work Sans

$work-sans-thin: '/assets/fonts/WorkSans/WorkSans-Thin.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

$work-sans-thin-italic: '/assets/fonts/WorkSans/WorkSans-ThinItalic.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

$work-sans-extra-light: '/assets/fonts/WorkSans/WorkSans-ExtraLight.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-extra-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$work-sans-extra-light-italic: '/assets/fonts/WorkSans/WorkSans-ExtraLightItalic.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-extra-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$work-sans-light: '/assets/fonts/WorkSans/WorkSans-Light.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$work-sans-light-italic: '/assets/fonts/WorkSans/WorkSans-LightItalic.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$work-sans-regular: '/assets/fonts/WorkSans/WorkSans-Regular.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$work-sans-regular-italic: '/assets/fonts/WorkSans/WorkSans-Italic.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$work-sans-medium: '/assets/fonts/WorkSans/WorkSans-Medium.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$work-sans-medium-italic: '/assets/fonts/WorkSans/WorkSans-MediumItalic.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$work-sans-semi-bold: '/assets/fonts/WorkSans/WorkSans-SemiBold.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-semi-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

$work-sans-semi-bold-italic: '/assets/fonts/WorkSans/WorkSans-SemiBoldItalic.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-semi-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

$work-sans-bold: '/assets/fonts/WorkSans/WorkSans-Bold.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$work-sans-bold-italic: '/assets/fonts/WorkSans/WorkSans-BoldItalic.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$work-sans-extra-bold: '/assets/fonts/WorkSans/WorkSans-ExtraBold.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-extra-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

$work-sans-extra-bold-italic: '/assets/fonts/WorkSans/WorkSans-ExtraBoldItalic.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-extra-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

$work-sans-black: '/assets/fonts/WorkSans/WorkSans-Black.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-black) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

$work-sans-black-italic: '/assets/fonts/WorkSans/WorkSans-BlackItalic.ttf';
@font-face {
  font-family: 'Work-Sans';
  src: url($work-sans-black-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}

// Roboto

$roboto-thin: '/assets/fonts/Roboto/Roboto-Thin.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

$roboto-thin-italic: '/assets/fonts/Roboto/Roboto-ThinItalic.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

$roboto-light: '/assets/fonts/Roboto/Roboto-Light.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$roboto-light-italic: '/assets/fonts/Roboto/Roboto-LightItalic.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$roboto-regular: '/assets/fonts/Roboto/Roboto-Regular.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$roboto-regular-italic: '/assets/fonts/Roboto/Roboto-Italic.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$roboto-medium: '/assets/fonts/Roboto/Roboto-Medium.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$roboto-medium-italic: '/assets/fonts/Roboto/Roboto-MediumItalic.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$roboto-bold: '/assets/fonts/Roboto/Roboto-Bold.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$roboto-bold-italic: '/assets/fonts/Roboto/Roboto-BoldItalic.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$roboto-black: '/assets/fonts/Roboto/Roboto-Black.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-black) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

$roboto-black-italic: '/assets/fonts/Roboto/Roboto-BlackItalic.ttf';
@font-face {
  font-family: 'Roboto';
  src: url($roboto-black-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}

// Poppins

$poppins-thin: '/assets/fonts/Poppins/Poppins-Thin.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

$poppins-thin-italic: '/assets/fonts/Poppins/Poppins-ThinItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

$poppins-extra-light: '/assets/fonts/Poppins/Poppins-ExtraLight.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-extra-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$poppins-extra-light-italic: '/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-extra-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$poppins-light: '/assets/fonts/Poppins/Poppins-Light.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$poppins-light-italic: '/assets/fonts/Poppins/Poppins-LightItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$poppins-regular: '/assets/fonts/Poppins/Poppins-Regular.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$poppins-regular-italic: '/assets/fonts/Poppins/Poppins-Italic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$poppins-medium: '/assets/fonts/Poppins/Poppins-Medium.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$poppins-medium-italic: '/assets/fonts/Poppins/Poppins-MediumItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$poppins-semibold: '/assets/fonts/Poppins/Poppins-SemiBold.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-semibold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

$poppins-semibold-italic: '/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-semibold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

$poppins-bold: '/assets/fonts/Poppins/Poppins-Bold.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$poppins-bold-italic: '/assets/fonts/Poppins/Poppins-BoldItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$poppins-extra-bold: '/assets/fonts/Poppins/Poppins-ExtraBold.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-extra-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

$poppins-extra-bold-italic: '/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-extra-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

$poppins-black: '/assets/fonts/Poppins/Poppins-Black.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-black) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

$poppins-black-italic: '/assets/fonts/Poppins/Poppins-BlackItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-black-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}

// Karla

$karla-extra-light: '/assets/fonts/Karla/Karla-ExtraLight.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-extra-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$karla-extra-light-italic: '/assets/fonts/Karla/Karla-ExtraLightItalic.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-extra-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$karla-light: '/assets/fonts/Karla/Karla-Light.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$karla-light-italic: '/assets/fonts/Karla/Karla-LightItalic.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$karla-regular: '/assets/fonts/Karla/Karla-Regular.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$karla-regular-italic: '/assets/fonts/Karla/Karla-Italic.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$karla-medium: '/assets/fonts/Karla/Karla-Medium.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$karla-medium-italic: '/assets/fonts/Karla/Karla-MediumItalic.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$karla-semibold: '/assets/fonts/Karla/Karla-SemiBold.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-semibold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

$karla-semibold-italic: '/assets/fonts/Karla/Karla-SemiBoldItalic.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-semibold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

$karla-bold: '/assets/fonts/Karla/Karla-Bold.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$karla-bold-italic: '/assets/fonts/Karla/Karla-BoldItalic.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$karla-extra-bold: '/assets/fonts/Karla/Karla-ExtraBold.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-extra-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

$karla-extra-bold-italic: '/assets/fonts/Karla/Karla-ExtraBoldItalic.ttf';
@font-face {
  font-family: 'Karla';
  src: url($karla-extra-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

// Metropolis

$metropolis-thin: '/assets/fonts/Metropolis/Metropolis-Thin.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

$metropolis-thin-italic: '/assets/fonts/Metropolis/Metropolis-ThinItalic.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

$metropolis-extra-light: '/assets/fonts/Metropolis/Metropolis-ExtraLight.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-extra-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$metropolis-extra-light-italic: '/assets/fonts/Metropolis/Metropolis-ExtraLightItalic.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-extra-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$metropolis-light: '/assets/fonts/Metropolis/Metropolis-Light.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$metropolis-light-italic: '/assets/fonts/Metropolis/Metropolis-LightItalic.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$metropolis-regular: '/assets/fonts/Metropolis/Metropolis-Regular.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$metropolis-regular-italic: '/assets/fonts/Metropolis/Metropolis-RegularItalic.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$metropolis-medium: '/assets/fonts/Metropolis/Metropolis-Medium.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$metropolis-medium-italic: '/assets/fonts/Metropolis/Metropolis-MediumItalic.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$metropolis-semibold: '/assets/fonts/Metropolis/Metropolis-SemiBold.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-semibold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

$metropolis-semibold-italic: '/assets/fonts/Metropolis/Metropolis-SemiBoldItalic.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-semibold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

$metropolis-bold: '/assets/fonts/Metropolis/Metropolis-Bold.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$metropolis-bold-italic: '/assets/fonts/Metropolis/Metropolis-BoldItalic.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$metropolis-extra-bold: '/assets/fonts/Metropolis/Metropolis-ExtraBold.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-extra-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

$metropolis-extra-bold-italic: '/assets/fonts/Metropolis/Metropolis-ExtraBoldItalic.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-extra-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

$metropolis-black: '/assets/fonts/Metropolis/Metropolis-Black.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-black) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

$metropolis-black-italic: '/assets/fonts/Metropolis/Metropolis-BlackItalic.ttf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-black-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}

// Inter

$inter-thin-url: '/assets/fonts/Inter/Inter_28pt-Thin';
$inter-thin-ttf: $inter-thin-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-thin-ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

$inter-thin-italic-url: '/assets/fonts/Inter/Inter_28pt-ThinItalic';
$inter-thin-italic-ttf: $inter-thin-italic-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-thin-italic-ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

$inter-extra-light-url: '/assets/fonts/Inter/Inter_28pt-ExtraLight';
$inter-extra-light-ttf: $inter-extra-light-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-extra-light-ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

$inter-extra-light-italic-url: '/assets/fonts/Inter/Inter_28pt-ExtraLightItalic';
$inter-extra-light-italic-ttf: $inter-extra-light-italic-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-extra-light-italic-ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

$inter-light-url: '/assets/fonts/Inter/Inter_28pt-Light';
$inter-light-ttf: $inter-light-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-light-ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

$inter-light-italic-url: '/assets/fonts/Inter/Inter_28pt-LightItalic';
$inter-light-italic-ttf: $inter-light-italic-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-light-italic-ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

$inter-regular-url: '/assets/fonts/Inter/Inter_28pt-Regular';
$inter-regular-ttf: $inter-regular-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-regular-ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

$inter-italic-url: '/assets/fonts/Inter/Inter_28pt-Italic';
$inter-italic-ttf: $inter-italic-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-italic-ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

$inter-medium-url: '/assets/fonts/Inter/Inter_28pt-Medium';
$inter-medium-ttf: $inter-medium-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-medium-ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

$inter-medium-italic-url: '/assets/fonts/Inter/Inter_28pt-MediumItalic';
$inter-medium-italic-ttf: $inter-medium-italic-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-medium-italic-ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

$inter-semi-bold-url: '/assets/fonts/Inter/Inter_28pt-SemiBold';
$inter-semi-bold-ttf: $inter-semi-bold-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-semi-bold-ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

$inter-semi-bold-italic-url: '/assets/fonts/Inter/Inter_28pt-SemiBoldItalic';
$inter-semi-bold-italic-ttf: $inter-semi-bold-italic-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-semi-bold-italic-ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

$inter-bold-url: '/assets/fonts/Inter/Inter_28pt-Bold';
$inter-bold-ttf: $inter-bold-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-bold-ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

$inter-bold-italic-url: '/assets/fonts/Inter/Inter_28pt-BoldItalic';
$inter-bold-italic-ttf: $inter-bold-italic-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-bold-italic-ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

$inter-extra-bold-url: '/assets/fonts/Inter/Inter_28pt-ExtraBold';
$inter-extra-bold-ttf: $inter-extra-bold-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-extra-bold-ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

$inter-extra-bold-italic-url: '/assets/fonts/Inter/Inter_28pt-ExtraBoldItalic';
$inter-extra-bold-italic-ttf: $inter-extra-bold-italic-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-extra-bold-italic-ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

$inter-black-url: '/assets/fonts/Inter/Inter_28pt-Black';
$inter-black-ttf: $inter-black-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-black-ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

$inter-black-italic-url: '/assets/fonts/Inter/Inter_28pt-BlackItalic';
$inter-black-italic-ttf: $inter-black-italic-url + '.ttf';
@font-face {
  font-family: 'Inter';
  src: url($inter-black-italic-ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

// NeueHaasGroteskDisplay

$neue-haas-grotesk-display-XX-thin: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayXThin.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-XX-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

$neue-haas-grotesk-display-XX-thin-italic: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayXThinItalic.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-XX-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

$neue-haas-grotesk-display-X-thin: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayThin.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-X-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$neue-haas-grotesk-display-X-thin-italic: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayThinItalic.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-X-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$neue-haas-grotesk-display-thin: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayLight.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$neue-haas-grotesk-display-thin-italic: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayLightItalic.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$neue-haas-grotesk-display-regular: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayRoman.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$neue-haas-grotesk-display-italic: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayRomanItalic.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$neue-haas-grotesk-display-medium: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayMedium.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$neue-haas-grotesk-display-medium-italic: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayMediumItalic.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$neue-haas-grotesk-display-bold: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayBold.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$neue-haas-grotesk-display-bold-italic: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayBoldItalic.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$neue-haas-grotesk-display-black: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayBlack.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-black) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

$neue-haas-grotesk-display-black-italic: '/assets/fonts/NeueHaasGroteskDisplay/NeueHaasGroteskDisplayBlackItalic.ttf';
@font-face {
  font-family: 'NeueHaasGroteskDisplay';
  src: url($neue-haas-grotesk-display-black-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}
