@use 'src/scss/font';
@use 'src/scss/variables';
@use 'src/scss/mixins';

.pur-life-theme-landscape {
  $pur-life-sativa: #ab8876;
  $pur-life-indica: #9caf88;
  $pur-life-hybrid: #7a99ac;
  $pur-life-blend: #8e8ba6;

  // Header

  .main-header {
    @include font.roboto-black();
    font-size: 3.5rem;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .header-container {
    justify-content: center;
  }

  .company-logo {
    max-width: 20rem;
    max-height: 4.25rem;
  }

  // Sections

  .title-section {
    @include font.roboto-black();
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 0.3rem;
  }

  .title-section-container {
    margin-bottom: 0.5rem !important;
    margin-top: 0 !important;
  }

  .title-section-image-container {
    padding-right: 1rem;
  }

  .section-container {
    margin-bottom: 0.4rem;
    padding-right: 1rem;
  }

  .section-header-container {
    height: 2.25rem;
    text-transform: uppercase;
  }

  .section-title {
    @include font.roboto-bold();
    font-size: 1.5rem;
    padding-left: 1rem;
  }

  .section-column-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section-header-general {
    @include font.roboto-bold();
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  // Product

  .product-container {
    height: 1.9rem;
  }

  .product-odd {
  }

  .product-column {
    flex-direction: row;
    height: 80%;
  }

  .product-column-general {
    @include font.roboto-medium-extra();
    font-size: 1rem;
    text-align: center;
  }

  .product-cannabinoid,
  .product-terpene,
  .product-quantity,
  .product-variant-price-integer,
  .product-size,
  .product-variant-secondary-price {
    padding-left: 0.3rem;
  }

  .product-strain-type {
    background-color: transparent;
    @include font.roboto-black();
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;

    &.s {
      color: $pur-life-sativa;
    }

    &.i {
      color: $pur-life-indica;
    }

    &.b {
      color: $pur-life-blend;
    }

    &.h {
      color: $pur-life-hybrid;
    }
  }

  .product-title-container {
    padding-left: 0.5rem;
    padding-right: 0;
  }

  .product-title {
    @include font.f16px();
    @include font.roboto-bold();
  }

  .product-brand {
    @include font.f16px();
    @include font.roboto-regular-italic();
    margin-left: 0.5rem;
  }

  .product-label {
    @include font.f10px();
    @include font.roboto-bold();
    padding: 0.25rem 0.5rem;
    margin-left: 0.3rem;
    margin-right: 0.1rem;
    align-items: flex-start;
  }

  .product-badge {
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }

  .badge {
    height: 1.1rem;
  }

  // Font Styling
  .force-bold-style * {
    @include font.roboto-black-extra();
  }
}
