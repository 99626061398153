@use 'src/scss/font' as f;
@use 'src/scss/variables' as v;

.print-menu.true-north-print-theme {
  page: true-north-print-theme-pages;
}

@page true-north-print-theme-pages {
  margin: 0.25in;
}

.true-north-print-theme {
  $title-section-height: 3.625rem;

  .background-asset {
    border-radius: 1rem;
  }

  // Header

  .main-header {
    @include f.montserrat-extra-bold;
    font-size: 4rem;
    letter-spacing: 0;
    text-align: left;
  }

  .header-container {
    padding: 1rem 1.75rem 1rem 1.75rem;
    justify-content: center;
  }

  .header-meta-desc {
    @include f.f24px;
    @include f.montserrat-bold;
    color: black;
  }

  .company-logo {
    padding-top: 0.5rem;
  }

  // Section container

  .sections-container {
    margin: 0.5rem 1.25rem 1.25rem 1.25rem;
  }

  // Sections

  .image-section {
    margin: 2.5rem 0;
  }

  .title-section {
    @include f.montserrat-extra-bold;
    @include f.f36px;
    letter-spacing: -0.02em;
    padding: 0 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: $title-section-height;
  }

  .section-container {
    margin: 0 1.25rem;
  }

  .title-section-container {
    color: black;
    border-radius: 1rem 1rem 0 0;
    margin-bottom: 0;
  }

  .section-header-container {
    padding: 0.3125rem 0 0.1875rem 0;
    background-color: #eaeaea;
    color: #231f2080;
  }

  .section-title {
    @include f.montserrat-extra-bold;
    @include f.f20px;
    letter-spacing: -0.02em;
    margin-left: 0.5rem;
  }

  .section-header-pre-roll {
    @include f.montserrat-extra-bold;
    @include f.f20px;
    vertical-align: middle;
    letter-spacing: -0.02em;
  }

  .section-header-general {
    @include f.montserrat-extra-bold;
    @include f.f20px;
    text-align: left;
    letter-spacing: -0.02em;
    box-sizing: border-box;
    padding-left: 0.5rem;
  }

  .section-header-variant-price {
  }

  // Product

  .product-section-container {
    &.first-on-page,
    &.after-asset-section {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    &.before-title-section,
    &.last-product-section-on-page,
    &.before-asset-section {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }

  .product-column-general {
    @include f.montserrat-medium;
    @include f.f14px;
  }

  .product-container {
    align-items: center;
    height: 2.2rem;
  }

  .product-title {
    @include f.montserrat-bold;
    @include f.f14px;
    letter-spacing: -0.02em;
    padding-left: 0.5rem;
  }

  .product-label {
    @include f.montserrat-semi-bold;
    margin: 0 0.5rem;
    font-size: 0.75rem;
    letter-spacing: -0.02em;
  }

  .product-subtitle {
    @include f.montserrat-medium;
    line-height: 1rem;
    font-size: 0.8125rem;
    letter-spacing: -0.02em;
  }

  .product-cannabinoid,
  .product-terpene {
    @include f.f14px;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    @include f.montserrat-medium;
  }

  .product-thc {
    @include f.montserrat-bold;
  }

  .product-variant-price {
    text-transform: capitalize;
    @include f.montserrat-bold;
    @include f.f14px;
  }

  .product-quantity,
  .product-size {
    @include f.f14px;
  }

  .product-low-amount {
    @include f.montserrat-medium;
    @include f.f14px;
  }

  .product-column {
    @include f.montserrat-medium;
    @include f.f14px;
    text-align: left;
    box-sizing: border-box;
    padding-left: 0.5rem;
  }

  .product-badge {
    text-align: center;
    padding-right: 0.5rem;
  }

  .badge {
    height: 2rem;
  }

  .product-row-overlay {
  }

  // Font Styling

  .force-bold-style * {
    @include f.montserrat-bold;
  }
}
