.scroll-down-over-specific-time-period {
  animation: scroll-down-over-specific-time-period var(--down-scroll-time) linear var(--steady-scroll-delay-animation) 1
    both running normal;
}

.delay-end-of-scroll-down {
  animation-name: delay-end-of-scroll-down;
  animation-duration: var(--steady-scroll-delay-animation);
  animation-delay: 0s;
  animation-fill-mode: both;
}

.scroll-up-over-specific-time-period {
  animation: scroll-up-over-specific-time-period var(--up-scroll-time) linear var(--steady-scroll-delay-animation) 1
    both running normal;
}

.scroll-right-over-specific-time-period {
  animation: scroll-right-over-specific-time-period var(--right-scroll-time) linear var(--steady-scroll-delay-animation)
    1 both running normal;
}

.delay-end-of-scroll-right {
  animation-name: delay-end-of-scroll-right;
  animation-duration: var(--steady-scroll-delay-animation);
  animation-delay: 0s;
  animation-fill-mode: both;
}

.scroll-left-over-specific-time-period {
  animation: scroll-left-over-specific-time-period var(--left-scroll-time) linear var(--steady-scroll-delay-animation) 1
    both running normal;
}

@keyframes scroll-down-over-specific-time-period {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(var(--sections-container-scroll-height) * -1px));
  }
}

@keyframes delay-end-of-scroll-down {
  0% {
    transform: translateY(calc(var(--sections-container-scroll-height) * -1px));
  }
  100% {
    transform: translateY(calc(var(--sections-container-scroll-height) * -1px));
  }
}

@keyframes scroll-up-over-specific-time-period {
  0% {
    transform: translateY(calc(var(--sections-container-scroll-height) * -1px));
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes scroll-right-over-specific-time-period {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--sections-container-scroll-width) * -1px));
  }
}

@keyframes delay-end-of-scroll-right {
  0% {
    transform: translateX(calc(var(--sections-container-scroll-width) * -1px));
  }
  100% {
    transform: translateX(calc(var(--sections-container-scroll-width) * -1px));
  }
}

@keyframes scroll-left-over-specific-time-period {
  0% {
    transform: translateX(calc(var(--sections-container-scroll-width) * -1px));
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
