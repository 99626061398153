@use 'src/scss/font' as f;

.print-menu.plantlife-theme {
  page: plantlife-theme-pages;
}

@page plantlife-theme-pages {
  margin: 0.25in;
}

.plantlife-theme {
  $plantlife-border-thickness: 1px;
  $plantlife-black: #222222;
  $plantlife-white: #ffffff;
  $plantlife-sativa-section-header-color: #dedede;
  $plantlife-sativa-item-color: #dedede;
  $plantlife-hybrid-section-header-color: #ffffff;
  $plantlife-hybrid-item-color: #ffffff;
  $plantlife-indica-section-header-color: #cccccc;
  $plantlife-indica-item-color: #cccccc;

  @mixin top-border {
    border-top: $plantlife-border-thickness solid $plantlife-black;
  }

  @mixin right-border {
    border-right: $plantlife-border-thickness solid $plantlife-black;
  }

  @mixin left-border() {
    border-left: $plantlife-border-thickness solid $plantlife-black;
  }

  @mixin bottom-border() {
    border-bottom: $plantlife-border-thickness solid $plantlife-black;
  }

  // Header

  .main-header {
    @include f.bebas-neue-pro-regular;
    font-size: 7.5rem;
    text-transform: uppercase;
    text-align: center;
  }

  .header-container {
    background-color: $plantlife-black;
    justify-content: center;
    padding: 1rem 0 !important;

    .company-logo {
      height: 5.5rem;
    }
  }

  // products container

  .products-container {
    display: flex;
    flex-direction: row;
  }

  // Section container

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  .sativa-products {
    flex: 1 1 100%;
    max-width: 33.33%;
    background-color: $plantlife-sativa-item-color;
    overflow-x: hidden;
    @include right-border;
    &.last {
      @include bottom-border;
    }
  }

  .hybrid-products {
    flex: 1;
    background-color: $plantlife-hybrid-item-color;
    overflow-x: hidden;
    &.last {
      @include bottom-border;
    }
  }

  .indica-products {
    flex: 1 1 100%;
    max-width: 33.33%;
    background-color: $plantlife-indica-item-color;
    overflow-x: hidden;
    @include left-border;
    &.last {
      @include bottom-border;
    }
  }

  // Sections

  .title-section {
    @include f.bebas-neue-pro-regular;
    font-size: 3.75rem;
  }

  .section-column {
    &.section-column-classification {
      flex: 1;
    }
  }

  .section-container {
    flex: 1;
    padding-left: 0;
    padding-right: 0;
    overflow: unset;
  }

  .sativa-hybrid-indica-section-container {
    @include left-border;
    @include right-border;
  }

  .sativa-hybrid-indica-container {
    display: flex;
    flex-direction: row;
    @include bottom-border;

    .sativa-section-header {
      flex: 1 1 100%;
      max-width: 33.33%;
      display: flex;
      flex-direction: row;
      background-color: $plantlife-sativa-section-header-color;
      @include right-border;
    }

    .hybrid-section-header {
      flex: 1 1 100%;
      max-width: 33.33%;
      display: flex;
      flex-direction: row;
      background-color: $plantlife-hybrid-section-header-color;
    }

    .indica-section-header {
      flex: 1 1 100%;
      max-width: 33.33%;
      display: flex;
      flex-direction: row;
      background-color: $plantlife-indica-section-header-color;
      @include left-border;
    }
  }

  .section-header-title {
    @include f.bebas-neue-pro-bold;
    @include f.f28px;
    letter-spacing: 0.0625rem;
    background-color: $plantlife-black;
    color: $plantlife-white;
    text-transform: uppercase;
    text-align: center;
    padding: 0.25rem;
    @include bottom-border;
  }

  .section-header-container {
    flex-direction: column;
    padding-top: 0 !important;
  }

  .section-title {
    @include f.bebas-neue-pro-regular;
    font-size: 3.75rem;
  }

  .section-column-thc-and-cbd {
    @include left-border;
  }

  .section-column-price {
    @include left-border;
  }

  .section-header-general {
    @include f.bebas-neue-pro-bold;
    @include f.f20px;
    color: $plantlife-black;
    letter-spacing: 0.0625rem;
  }

  // Product

  .product-column-general {
    @include f.bebas-neue-pro-regular;
    @include f.f20px;
  }

  .product-container {
    align-items: center;
    height: 2.82rem;
    @include bottom-border;

    &.sativa {
      background-color: $plantlife-sativa-item-color;
    }

    &.hybrid {
      background-color: $plantlife-hybrid-item-color;
    }

    &.indica {
      background-color: $plantlife-indica-item-color;
    }

    &.last {
      border-bottom: unset;
    }
  }

  .product-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding-left: 0.25rem; // 4px
    @include right-border;
  }

  .product-title-flex-container {
    justify-content: space-between;
  }

  .product-title {
    @include f.bebas-neue-pro-bold;
    @include f.f19px;
  }

  .product-badge {
    display: flex;
    gap: 1px;
  }

  .badge {
    height: 1.2rem;
  }

  .product-label {
    @include f.bebas-neue-pro-bold;
    @include f.f12px;
    margin-left: 0;
    border-radius: 0.2rem;
    padding: 0.125rem 0.2rem;
    align-self: end;
    letter-spacing: 0.03125rem;
  }

  .subtitle-flex-container {
    justify-content: space-between;
  }

  .product-subtitle {
    @include f.bebas-neue-pro-regular;
    @include f.f18px;
  }

  .product-column-Size {
    @include right-border;
  }

  .product-column-THCAndCBD {
    @include right-border;
  }

  .product-thc-and-cbd {
  }

  .product-variant-price {
    @include f.f20px;
  }

  .product-quantity {
  }

  .product-low-amount {
    @include f.bebas-neue-pro-regular;
  }

  .product-size {
    @include f.f18px;
  }

  .product-column {
    @include f.bebas-neue-pro-regular;
    @include f.f16px;
  }

  .base-product-strain-type {
    @include f.bebas-neue-pro-regular;
  }

  .image-section {
    margin-top: 0;
  }

  // Font Styling

  .force-bold-style * {
    @include f.bebas-neue-pro-bold;
  }
}
