@use 'src/scss/font' as f;

.doug-theme {
  $themeColor: #023048;
  $borderThickness: 1.5rem;

  // Header

  .header-container {
  }

  .main-header {
    @include f.suisse-bold;
    font-size: 4rem;
    letter-spacing: 0;
  }

  .company-logo {
    max-height: 5rem;
  }

  // Section container

  .sections-container {
    margin: 0 2.125rem;
  }

  // Sections

  .title-section {
    @include f.suisse-bold;
    font-size: 3rem;
  }

  .section-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-header-container {
    color: white;
    background-color: black;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    height: 3.5rem;
    margin-top: 1rem;
    padding: 0.5rem 0;
  }

  .section-column-title,
  .product-title-container {
    padding-left: 1rem;
  }

  .section-header-general {
    font-size: 1.25rem;
    @include f.suisse-semi-bold;
  }

  .section-title {
    @include f.suisse-bold;
    font-size: 1.625rem;
    letter-spacing: 0.01625rem;
    align-self: center;
  }

  .section-header-pre-roll {
    @include f.suisse-bold;
    vertical-align: middle;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .section-header-variant-price {
    @include f.suisse-bold;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .section-header-badge {
    @include f.suisse-semi-bold;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .section-header-thc {
    @include f.suisse-semi-bold;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .section-header-cbd {
    @include f.suisse-semi-bold;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .section-header-size {
    @include f.suisse-semi-bold;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .section-header-quantity {
    @include f.suisse-semi-bold;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  // Product

  .product-container {
    align-items: center;
    height: 4.5rem;
  }

  .product-odd {
  }

  .product-even {
    background: #f0f0f0;
  }

  .product-title {
    font-size: 1.375rem;
    letter-spacing: 0.01375rem;
    vertical-align: middle;
    @include f.suisse-bold;
  }

  .product-label {
    @include f.suisse-semi-bold;
    font-size: 0.875rem;
    letter-spacing: 0;
  }

  .product-subtitle {
    @include f.suisse-medium;
    font-size: 0.9375rem;
    letter-spacing: 0.009375rem;
  }

  .product-column-general {
    font-size: 1rem;
    @include f.suisse-semi-bold;
  }

  .product-variant-price {
    @include f.suisse-bold;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .product-cannabinoid,
  .product-terpene {
    @include f.suisse-semi-bold;
    font-size: 1rem;
    letter-spacing: 0.01rem;
  }

  .product-low-amount {
    @include f.suisse-medium;
    font-size: 1rem;
  }

  // Footer

  .footer-container {
  }

  // Font Styling

  .force-bold-style * {
    // there are no bolder options for suisse so using poppins
    @include f.poppins-extra-bold;
  }
}
