@use 'src/scss/font';
@use 'src/scss/mixins' as m;

.print-menu.double-gelato-theme {
  page: double-gelato-theme-pages;
}

@page double-gelato-theme-pages {
  margin: 0.25in;
}

.double-gelato-theme {
  $double-gelato-legend-color: #e6eef9;
  $double-gelato-indica-color: #2921ea;
  $double-gelato-sativa-color: #eb433c;
  $double-gelato-hybrid-color: #47b81d;
  $double-gelato-blend-color: #eba92d;
  $double-gelato-cbd-color: #96a9b0;

  // Header

  .main-header {
    @include font.roboto-black;
    font-size: 5.5rem;
    text-transform: uppercase;
    text-align: left;
  }

  .header-container {
    justify-content: center;
    padding: 1rem 0 !important;

    .company-logo {
      height: 5.5rem;
    }
  }

  // products container

  .products-container {
  }

  // Section container

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  // Sections

  .title-section-container {
    margin-bottom: 0 !important;
    color: black;
  }

  .title-section {
    @include font.roboto-black;
    font-size: 3.25rem;
    padding: 1rem 0.73rem;
    line-height: 1.2;
  }

  .title-section-subtitle {
    @include font.roboto-bold;
    line-height: 1.2;
    font-size: 1.5rem;
    padding: 0 0.73rem 0.45rem 0.73rem;
  }

  .section-column {
    &.section-column-classification {
      flex: 1;
    }
  }

  .section-container {
    flex: 1;
    padding-left: 0;
    padding-right: 0;
    overflow: unset;
    margin-bottom: 1.82rem;
  }

  .section-header-title {
    @include font.roboto-bold;
    @include font.f26px;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    text-align: center;
    padding: 0.25rem;
  }

  .section-header-container {
    padding-top: 0 !important;
  }

  .section-title {
    @include font.roboto-bold;
    font-size: 1.75rem;
    letter-spacing: 0.1em;
  }

  .section-title-text {
    padding: 0 0.73rem;
    @include m.no-wrap;
  }

  .section-column-thc-and-cbd {
  }

  .section-column-price {
  }

  .section-header-general {
    @include font.roboto-bold;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 0.0625rem;
  }

  // Product

  .product-column-general {
    @include font.roboto-regular;
    @include font.f16px;
    letter-spacing: -0.5px;
    line-height: 1.5;
    text-align: center;
  }

  .product-container {
    align-items: center;
    height: 3.25rem;
  }

  .product-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding-left: 0.25rem; // 4px
  }

  .product-title {
    @include font.roboto-bold;
    @include font.f19px;
  }

  .product-badge {
    display: flex;
    gap: 1px;
  }

  .product-brand {
    text-align: left;
  }

  .badge {
    height: 2rem;
  }

  .product-label {
    @include font.roboto-bold;
    font-size: 0.8rem;
    margin-left: 0;
    border-radius: 0.2rem;
    padding: 0.125rem 0.2rem;
    align-self: end;
    letter-spacing: 0.03125rem;
  }

  .product-title-flex-container {
    gap: 0.5rem;
  }

  .subtitle-flex-container {
    gap: 0.5rem;
  }

  .under-subtitle-flex-container {
    display: none;
  }

  .product-subtitle {
    @include font.roboto-regular;
    @include font.f16px;
  }

  .product-thc-and-cbd {
  }

  .product-variant-price {
    @include font.f18px;
  }

  .product-quantity {
  }

  .product-low-amount {
    @include font.roboto-regular;
  }

  .product-size {
    @include font.f16px;
  }

  .product-column {
    @include font.roboto-regular;
    @include font.f14px;
  }

  .base-product-strain-type {
    @include font.roboto-regular;
  }

  .image-section {
    margin-top: 0;
    padding: 3.27rem;
  }

  $gapBetweenSwimlanes: 1.5rem;

  .wrapping-swimlane-section-container {
    gap: $gapBetweenSwimlanes;
    margin-bottom: 0;
  }

  .footer-container {
    margin-top: 0;
    padding: 0 3.125rem;
  }

  .footer-classification-board {
    @include font.roboto-black;
    text-align: center;
    font-size: 1.25rem;
    color: black;
    column-gap: 0;
    row-gap: 1rem;
  }

  .product-strain-type,
  .classification-badge {
    @include font.roboto-black;

    &.i,
    &.h,
    &.s,
    &.b,
    &.c {
      font-size: 1.25rem;
      width: 1.5rem;
    }

    &.indica,
    &.hybrid,
    &.sativa,
    &.blend,
    &.cbd {
      font-size: 1rem;
      width: 4.5rem;
    }

    &.i,
    &.indica {
      color: $double-gelato-indica-color;
    }

    &.s,
    &.sativa {
      color: $double-gelato-sativa-color;
    }

    &.h,
    &.hybrid {
      color: $double-gelato-hybrid-color;
    }

    &.b,
    &.blend {
      color: $double-gelato-blend-color;
    }

    &.c,
    &.cbd {
      color: $double-gelato-cbd-color;
    }
  }

  .product-strain-type {
    width: 1.5rem;
  }

  // Font Styling

  .force-bold-style * {
    @include font.roboto-black;
  }
}
