@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.grid-lines-theme {
  $black-bg: #222222;
  $backgroundColor: white;
  $pageMargin: 75px;
  $titleMargin: 0.75rem;
  $distanceBetweenSections: 1.375rem;

  .company-logo-container {
    max-width: 50%;
  }

  .company-logo-container .company-logo {
    max-width: 75%;
    object-position: bottom left;
  }

  // Display Component

  .display-container {
  }

  .menu-container {
  }

  // Menu Header

  .header-container {
    padding-left: $pageMargin;
    padding-right: $pageMargin;
  }

  .main-header {
    @include f.arial-bold;
    font-size: 4rem;
    text-align: center;
    flex: 10;
  }

  // Sections

  .sections-container {
    margin-left: $pageMargin;
    margin-right: $pageMargin;
  }

  // Product Section

  .section-container {
    margin-bottom: $distanceBetweenSections;
  }

  .title-section-container {
    color: white;
  }

  // Product Section Header

  .section-header-container {
    color: white;
    height: 54px;
    background-color: $black-bg;
  }

  .section-title {
    @include f.arial-bold;
    margin-left: $titleMargin;
    font-size: 1.5rem;
  }

  .section-column {
    height: 100%;
    display: flex;
  }

  .section-header-general {
    @include f.arial-bold;
    font-size: 1.5rem;
    text-align: end;
    align-self: center;
    margin-right: $titleMargin;
  }

  .black-background {
    background-color: $black-bg;
  }

  .products-container {
    gap: 1px;
    background-color: $black-bg;
  }

  .product-column-general {
    text-align: end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0.625rem;
    @include m.fill-height;
    @include m.fill-width;
  }

  .section-header-variant-price {
  }

  .section-header-pre-roll {
  }

  .section-header-producer {
    border-right: 0;
  }

  .section-header-badge {
  }

  .section-header-quantity {
  }

  .section-header-size {
  }

  .section-header-thc {
  }

  .section-header-cbd {
  }

  .section-header-variant-price {
  }

  // Image Section

  .image-section {
  }

  // Title Section

  .title-section {
    @include f.arial-bold;
    padding-left: $titleMargin;
    font-size: 3rem;
    line-height: 8rem; // Line height should be font-size + 2x 2.5rem
  }

  // Product Section Item

  .product-container {
    @include f.arial-regular;
    font-size: 1.25rem;
    height: 47px;
  }

  .product-title-container {
    display: flex;
    padding-left: $titleMargin;
    @include m.fill-height;
    @include m.fill-width;
  }

  .product-column {
  }

  .product-title {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .product-subtitle {
  }

  .product-label {
    max-height: 1rem;
    font-size: 0.875rem;
  }

  .base-product-strain-type {
  }

  .product-variant-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .product-quantity {
  }

  .product-size {
  }

  .product-cannabinoid,
  .product-terpene {
    font-size: 1.2rem;
  }

  .product-producer {
  }

  .product-brand {
  }

  .product-badge {
    flex-direction: row;
    padding: 0;
  }

  .product-low-amount {
  }

  .pre-roll-button {
  }

  .badge {
    height: 2rem;
  }

  // Menu Footer

  .footer-container {
    padding-top: 1rem;
    padding-bottom: 0;
  }

  .v-align-middle {
  }

  // Product Menu

  .product-menu-container {
  }

  // Subheader

  .subheader-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.75rem $titleMargin 0.75rem $titleMargin;
    color: white;
  }

  .subheader-title {
    @include f.arial-bold;
    font-size: 1.5rem;
  }

  .subheader-subtitle {
    @include f.arial-regular;
    font-size: 1.5rem;
  }

  .default-background-color {
    background-color: $backgroundColor;
    margin-right: 1px;
  }

  // Font Styling

  .force-bold-style * {
    @include f.arial-semi-bold;
  }
}

html.print-menu {
  .grid-lines-theme {
    .product-column-general,
    .product-title-container {
      height: 100% !important;
    }

    .product-title-container .flex-container {
      height: 100% !important;
    }

    .title-section {
      font-size: 4rem;
      line-height: 9rem; // Line height should be font-size + 2x 2.5rem
    }

    .section-header-container {
      height: 32px;
    }

    .product-container {
      height: 26px;
    }

    .header-container {
      padding-left: 0;
      padding-right: 0;
    }

    .sections-container {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .badge-in-legend {
    width: 3rem;
  }

  .classification-name {
    font-size: 0.85rem;
    color: black;
    @include f.arial-regular;
  }
}
