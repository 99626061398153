@use 'src/scss/font' as f;
@use 'src/scss/variables' as v;
@use 'src/scss/mixins' as m;

/*
 * All menu classes that can be overwritten in themes.
 */

// Display Component

.display-container {
  height: 100vh;
}

.fixed-print-wrapper {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.menu-container {
  @include m.fixed-top-left;
  height: 100%;
}

// Menu Header

.header-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem 3.125rem 1rem 3.125rem;
}

.main-header {
  font-size: 5rem;
  letter-spacing: 0;
  flex: 1;
  @include m.no-wrap;
}

.main-title {
  @include m.no-wrap;
}

.header-meta-desc {
  @include f.f24px;
}

.header-meta-image {
  height: 5.75rem;
  width: 5.75rem;
}

.header-logo {
  justify-content: flex-end;
}

// Product Section

.section-container {
  overflow: overlay;

  &.section-level-overflow {
    overflow-y: hidden;
  }
}

.grid-section-container {
  width: 100%;
}

// Product Section Header

.section-header-container {
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.section-header-bottom-border {
  border-bottom: 0.125rem solid;
}

.section-title {
  display: flex;
  text-align: left;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-self: center;
  align-items: center;
}

.section-title-text {
  @include m.no-wrap;
}

.section-title-icon {
  display: inline-block;
  height: 1.875rem;
  width: 1.875rem;
  margin-left: 0.5rem;
  vertical-align: text-top;
  border-radius: 50%;
  flex-shrink: 0;
}

.section-column {
  align-self: center;
}

.section-column-title {
  flex: 1;
  align-self: center;
  @include m.no-wrap;
}

.product-column-general {
  overflow-x: hidden;
  align-self: center;
  width: 100%;
}

.section-header-variant-price {
}

.section-header-pre-roll {
}

.section-header-producer {
}

.section-header-general {
  text-align: center;
  vertical-align: middle;
  font-size: 1.25rem;
  letter-spacing: 0.0125rem;
  width: 100%;
}

.section-header-badge {
  text-align: center;
  vertical-align: middle;
  font-size: 1.25rem;
  letter-spacing: 0.0125rem;
  width: 100%;
  @include f.rubik-regular;
}

.section-header-quantity {
}

.section-header-asset {
}

.section-header-size {
}

.section-header-terpene {
}

.section-header-cannabinoid {
}

.section-header-thc {
}

.section-header-cbd {
}

.section-header-variant-price {
}

// Image Section

.image-section {
  margin-top: 2.5rem;
  display: flex;
}

// Title Section

.title-section {
  font-size: 3rem;
  letter-spacing: 0;
}

.title-section-container {
  position: relative;
}

.title-section-image-container {
  position: absolute;
  display: flex;
  inset: 0;
  z-index: -1;
}

.title-section-background-image {
}

// Product Section Item

.products-container {
  position: relative;
}

.products-page {
}

.products-container-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.product-container {
  display: flex;
  align-items: center;
}

.product-title-container {
  text-align: left;
  flex: 1;
  flex-direction: row;
  /* if overflow/min-width/width is not explicitly set,
     child2 will expand beyond the allowed width and shrink
     other children if they have flex-shrink: 1 */
  overflow: hidden;
  padding-right: 0.25rem;
}

.product-column {
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.product-title {
  @include m.no-wrap;
}

.product-subtitle {
  @include m.no-wrap;
}

.base-product-strain-type {
  @include f.poppins-semi-bold;
  height: max-content;
  align-self: center;
  color: #ffffff;
  max-width: max-content;
  padding: 0 0.625rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  letter-spacing: 0.0625rem;
  border-radius: 0.3125rem;
  vertical-align: middle;
}

.product-variant-price {
}

.product-variant-previous-price {
}

.product-variant-sale-price {
}

.product-variant-price-integer {
  display: inline-block;
  text-decoration: inherit;
}

.product-variant-price-decoration {
  display: inline-block;
  text-decoration: inherit;
}

.product-variant-price-integer-empty {
  display: inline-block;
  text-decoration: inherit;
}

.product-quantity-and-size {
}

.product-quantity {
}

.product-size {
}

.product-strain-type {
}

.product-image-column {
}

.product-image {
}

.product-terpene {
}

.product-cannabinoid {
  overflow-wrap: break-word;
}

.product-producer {
}

.product-brand {
  @include m.no-wrap;
}

.product-spacer {
}

.product-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.25rem;
}

.badge {
  display: flex;
  height: 2.5rem;
  aspect-ratio: 1;
}

.product-low-amount {
}

.pre-roll-button {
}

// Menu Footer

.footer-oval {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  text-align: center;
  border-radius: 80% / 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.footer-container {
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 3.125rem;
}

.v-align-middle {
  vertical-align: middle;
}

// Subheader

.subheader-container {
}

.subheader-title {
}

.subheader-subtitle {
}

// Spotlight Menu

.spotlight-menu-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

// Product Menu

.product-menu-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.product-menu-container.print-product-menu-container {
  // When debugging print menus in Chrome, the following css must be updated from `block` to `flex` otherwise the
  // scrolling property will not work.
  display: block; // print pdf will not work as expected on page breaks if any flex options are used
}

.sections-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  z-index: 1;
  margin-left: 3.125rem;
  margin-right: 3.125rem;
}

.content-inside-sections-container {
  display: flex;
  min-height: 100%;
  min-width: 100%;
  // height: fit-content; set programmatically in product-menu component
  // width: fit-content; set programmatically in product-menu component
  // flex-direction: column; set programmatically in product-menu component
  // flex-wrap: wrap; set programmatically in product-menu component
  // Allows child overflow-calculators to use absolute positions to fit into sections-container
  // position: relative; set programmatically in product-menu component
}

.horizontal-scroll {
  overflow-y: hidden;
  @include m.overlay-x;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
  width: 0; /* Remove scrollbar space */
  height: 0;
  padding: 0 !important;
  background: transparent; /* Optional: just make scrollbar invisible */
}

.vertical-scroll {
  overflow-x: hidden;
  @include m.overlay-y;
}

.vertical-scroll::-webkit-scrollbar {
  display: none;
  width: 0; /* Remove scrollbar space */
  height: 0;
  padding: 0 !important;
  background: transparent; /* Optional: just make scrollbar invisible */
}

.fill-parent {
  height: 100%;
  width: 100%;
}

.header-and-footer-height {
  height: 100%;
}

.header-xor-footer-height {
  height: calc(100% - 2rem);
}

.no-header-and-footer-height {
  height: calc(100% - 4rem);
}

.no-footer {
  padding-bottom: 2rem;
}

.no-header {
  padding-top: 2rem;
}

// Don't allow page-break sections to have a height
.section-page-break {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
