@use 'src/scss/font' as f;
@use 'src/scss/mixins' as m;

.original-combo {
  @mixin title-font {
    @include f.poppins-bold;
    @include f.f96px;
    line-height: 1;
    letter-spacing: -0.02em;
  }

  .wrap-header-in-flex-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
  }

  .header-container {
    align-items: start;
    padding: 2rem 4rem 1rem 4rem;
  }

  .landscape-5-to-8 {
    .header-container {
      padding: 2rem 10.5rem 1rem 10.5rem;
    }
    &.has-footer {
      .header-container {
        padding: 2rem 16.5rem 1rem 16.5rem;
      }
    }
  }

  .landscape-9-to-12 {
    .header-container {
      padding: 2rem 4rem 1rem 4rem;
    }
  }

  .main-header {
    @include title-font;
  }

  .main-title {
    overflow-x: clip;
  }

  .main-subtitle {
    @include f.poppins-regular;
    @include f.f32px;
    @include m.no-wrap;
    line-height: 2.5rem;
    margin-top: 1rem;
    letter-spacing: -0.02em;
  }

  .card-grid {
    margin-top: 2rem;
  }

  .screen-is-portrait {
    .card-grid {
      margin-top: 4rem;
    }
  }

  .total-price {
    @include title-font;
  }

  .footer-container {
    padding: 2.5rem 3.125rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  // landscape

  .landscape-1-to-3 {
    .company-logo {
      max-height: 10rem;
    }
  }

  .landscape-4 {
    .company-logo {
      max-height: 10rem;
    }
    &.footer-container {
      padding: 4.5rem 3.125rem;
    }
  }

  .landscape-5-to-8 {
    &.footer-container {
      padding: 2rem 3.125rem;
    }
  }

  .landscape-9-to-12 {
    .company-logo {
      max-height: 10rem;
    }
  }

  // portrait

  .portrait-1-to-2 {
    .company-logo {
      max-height: 10rem;
    }
  }

  .portrait-3-to-4 {
    .company-logo {
      max-height: 10rem;
    }
  }

  .portrait-5-to-6 {
    .company-logo {
      max-height: 8rem;
    }
  }

  .portrait-7-to-9 {
    .company-logo {
      max-height: 10rem;
    }
  }

  .portrait-10-to-12 {
    .company-logo {
      max-height: 10rem;
    }
    &.footer-container {
      padding: 4.1rem 3.125rem;
    }
  }
}
