@use 'src/scss/font' as f;

$borderThickness: 4px;
$headerBottomBorderThickness: 2px;
$productBottomBorderThickness: 1px;
$circumferencePadding: 3.125rem;

.sprout-theme {
  // Header
  .header-container {
    height: 6rem;
    border: $borderThickness solid black;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    border-bottom: 0;
    margin-top: $circumferencePadding;
    margin-left: $circumferencePadding;
    margin-right: $circumferencePadding;
    padding: 0;
  }

  .menu-title {
    font-size: 2rem;
    @include f.acumin-pro-wide-bold;
  }

  .menu-date {
    font-size: 1.25rem;
    @include f.acumin-pro-wide-semibold;
  }

  // Sections
  .sections-container {
    border: $borderThickness solid black;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
    border-top-width: 0;
    margin-left: $circumferencePadding;
    margin-right: $circumferencePadding;
    padding: 0 2rem;
  }

  .section-header-bottom-border {
    border-bottom: none;
  }

  .section-title {
    font-size: 1.5rem;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    margin-right: 0.5rem;
    @include f.acumin-pro-wide-bold;
  }

  .section-title-text {
    font-size: 1.5rem;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    margin-right: 0.5rem;
    @include f.acumin-pro-wide-bold;
  }

  .section-title-icon {
    margin-right: 1rem;
  }

  .section-header-general {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-align: left;
    @include f.acumin-pro-wide-semibold;
  }

  .product-badge {
    display: flex;
  }

  // Product
  .product-title {
    line-height: 2rem;
  }

  .product-title-container {
    padding-right: 1.5rem;
  }

  .product-column {
    text-align: left;
  }

  .products-container {
    margin-bottom: 2rem;
    @include f.acumin-pro-wide-regular;
  }

  .product-label {
    font-size: 0.75rem;
    padding: 0.05rem 0.3rem;
  }

  // Title Section
  .title-section {
    @include f.acumin-pro-wide-bold;
  }

  // Font Styling
  .force-bold-style * {
    @include f.acumin-pro-wide-bold;
  }
}

html.print-menu {
  .sprout-theme {
    .sections-container {
      border: none;
    }

    .section-container {
      padding-bottom: 0;
      padding-top: 0;
    }

    .header-container,
    .sections-container,
    .footer-container {
      margin-left: 0;
      margin-right: 0;
      padding-top: 0;
    }

    .header-container {
      margin-top: 0;
      border-top-width: 0;
      border-right-width: 0;
      border-left-width: 0;
    }

    &.fixed-print-wrapper {
      display: block;
      border: $borderThickness solid black;
      margin: 0 0;
      padding: 0.5in;
      border-radius: 3rem;
    }

    .product-variant-price {
      font-size: 1.2rem;
      line-height: 1.325rem;
    }

    .product-container {
      align-items: flex-end;
      height: calc(2rem + 5px);
    }

    .badge {
      height: 2rem;
    }
  }
}
