@use 'src/scss/font' as f;

.print-menu.fika-print-theme {
  page: fika-print-theme-pages;
}

@page fika-print-theme-pages {
  margin: 1.37in 0.9in;
}

.fika-print-theme {
  $fika-sativa: #000000;
  $fika-indica: #000000;
  $fika-hybrid: #000000;
  $fika-blend: #000000;
  $fika-cbd: #000000;

  &.fixed-print-wrapper {
    display: block;
    border: 1px dashed lightgrey;
  }

  // Header

  .main-header {
    @include f.PT-mono-bold();
    font-size: 3.64rem;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .header-container {
    justify-content: center;
    padding: 1rem 0 1rem 0;
  }

  .company-logo {
    max-width: 20rem;
    max-height: 4.25rem;
  }

  // Sections

  .title-section {
    @include f.poppins-semi-bold();
    font-size: 1.27rem;
    text-transform: uppercase;
    padding: 0.5rem;
  }

  .title-section-container {
  }

  .sections-container {
    margin-left: 0.025rem;
    margin-right: 0.025rem;
  }

  .section-container {
  }

  .section-header-container {
    height: 2rem;
    padding-left: 0.5rem;
    text-transform: uppercase;
  }

  .section-column-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section-title {
    @include f.poppins-medium();
    font-size: 1.09rem;
  }

  .section-header-general {
    @include f.poppins-medium();
    font-size: 1.09rem;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Product

  .product-container {
    height: 2rem;
  }

  .product-column {
    flex-direction: row;
  }

  .product-column-general {
    @include f.poppins-medium();
    font-size: 0.9rem;
    text-align: center;
  }

  .product-brand {
    order: 0;
  }

  .product-title {
    order: 1;
  }

  app-label-inflator {
    order: 2;
  }

  .product-cannabinoid,
  .product-terpene {
    overflow-x: clip;
    line-height: 0.91rem;
  }

  .product-cannabinoid,
  .product-terpene,
  .product-quantity,
  .product-variant-price-integer,
  .product-size,
  .product-variant-secondary-price {
    padding-left: 0.25rem;
  }

  .product-variant-secondary-price {
    .product-variant-price-integer:not(.product-variant-price-integer-empty),
    .product-variant-price-decoration {
      text-decoration: line-through;
    }
  }

  .product-strain-type {
    background-color: transparent;
    @include f.poppins-bold();
    display: flex;
    justify-content: center;
    align-items: center;

    &.s,
    &.i,
    &.b,
    &.h,
    &.c {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.9rem;
    }

    &.sativa,
    &.indica,
    &.blend,
    &.hybrid,
    &.cbd {
      width: 4rem;
      height: 1.5rem;
      font-size: 0.9rem;
    }

    &.s,
    &.sativa {
      color: $fika-sativa;
    }

    &.i,
    &.indica {
      color: $fika-indica;
    }

    &.b,
    &.blend {
      color: $fika-blend;
    }

    &.h,
    &.hybrid {
      color: $fika-hybrid;
    }

    &.c,
    &.cbd {
      color: $fika-cbd;
    }
  }

  .product-title-container {
    padding-left: 0.5rem;
    padding-right: 0;
  }

  .product-title-flex-container {
    gap: 0.5rem;
  }

  .product-title {
    font-size: 0.9rem;
    @include f.poppins-medium();
  }

  .product-brand {
    font-size: 0.9rem;
    @include f.poppins-italic();
    text-transform: uppercase;
  }

  .product-flag-label {
  }

  .product-label {
    font-size: 0.73rem;
    @include f.poppins-medium();
    padding: 0 0.5rem;
    align-items: flex-start;
    margin: 0;
  }

  .flag-body {
    padding: 0 0.5rem;
  }

  .product-badge {
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }

  .badge {
    height: 1.3rem;
  }

  .classification-mapping {
    @include f.poppins-bold();
    @include f.f16px();
    flex-direction: row;
    text-align: -webkit-center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;

    .sativa {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $fika-sativa;
    }

    .hybrid {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $fika-hybrid;
    }

    .indica {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $fika-indica;
    }

    .blend {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $fika-blend;
    }

    .cbd {
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      height: max-content;
      color: $fika-cbd;
    }
  }

  // Font Styling
  .force-bold-style * {
    @include f.poppins-extra-bold;
  }
}
