@use 'src/scss/font' as f;

.print-menu.ingram-and-sons-print-theme {
  page: ingram-and-sons-print-theme-pages;
}

@page ingram-and-sons-print-theme-pages {
  margin: 0.25in;
}

.ingram-and-sons-print-theme {
  .company-logo {
    max-height: 6rem;
  }

  // Header

  .header-container {
    justify-content: center;
    padding: 1rem 0 !important;

    .company-logo {
      height: 5.5rem;
    }
  }

  .main-header {
    @include f.spectral-extra-bold;
    height: auto;
    line-height: 1;
    font-size: 5.8rem;
    letter-spacing: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Section container

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  // Sections

  .title-section {
    @include f.spectral-extra-bold;
    font-size: 3.64rem;
    padding: 0.5rem 1rem;
  }

  .title-section-container {
    color: black;
    border-radius: 0.625rem;
    word-wrap: break-word;
    word-break: break-word;
  }

  .section-container {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 1rem;
  }

  .section-header-container {
    color: white;
    background-color: black;
    border-radius: 0.625rem;
    height: 5.125rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
  }

  .section-title {
    @include f.anton-regular;
    font-size: 2.55rem;
    letter-spacing: 0.01625rem;
    align-self: center;
  }

  .section-column-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section-header-pre-roll {
    @include f.anton-regular;
    vertical-align: middle;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.0125rem;
  }

  .section-header-general {
    @include f.anton-regular;
    font-size: 1.625rem;
    line-height: 1.625;
    letter-spacing: 0.0125rem;
  }

  // Product

  .product-container {
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 4rem;
  }

  .product-title {
    @include f.anton-regular;
    font-size: 1.4rem;
    line-height: 1.25;
    letter-spacing: 0.01375rem;
  }

  .product-label-container {
    vertical-align: middle;
  }

  .product-label {
    @include f.montserrat-medium;
    max-height: 2rem;
    font-size: 1rem;
    letter-spacing: 0.0625rem;
    vertical-align: middle;
  }

  .product-subtitle {
    @include f.montserrat-medium;
    font-size: 1.25rem;
    letter-spacing: 0.009375rem;
  }

  .product-column-general {
    @include f.montserrat-medium;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.01rem;
  }

  //.product-variant-price-decoration {
  //  font-size: 1.25rem;
  //  line-height: 1;
  //  letter-spacing: 0.0125rem;
  //  vertical-align: text-top;
  //}

  .product-column {
    @include f.montserrat-medium;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .footer-container {
    padding: 2rem 3.125rem;
  }

  .badge {
    height: 1.75rem;
  }

  // Font Styling

  .force-bold-style * {
    @include f.montserrat-bold;
  }
}
