@use 'src/scss/font' as f;
@use 'src/scss/variables' as v;
@use 'src/scss/mixins' as m;

/**
 * Reports fix REM flaw with print menus.
 * Print menus are borked and need to be developed using the base REM size of 11px.
 * Report menus can be developed using the default REM size of 16px.
 */
html.report-menu {
  font-size: 16px !important;

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  .new-product-sub-container {
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    width: 100%;
    padding: 0.25rem 0;

    $image-height: 4rem;
    $image-width: 4rem;

    .product-image-column {
      height: $image-height;
      width: $image-width;
      object-fit: contain;
      object-position: center;

      .product-image {
        height: $image-height;
        width: $image-width;
      }
    }
  }

  .new-product-columns-and-desc {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
  }

  .new-product-columns {
    display: flex;
    flex-direction: row;
    padding-left: 0.25rem;
  }

  .new-product-desc {
    padding: 0 0.25rem;
  }
}
