@use 'src/scss/font' as f;

.amsterdam-theme {
  // Header

  .main-header {
    @include f.P22-Mackinac-Pro-bold;
    font-size: 3.75rem;
    letter-spacing: 0.01625rem;
    text-align: left;
  }

  // Section container

  .sections-container {
    margin-left: 2.125rem;
    margin-right: 2.125rem;
  }

  // Sections

  .title-section {
    @include f.P22-Mackinac-Pro-bold;
    font-size: 3.75rem;
    letter-spacing: 0.01625rem;
    padding: 0 0.5rem;
  }

  .section-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-header-container {
    height: 5.125rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }

  .section-title {
    @include f.soleil-normal;
    font-size: 2.5rem;
    letter-spacing: 0.01625rem;
  }

  .section-header-pre-roll {
    @include f.soleil-normal;
    vertical-align: middle;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
  }

  .section-header-general {
    @include f.soleil-normal;
    font-size: 1.6rem;
  }

  // Product

  .product-column-general {
    @include f.soleil-normal;
    font-size: 1.25rem;
  }

  .product-container {
    align-items: center;
    height: 4rem;
    padding: 0 0.5rem;
  }

  .product-title-flex-container {
    gap: 0.75rem;
  }

  .product-title {
    @include f.soleil-normal;
    letter-spacing: 0.01375rem;
  }

  .product-subtitle {
    @include f.soleil-normal;
    font-size: 0.875rem;
    letter-spacing: 0.009375rem;
  }

  .product-variant-price {
    letter-spacing: 0.0125rem;
  }

  .product-low-amount {
    @include f.soleil-normal;
  }

  .product-column {
    @include f.soleil-normal;
  }

  // Font Styling

  .force-bold-style * {
    @include f.soleil-extra-bold-normal;
  }

  .badge {
    width: 1.5rem;
    height: 1.5rem;
  }

  .product-badge {
    display: flex;
  }

  .product-label {
    margin-left: 0;
    margin-right: 0;
    @include f.f16px;
    @include f.soleil-italic;
  }
}
