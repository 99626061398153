@use 'src/scss/font';
@use 'src/scss/variables';
@use 'src/scss/mixins';

// base html font-size gets set to 11px for print menus

$plantlife-non-smokable-border-thickness: 1px;
$plantlife-non-smokable-thick-border-thickness: 2px;
$plantlife-non-smokable-black: #222222;
$plantlife-non-smokable-white: #ffffff;
$plantlife-non-smokable-background-color: #222222;
$plantlife-non-smokable-header-color: #ffffff;
$plantlife-non-smokable-header-text-color: #ffffff;

$plantlife-non-smokable-sativa-color: $plantlife-non-smokable-black;
$plantlife-non-smokable-hybrid-color: $plantlife-non-smokable-black;
$plantlife-non-smokable-indica-color: $plantlife-non-smokable-black;
$plantlife-non-smokable-blend-color: $plantlife-non-smokable-black;
$plantlife-non-smokable-cbd-color: $plantlife-non-smokable-black;

.print-menu.plantlife-non-smokable-theme {
  page: plantlife-beverages-theme-pages;
}

@page plantlife-non-smokable-theme-pages {
  margin: 0.25in;
}

@mixin plantlife-non-smokable-top-border {
  border-top: $plantlife-non-smokable-border-thickness solid $plantlife-non-smokable-black;
}

@mixin plantlife-non-smokable-thick-top-border {
  border-top: $plantlife-non-smokable-thick-border-thickness solid $plantlife-non-smokable-black;
}

@mixin plantlife-non-smokable-right-border {
  border-right: $plantlife-non-smokable-border-thickness solid $plantlife-non-smokable-black;
}

@mixin plantlife-non-smokable-left-border {
  border-left: $plantlife-non-smokable-border-thickness solid $plantlife-non-smokable-black;
}

@mixin plantlife-non-smokable-bottom-border {
  border-bottom: $plantlife-non-smokable-border-thickness solid $plantlife-non-smokable-black;
}

@mixin plantlife-non-smokable-thick-bottom-border {
  border-bottom: $plantlife-non-smokable-thick-border-thickness solid $plantlife-non-smokable-black;
}

@mixin plantlife-non-smokable-all-borders {
  border: $plantlife-non-smokable-border-thickness solid $plantlife-non-smokable-black;
}

.plantlife-non-smokable-theme {
  .background-asset {
    // fallback menu body color
    background-color: $plantlife-non-smokable-background-color;
  }

  // Header

  .header-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $plantlife-non-smokable-header-text-color;
    padding: 1rem 0 !important;
    gap: 0.5rem;

    .main-header {
      @include font.bebas-neue-pro-regular;
      font-size: 3.27rem;
      text-align: center;
      max-width: 100%;
    }

    .company-logo {
      height: 8.18rem !important;
      max-height: 8.18rem;
    }
  }

  // products container

  .products-container {
    background-color: $plantlife-non-smokable-white;
    display: flex;
    flex-direction: column;
  }

  // Section container

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  // Sections

  .title-section {
    @include font.bebas-neue-pro-regular;
    font-size: 3.75rem;
  }

  .section-column {
    @include font.bebas-neue-pro-bold;
    @include font.f28px;

    &.section-column-classification {
      flex: 1;
    }
  }

  .section-container {
    @include plantlife-non-smokable-left-border;
    @include plantlife-non-smokable-right-border;
    flex: 1;
    padding-left: 0;
    padding-right: 0;
    overflow: unset;
  }

  .section-header-title {
    @include font.bebas-neue-pro-bold;
    @include font.f28px;
    letter-spacing: 0.0625rem;
    color: $plantlife-non-smokable-black;
    text-transform: uppercase;
    text-align: center;
    padding: 0.25rem;
  }

  .section-header-container {
    @include plantlife-non-smokable-thick-top-border;
    @include plantlife-non-smokable-thick-bottom-border;
    flex-direction: row;
    height: 2.36rem;
    background-color: $plantlife-non-smokable-header-color;
    padding-top: 0 !important;
  }

  .section-title {
    text-transform: uppercase;
  }

  .section-column-thc-and-cbd {
  }

  .section-column-price {
  }

  .section-header-general {
    @include font.bebas-neue-pro-bold;
    @include font.f28px;
    color: $plantlife-non-smokable-black;
  }

  // Product

  .split-product-section-container {
  }

  .product-column-general {
    @include font.bebas-neue-pro-regular;
  }

  .product-container {
    align-items: center;
    height: 4.72rem;

    &.odd {
      @include plantlife-non-smokable-right-border;
    }

    &.last {
      border-bottom: unset;
    }
  }

  .product-strain-type {
    color: $plantlife-non-smokable-black;
    @include font.bebas-neue-pro-bold;
    @include font.f22px;
    &.s {
      color: $plantlife-non-smokable-sativa-color;
    }
    &.h {
      color: $plantlife-non-smokable-hybrid-color;
    }
    &.i {
      color: $plantlife-non-smokable-indica-color;
    }
    &.b {
      color: $plantlife-non-smokable-blend-color;
    }
    &.c {
      color: $plantlife-non-smokable-cbd-color;
    }
  }

  .product-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.18rem;
    height: 100%;
    padding-left: 0.25rem; // 4px
  }

  .product-title-flex-container {
  }

  .product-title {
    @include font.bebas-neue-pro-bold;
    @include font.f21px;
  }

  .product-badge {
    display: flex;
    gap: 0.5rem;
    order: 3;
  }

  .badge {
    height: 1.2rem;
  }

  .product-label {
    @include font.bebas-neue-pro-regular;
    @include font.f14px;
    line-height: 0.875rem;
    margin-left: 0;
    border-radius: 0.2rem;
    padding: 0.125rem 0.4rem;
    align-self: end;
  }

  .subtitle-flex-container {
    gap: 0.75rem;
  }

  .product-subtitle {
    @include font.bebas-neue-pro-regular;
    @include font.f20px;
    font-size: 1.25rem;
  }

  .product-thc-and-cbd {
  }

  .product-variant-price {
  }

  .product-quantity {
  }

  .product-image-column {
    max-height: 80%;
    max-width: 80%;
    display: flex;
    overflow-x: visible;

    .product-strain-type {
      margin-left: 0.25rem;
    }
  }

  .product-image {
    height: 100%;
    width: inherit;
    filter: grayscale(1);
  }

  .product-low-amount {
    @include font.bebas-neue-pro-regular;
  }

  .product-size {
    @include font.f20px;
  }

  .product-column {
    @include font.bebas-neue-pro-regular;
    @include font.f20px;
  }

  .base-product-strain-type {
    @include font.bebas-neue-pro-regular;
    font-size: 1.5rem;
  }

  .image-section {
    margin-top: 0;
  }

  .empty-container {
    background-color: $plantlife-non-smokable-white;
    @include plantlife-non-smokable-right-border;

    .lane-one {
      flex: 1 1 100%;
      max-width: 50%;
      @include plantlife-non-smokable-left-border;
      @include plantlife-non-smokable-right-border;
    }

    .lane-two {
      flex: 1 1 100%;
      max-width: 50%;
      border-right: $plantlife-non-smokable-border-thickness solid rgba(255, 255, 255, 0);
    }
  }

  // Font Styling

  .force-bold-style * {
    @include font.bebas-neue-pro-bold;
  }
}
