@use 'src/scss/font';

.print-menu.doug-print-theme {
  page: doug-print-theme-pages;
}

@page doug-print-theme-pages {
  margin: 0.25in;
}

.doug-print-theme {
  // Header

  .header-container {
    padding-inline: 0;
  }

  .main-header {
    @include font.poppins-bold;
    font-size: 4.36rem;
    letter-spacing: 0.06em;
  }

  // Sections

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  .title-section {
    @include font.poppins-bold;
    font-size: 3.27rem;
    letter-spacing: 0.06em;
  }

  .section-header-container {
    border-radius: 0.727rem 0.727rem 0 0;
    height: 3.37rem;
    padding-left: 0.727rem;
    padding-top: 0.3636rem;
  }

  .section-title {
    @include font.poppins-bold;
    font-size: 1.8rem;
    letter-spacing: 0.06em;
  }

  .section-header-general {
    @include font.poppins-semi-bold;
    font-size: 1.45rem;
    letter-spacing: 0.05em;
    line-height: 100%;
  }

  .section-container {
    margin-bottom: 0.727rem;
  }

  // Product

  .product-container {
    align-items: center;
    height: 3.727rem;
    padding-left: 0.727rem;
  }

  .product-title {
    @include font.poppins-semi-bold;
    font-size: 1.272rem;
    letter-spacing: 0.03em;
  }

  .product-subtitle {
    @include font.poppins-regular;
    font-size: 1.09rem;
    letter-spacing: 0.03em;
  }

  .product-label {
    @include font.poppins-semi-bold;
    border-radius: 0.363rem;
    font-size: 0.727rem;
    padding-inline: 0.545rem;
    height: 0.909rem;
  }

  .product-badge {
    display: flex;
    justify-content: flex-end;
    max-height: 100%;
    overflow-y: hidden;
    margin-right: 0.8125rem;
  }

  .badge {
  }

  .product-column {
    @include font.poppins-semi-bold;
    font-size: 1.272rem;
  }

  .product-variant-price-decoration {
    font-size: 0.65rem;
    line-height: 2.3;
    vertical-align: text-top;
  }
}
