@use 'src/scss/font';
@use 'src/scss/mixins' as m;

.print-menu.come-to-bask-trifold-theme {
  page: come-to-bask-trifold-theme-pages;
}

@page come-to-bask-trifold-theme-pages {
  margin: 0.25in;
}

.come-to-bask-trifold-theme {
  $come-to-bask-legend-color: #e6eef9;
  $come-to-bask-indica-color: #59529e;
  $come-to-bask-sativa-color: #cd2d2b;
  $come-to-bask-hybrid-color: #9bc562;
  $come-to-bask-blend-color: #5690f0;
  $come-to-bask-cbd-color: #b8b8b8;

  // Header

  .main-header {
    @include font.merriweather-regular;
    font-size: 7.5rem;
    text-transform: uppercase;
    text-align: center;
  }

  .header-container {
    justify-content: center;
    padding: 1rem 0 !important;

    .company-logo {
      height: 5.5rem;
    }
  }

  // products container

  .products-container {
  }

  // Section container

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  // Sections

  .title-section-container {
    margin-bottom: 0 !important;
    color: black;
  }

  .title-section {
    @include font.merriweather-ultra-bold;
    font-size: 3.75rem;
    padding: 0.45rem 0.73rem;
    line-height: 1.2;
  }

  .title-section-subtitle {
    @include font.merriweather-ultra-bold;
    line-height: 1.2;
    font-size: 1.5rem;
    padding: 0 0.73rem 0.45rem 0.73rem;
  }

  .section-column {
    &.section-column-classification {
      flex: 1;
    }
  }

  .section-container {
    flex: 1;
    padding-left: 0;
    padding-right: 0;
    overflow: unset;
    margin-bottom: 1.82rem;
  }

  .section-header-title {
    @include font.merriweather-ultra-bold;
    @include font.f26px;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    text-align: center;
    padding: 0.25rem;
  }

  .section-header-container {
    padding-top: 0 !important;
  }

  .section-title {
    @include font.merriweather-ultra-bold;
    font-size: 1.64rem;
    letter-spacing: 0.25em;
  }

  .section-title-text {
    padding: 0 0.73rem;
    @include m.no-wrap;
  }

  .section-column-thc-and-cbd {
  }

  .section-column-price {
  }

  .section-header-general {
    @include font.merriweather-ultra-bold;
    font-size: 1rem;
    letter-spacing: 0.0625rem;
  }

  // Product

  .product-column-general {
    @include font.merriweather-regular;
    @include font.f20px;
    letter-spacing: -0.5px;
    line-height: 1.5;
  }

  .product-container {
    align-items: center;
    height: 4rem;
    text-transform: uppercase;
  }

  .product-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding-left: 0.25rem; // 4px
  }

  .product-title {
    @include font.merriweather-ultra-bold;
    @include font.f21px;
  }

  .product-badge {
    display: flex;
    gap: 1px;
  }

  .badge {
    height: 2rem;
  }

  .product-label {
    @include font.merriweather-ultra-bold;
    @include font.f12px;
    margin-left: 0;
    border-radius: 0.2rem;
    padding: 0.125rem 0.2rem;
    align-self: end;
    letter-spacing: 0.03125rem;
  }

  .subtitle-flex-container {
    gap: 0.5rem;
  }

  .product-subtitle {
    @include font.merriweather-regular;
    @include font.f18px;
  }

  .product-thc-and-cbd {
  }

  .product-variant-price {
    @include font.f20px;
  }

  .product-quantity {
  }

  .product-low-amount {
    @include font.merriweather-regular;
  }

  .product-size {
    @include font.f18px;
  }

  .product-column {
    @include font.merriweather-regular;
    @include font.f16px;
  }

  .base-product-strain-type {
    @include font.merriweather-regular;
  }

  .image-section {
    margin-top: 0;
    padding: 3.27rem;
    background-color: $come-to-bask-legend-color;
  }

  $gapBetweenSwimlanes: 2.9rem;

  .wrapping-swimlane-section-container {
    gap: $gapBetweenSwimlanes;
    margin-bottom: 0;
  }

  .footer-container {
    margin-top: 0;
  }

  @mixin classification-badge-styling {
    border: 1px solid #000;
    border-radius: 2px;
    color: white;
  }

  .product-strain-type,
  .classification-badge {
    @include font.merriweather-bold;
    color: black;
    background-color: $come-to-bask-legend-color;

    &.i,
    &.h,
    &.s,
    &.b,
    &.c {
      font-size: 1.25rem;
      width: 1.5rem;
    }

    &.indica,
    &.hybrid,
    &.sativa,
    &.blend,
    &.cbd {
      font-size: 1rem;
      width: 4.5rem;
    }

    &.i,
    &.indica {
      background-color: $come-to-bask-indica-color;
    }
    &.s,
    &.sativa {
      background-color: $come-to-bask-sativa-color;
    }
    &.h,
    &.hybrid {
      background-color: $come-to-bask-hybrid-color;
    }
    &.b,
    &.blend {
      background-color: $come-to-bask-blend-color;
    }
    &.c,
    &.cbd {
      background-color: $come-to-bask-cbd-color;
    }
    .classification-badge {
      @include classification-badge-styling;
    }
  }

  .product-strain-type {
    @include classification-badge-styling;
    width: 1.5rem;
  }

  // Font Styling

  .force-bold-style * {
    @include font.merriweather-ultra-bold;
  }
}
