@use 'src/scss/font' as f;

.print-menu.banded-theme {
  page: banded-pages;
}

@page banded-pages {
  margin: 0.5in;
}

.banded-theme {
  $title-letter-spacing: 0.4rem;
  $common-letter-spacing: 0.1rem;
  $variant-sale-price-color: #fa5555;

  // Header

  .main-header {
    @include f.bebas-neue-pro-bold;
    font-size: 6rem;
    letter-spacing: $title-letter-spacing;
    text-align: center;
  }

  // Section container

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  // Sections

  .title-section {
    @include f.bebas-neue-pro-regular;
    font-size: 4rem;
    letter-spacing: $title-letter-spacing;
    text-transform: uppercase;
  }

  .section-container {
    margin-bottom: 0.75rem;
  }

  .section-header-container {
    height: 1.5rem;
    @include f.f16px;
    padding: 0.6rem 0.5rem;
  }

  .section-title {
    @include f.myriad-pro-semi-bold;
    font-size: 1.5rem;
    letter-spacing: $common-letter-spacing;
  }

  .section-header-pre-roll {
  }

  .section-header-general {
    @include f.myriad-pro-regular;
  }

  .section-header-variant-price {
  }

  // Product

  $general-font-size: 1.125rem;

  .product-column-general {
    @include f.myriad-pro-regular;
    font-size: $general-font-size;
  }

  .product-container {
    align-items: center;
    height: 1.75rem;
    padding: 0 0.5rem;
  }

  .product-title {
    @include f.myriad-pro-bold;
    font-size: $general-font-size;
    letter-spacing: $common-letter-spacing;
    text-transform: uppercase;
  }

  .product-brand {
    @include f.myriad-pro-regular;
    font-size: $general-font-size;
    letter-spacing: 0.1rem;
    margin-left: 0.5rem;
    text-transform: uppercase;
  }

  .product-label {
    @include f.myriad-pro-regular;
    margin: 0 0.5rem;
    font-size: 0.75rem;
    letter-spacing: 0;
  }

  .product-subtitle {
    @include f.myriad-pro-regular;
    line-height: 1rem;
    font-size: 0.8125rem;
    letter-spacing: $common-letter-spacing;
  }

  .product-variant-price,
  .product-cannabinoid,
  .product-terpene {
    @include f.myriad-pro-regular;
    font-size: $general-font-size;
  }

  .product-variant-price-decoration {
  }

  .product-variant-price {
  }

  .product-quantity,
  .product-size {
    font-size: $general-font-size;
  }

  .product-low-amount {
    font-size: $general-font-size;
  }

  .product-column {
    @include f.myriad-pro-regular;
    font-size: $general-font-size;
  }

  .badge {
    height: 1.25rem;
  }

  .product-row-overlay {
    border-radius: 0.2rem;
  }

  // Menu Footer

  .footer-container {
    padding-top: 1rem;
    padding-bottom: 0;
  }

  // Font Styling

  .force-bold-style * {
    @include f.myriad-pro-bold;
  }
}
