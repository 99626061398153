@use 'src/scss/font' as f;

.weed-theme {
  // Header

  .header-container {
    padding: 2rem 2rem 1rem 2rem;
  }

  .main-header {
    @include f.windsor-bold;
    text-transform: uppercase;
    font-size: 5rem;
    letter-spacing: 0.125rem;
    font-weight: 800;
  }

  // Sections

  .title-section {
    @include f.windsor-bold;
    @include f.f64px;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }

  .sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  .section-title {
    @include f.windsor-bold;
    text-align: left;
    font-size: 3rem;
    line-height: 3.5rem;
    letter-spacing: 0.125rem;
    font-weight: 600;
    margin: auto;
  }

  .section-column {
    vertical-align: middle;
    text-align: center;
  }

  .weed-section-container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .weed-section-container-portrait {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .section-container {
  }

  .section-header-general {
    font-size: 1.6rem;
    @include f.proxima-nova-bold;
  }

  .section-header-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }

  .section-header-variant-price {
    @include f.proxima-nova-bold;
    vertical-align: middle;
    font-size: 1.6rem;
    letter-spacing: 0.0125rem;
    width: 100%;
    float: right;
  }

  .section-header-variant-price {
    @include f.proxima-nova-bold;
    vertical-align: middle;
    font-size: 1.75rem;
    letter-spacing: 0.0125rem;
    width: 100%;
    float: right;
  }

  // Products

  .product-container {
    vertical-align: middle;
    height: 5rem;
  }

  .product-title {
    @include f.proxima-nova-semibold;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: 0.03125rem;
  }

  .product-label-container {
    vertical-align: middle;
  }

  .product-label {
    @include f.proxima-nova-bold;
    font-size: 1.2rem;
    letter-spacing: 0.0625rem;
    vertical-align: middle;
  }

  .product-subtitle {
    @include f.proxima-nova-regular;
    font-size: 1.4rem;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
    align-self: center;
  }

  .product-cannabinoid,
  .product-terpene {
    @include f.proxima-nova-regular;
    font-size: 1.5rem;
    letter-spacing: 0.01rem;
  }

  .product-thc {
    font-size: 1.6rem;
  }

  .product-variant-price {
    @include f.proxima-nova-bold;
    font-size: 1.6rem;
    letter-spacing: 0.0125rem;
  }

  .product-variant-price-decoration {
    font-size: 1.1rem;
    line-height: 1.6;
    padding-left: 0.1rem;
    letter-spacing: 0.0125rem;
    vertical-align: text-top;
  }

  .product-brand {
    @include f.proxima-nova-bold-italic;
    font-size: 1.25rem;
  }

  .product-quantity {
    @include f.proxima-nova-bold-italic;
    font-size: 1.25rem;
  }

  .product-size {
    @include f.proxima-nova-bold-italic;
    font-size: 1.25rem;
  }

  // Font Styling

  .force-bold-style * {
    @include f.proxima-nova-extrabold;
  }
}
