@use 'src/scss/font' as f;

.house-of-dank-theme {
  .company-logo {
    max-height: 10rem;
  }

  // Header

  .header-container {
    padding: 1.5rem 3.125rem 0 3.125rem;
  }

  .header-container-portrait {
    padding-top: 7.5rem;
  }

  .header-container-landscape {
    padding-top: 3.125rem;
  }

  .main-header {
    @include f.mont-heavy;
    height: auto;
    font-size: 9.375rem;
    letter-spacing: 0;
    text-align: center;
  }

  // Section container

  .sections-container {
    margin-left: 2.125rem;
    margin-right: 2.125rem;
  }

  // Sections

  .title-section {
    @include f.mont-heavy;
  }

  .section-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-header-container {
    color: white;
    background-color: black;
    border-radius: 0.625rem;
    height: 3.875rem;
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
  }

  .section-title {
    @include f.mont-heavy;
    @include f.f55px;
    letter-spacing: 0.01625rem;
    align-self: center;
  }

  .section-header-general {
    @include f.poppins-bold;
    @include f.f35px;
    line-height: 1.625;
    letter-spacing: 0.0125rem;
  }

  .section-header-pre-roll {
    @include f.poppins-bold;
    @include f.f29px;
    vertical-align: middle;
    line-height: 1.325;
    letter-spacing: 0.0125rem;
  }

  .products-container {
    border-radius: 0.625rem;
  }

  // Product

  .product-container {
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 4.625rem;
  }

  .product-title {
    @include f.poppins-bold;
    @include f.f41px;
    line-height: 1.8;
    letter-spacing: 0.01375rem;
  }

  .product-label-container {
    vertical-align: middle;
  }

  .product-label {
    @include f.poppins-regular;
    @include f.f16px;
    height: 1rem;
    letter-spacing: 0.0625rem;
    vertical-align: middle;
  }

  .product-variant-price {
    @include f.poppins-bold;
    @include f.f41px;
    line-height: 1.75;
    letter-spacing: 0.0125rem;
  }

  .product-variant-price-decoration {
    @include f.f22px;
    line-height: 1.2;
    letter-spacing: 0.0125rem;
    vertical-align: text-top;
  }

  .product-low-amount {
    @include f.poppins-medium;
    @include f.f41px;
    line-height: 1.5;
  }

  .product-column {
    @include f.poppins-semi-bold;
    @include f.f41px;
    line-height: 1.625;
  }

  .footer-container {
    padding: 0 3.125rem 1rem;
  }

  // Font Styling

  .force-bold-style * {
    @include f.poppins-black;
  }
}

// Specific styling just for web version of theme

.house-of-dank-theme.web-menu {
  .main-header {
    @include f.f100px;
  }

  .company-logo {
    max-height: 6.5rem;
  }

  .product-cannabinoid,
  .product-terpene,
  .product-low-amount {
    @include f.f20px;
  }
}
