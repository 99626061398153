@use 'src/scss/variables' as v;
@use 'src/scss/font' as f;

.super-lemon-haze-theme {
  // Header

  .main-header {
    @include f.open-sans-bold;
    @include f.f64px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .header-container {
    justify-content: center;
  }

  .company-logo {
    max-width: 20rem;
    max-height: 4.25rem;
  }

  // Section container

  .sections-container {
    margin-left: 2.125rem;
    margin-right: 2.125rem;
  }

  // Sections

  .title-section {
    @include f.open-sans-bold;
    @include f.f40px;
    padding: 1rem;
  }

  .title-section-container {
    border-radius: 0.5rem;
  }

  .title-section-image-container {
  }

  .section-container {
    margin: 0 1rem 0.5rem;
  }

  .section-header-container {
    padding: 1rem 0;
    border-radius: 0.5rem;
    border: none;
    margin-bottom: 0.25rem;
  }

  .section-title {
    @include f.open-sans-bold;
    @include f.f40px;
  }

  .section-column-title {
    padding-left: 1rem;
  }

  .section-header-general {
    @include f.open-sans-semi-bold;
    @include f.f24px;
  }

  .section-header-badge {
    margin-right: 1rem;
  }

  // Product

  .products-container {
    margin-top: 0.75rem;
  }

  .product-column-general {
    @include f.open-sans-regular;
    @include f.f20px;
  }

  .product-container {
    @include f.open-sans-semi-bold;
    @include f.f24px;
    height: 2.9rem;
  }

  .product-label {
    @include f.poppins-medium;
    @include f.f12px;
    margin: 0 0.5rem;
    letter-spacing: -0.02em;
  }

  .product-badge {
  }

  .badge {
    height: 1.5rem;
  }

  // Font Styling
  .force-bold-style * {
    @include f.open-sans-bold;
  }
}
